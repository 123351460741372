import {EventSectionImage} from './section-img.model';
import {EventSectionContent} from './section-con.model';
export class EventSectionModel {

	 eventSectionId : any;
	 eventId : any;
	 sectionName : any;
	 mainHeader : any;
	 mainHeaderEn : any ;
	 detailHeaderEn : any;
	 detailHeader : any;
	 status : any;
	
	listSectionImage : EventSectionImage [] = [];
    listSectionContent : EventSectionContent ;
}