import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { FunctionUtils } from 'src/app/shared/utils/functionUtils';
import { vrLogModel } from 'src/app/shared/models/vr-log.model';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { TokenStorageService } from 'src/app/shared/authentication/token-storage.service';
// import { SrvRecord } from 'dns';

@Component({
  selector: 'app-vrgo-admin-approve-vr',
  templateUrl: './vrgo-admin-approve-vr.component.html',
  styleUrls: ['./vrgo-admin-approve-vr.component.scss']
})
export class VRGoAdminApproveVRComponent implements OnInit {

  @ViewChild('SuccessMessage',{static: false}) SuccessMessage;

  searchControl: FormControl = new FormControl();
  moduleLoading: boolean = false;
  search: boolean = false;
  preApproveVrlog: vrLogModel[];
  filteredPreApproveVrlog: vrLogModel[];
  listVRLog: any;
  userProfile : any;


  constructor(
    private service: HttpClientService,
    private fn: FunctionUtils,
    private vrLogDetailModal: NgbActiveModal,
    private modalService: NgbModal,
    private modaladdUser: NgbActiveModal,
    private modalOrderDetail: NgbActiveModal,
    private datePipe: DatePipe,
    private tokenService : TokenStorageService
    ) { }

  formBasic: FormGroup;

  listEventAll : any ;
  statusListOrder : any;
  listTicketByEvent : any ;

  distance : number;

  eventId : number = 0 ;
  ticketId : number = 0 ;
  status : string = null ;
  orderNumber : string = null;

  startDate: any;
  endDate: any ;
  vrLog : any;
  APPROVE = 'APPROVE';
  REJECT = 'REJECT';
  vrLogDetail;

  btnsubmit : any = 'อนุมัติ';
  currentLesson:any = '3';

  selectName: string = '';
  selectNameClass: string = 'form-control select-form';
  selectNameClassErrorMsg: string = '';

  selectticketName: string = '';
  selectticketNameClass: string = 'form-control select-form';
  selectticketNameClassErrorMsg: string = '';

  ordernumberNameClass : string = 'form-control';
  selectvrlistStatusClass : string = 'form-control';

  vrStatus : any[] = [];

  eventIdAfter : any ;
  ticketIdAfter : any ;
  statusAfter : any ;
  orderNumberAfter : any ;
  
  dataSearch = new Object()
  ngOnInit() {

    this.userProfile = JSON.parse(this.tokenService.getUserProfile());

    this.buildFormBasic();
    this.formBasic.controls['selectName'].setValue(0);
    this.formBasic.controls['selectticketName'].setValue(0);
    this.vrStatus;
    this.searchControl.valueChanges
    .pipe(debounceTime(200))
    .subscribe(value => {
      this.filerData(value);
    });


    this.getEventListOrder();
    this.getStatusListOrder();
    this.getListvrStatus();
  }
 
  
  filerData(val) {
    if (val) {
      val = val.toLowerCase();
    } else {
      return this.filteredPreApproveVrlog = [...this.preApproveVrlog];
    }

    const columns = Object.keys(this.preApproveVrlog[0]);
    if (!columns.length) {
      return;
    }

    const rows = this.preApproveVrlog.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        const column = columns[i];
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });
    this.filteredPreApproveVrlog = rows;
  }


  vrDetail(vrLogId){
    for (let vrLogs of this.vrLog) {
      if (vrLogId == vrLogs.vrLogId) {
        this.vrLogDetail = vrLogs;
        this.distance = this.vrLogDetail.distance;
      }
    }
  }
  openvrLogModel(content){
    this.listVRLog = undefined;
    this.vrLogDetailModal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'vrApproved' });
    // console.log('vrLogDetail:',this.vrLogDetail);
  }


  getTicketByEvent(event){
    let eventId = event.target.value;
    this.eventId = eventId;
    // console.log('event = ', eventId);
    this.service.getTicketByEvent(eventId).subscribe(data => {this.mapTicketByEvent(data)});
  }

  mapTicketByEvent(data : any){
    // console.log('ticket=',data);
    this.listTicketByEvent = data ;
    this.ticketId = this.listTicketByEvent.ticketId
  }


  getListvrStatus(){
    this.service.getListBoxByGroup('VR_STATUS').subscribe(data => this.mapvrStatus(data)
    );
  }

  getStatusListOrder(){
    this.service.getStatusListOrder().subscribe(data => {this.mapStatusListOrder(data)});
  }
  getEventListOrder(){
    this.service.getEventListOrder(this.userProfile.roleId).subscribe(data => {this.mapEevetListOrder(data)});
   
  }

  mapvrStatus(data){
    this.vrStatus = data
    // console.log('data', this.vrStatus);
  }
  
  mapEevetListOrder(data : any){
    this.listEventAll = data ;
  }

  mapStatusListOrder(data : any){
    this.statusListOrder = data ;
  }
  approveVrLog(){
    this.currentLesson = '2'
    this.btnsubmit ='กรุณารอสักครู่...';
    // console.log('distance',this.distance);
    this.service.approveVRLog(this.vrLogDetail.vrLogId,this.APPROVE,this.distance).subscribe(
    //  data => data
      data =>  this.mapapproveVrLog(data));
    
    // console.log('data',this.vrLogDetail.vrLogId,this.APPROVE);
  }
  rejectVrLog(vrLogId : any){
    // console.log('vrLogId',vrLogId);
    this.service.deleteVRLog(vrLogId).subscribe( data => this.mapapproveVrLog(data))
  }
  mapapproveVrLog(data){
    data
    // console.log('data',data)
   this.modalService.dismissAll();
   this.modalService.open(this.SuccessMessage,{ariaLabelledBy: 'modal-basic-title', windowClass: 'myCustomModalClass'})
  }

  buildFormBasic() {
    this.formBasic = new FormGroup({
      orderNumber: new FormControl(''),
      selectName: new FormControl(''),
      selectticketName : new FormControl(''),
      statusticketName: new FormControl(''),
      
    });
  }

  getTicketId(event){
    this.ticketId = event.target.value;
    // console.log('ticketId',this.ticketId);
  }
  getStatusByName(event){
    this.status = event.target.value;
  }

  onClickOk(){
    this.btnsubmit  = 'อนุมัติ';
    this.currentLesson= '3';
    this.modalService.dismissAll();
    this. buildFormBasic();
    this.formBasic.controls['selectName'].setValue(0);
    this.formBasic.controls['selectticketName'].setValue(0);
    this.preApproveVrlog = [];
    this.filteredPreApproveVrlog = [];
    this.selectNameClass = 'form-control select-form';
    this.selectNameClassErrorMsg= '';
    this.selectticketNameClass = 'form-control select-form';
    this.selectticketNameClassErrorMsg = '';
    this.ordernumberNameClass = 'form-control';
    this.selectvrlistStatusClass = 'form-control';
  }

  onClickReture(){
    this.modalService.dismissAll();
    this.btnsubmit  = 'อนุมัติ';
    this.currentLesson= '3';
    this.selectNameClass = 'form-control select-form';
    this.selectNameClassErrorMsg= '';
    this.selectticketNameClass = 'form-control select-form';
    this.selectticketNameClassErrorMsg = '';
    this.ordernumberNameClass = 'form-control';
    this.selectvrlistStatusClass = 'form-control';
    this.service.getVRLog(this.dataSearch).subscribe(
      data =>  this.mapdata(data)
     );
  }
  
  submit() {
    this.dataSearch = new Object ;
    let isNull = 0;
    if(this.formBasic.controls['selectName'].value === 0 || this.formBasic.controls['selectName'].value === undefined){
      isNull = isNull+1;
      this.selectNameClass = 'form-control text-error';
      this.selectNameClassErrorMsg = 'กรุณาระบุ ชื่อกิจกรรม';
      return;
    }else{
      this.selectNameClass = 'form-control select-form';
      this.selectNameClassErrorMsg = ''
    
    }

    if(isNull == 0){
      if(this.formBasic.controls['statusticketName'].value == null || this.formBasic.controls['statusticketName'].value == '' ){
        this.formBasic.controls['statusticketName'].setValue('');
      }
      if(this.formBasic.controls['orderNumber'].value == null || this.formBasic.controls['orderNumber'].value == '' ){
        this.formBasic.controls['orderNumber'].setValue('');
      }
      if(this.formBasic.controls['selectticketName'].value == 0 || this.formBasic.controls['selectticketName'].value == undefined){
        this.formBasic.controls['selectticketName'].setValue(0);
      }
      
      const log = new Object();
      log['status'] = this.formBasic.controls['statusticketName'].value
      log['eventId'] = this.formBasic.controls['selectName'].value
      log['ticketId'] = this.formBasic.controls['selectticketName'].value
      log['orderNumber'] = this.formBasic.controls['orderNumber'].value
       //console.log("Request Data",log);
      this.dataSearch = log ;
      this.service.getVRLog(log).subscribe(
       data =>  this.mapdata(data)
      );
      
    }
  }
  aaaa(event){
    console.log('>>>',event);
    this.searchControl.valueChanges
    .pipe(debounceTime(200))
    .subscribe(value => {
      this.filerData(value);
    });
  }
  mapdata(data){
    // console.log('data', data);
    this.searchControl.valueChanges
    .pipe(debounceTime(200))
    .subscribe(value => {
      this.filerData(value);
    });
    this.vrLog = data
    this.search = true;
    this.preApproveVrlog = data;
    this.filteredPreApproveVrlog = data;
    this.moduleLoading = false;
  }
  clearCriteria(){
    // window.location.reload();
    this. buildFormBasic();
    this.formBasic.controls['selectName'].setValue(0);
    this.formBasic.controls['selectticketName'].setValue(0);
    this.preApproveVrlog = [];
    this.filteredPreApproveVrlog = [];
    this.selectNameClass = 'form-control select-form';
    this.selectNameClassErrorMsg= '';
    this.selectticketNameClass = 'form-control select-form';
    this.selectticketNameClassErrorMsg = '';
    this.ordernumberNameClass = 'form-control';
    this.selectvrlistStatusClass = 'form-control';

  }

  closeModal(){
    this.modalService.dismissAll();
    
  }

  vrHistory(vrItem){
    console.log('vrItem >> ',vrItem )
    this.getVRLog(vrItem.orderNumber);
  }

  getVRLog(orderId) {
    this.service.getListVRLogById(orderId).subscribe(data => {
      this.listVRLog = data;
    });
  }
}
