import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vrgo-admin-users-edit',
  templateUrl: './vrgo-admin-users-edit.component.html',
  styleUrls: ['./vrgo-admin-users-edit.component.scss']
})
export class VrgoAdminUsersEditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
