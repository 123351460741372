import { Component, OnInit, ViewChild ,Output,EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VRGoAdminModalComponent } from '../vrgo-admin-modal/vrgo-admin-modal.component';
import { MessageModal } from 'src/app/shared/models/message-modal.modal';
import { UserAdmin } from 'src/app/shared/models/userAdmin.model';

@Component({
  selector: 'app-vrgo-admin-create-user',
  templateUrl: './vrgo-admin-create-user.component.html',
  styleUrls: ['./vrgo-admin-create-user.component.scss']
})
export class VRGoAdminCreateUserComponent implements OnInit {
  @ViewChild(VRGoAdminModalComponent, { static: false }) vrgoAdminModal: VRGoAdminModalComponent;
  @Output() messageEvent = new EventEmitter<any>();

  formUserAdmin: FormGroup;
  formAddUserAdmin: FormGroup;
  searchControl: FormControl = new FormControl();
  roleList : [];
  userAdminList: UserAdmin[];
  filteredUserAdmin: UserAdmin[];

  search: boolean = false;
  moduleLoading: boolean = false;

  mode: string = '';
  submited: boolean = false;

  fileToUpload: File = null;
  imgProfile: string = null; 
  sizeFile : number = 1048576;
  dialogSuccessMessage : string = 'ระบบทำการบันทึกเสร็จเรียบร้อย'; 

  constructor(
    private service: HttpClientService,
    private modalService: NgbModal,
    private modaladdUser: NgbActiveModal  
  ) { }

  get addUserForm() { return this.formAddUserAdmin.controls; }

  ngOnInit() {
    this.buildFormUserAdmin();
    this.buildFormAddUserAdmin();
    this.getRole();
  }

  buildFormUserAdmin(){
    this.formUserAdmin = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      username: new FormControl(''),
      role: new FormControl('')
    });
  }

  buildFormAddUserAdmin(){
    this.formAddUserAdmin = new FormGroup({
      firstName: new FormControl('',Validators.required),
      lastName: new FormControl('',Validators.required),
      email: new FormControl('',Validators.required),
      tel: new FormControl('',Validators.required),
      userName: new FormControl('',Validators.required),
      password: new FormControl('',Validators.required),
      imgProfile: new FormControl(''),
      confirmPassword: new FormControl('',Validators.required),
      roleId: new FormControl('',Validators.required)
    });
  }

  getRole(){
    this.service.getRole().subscribe((items) => {
      this.roleList = items;
    });
  }

  filerData(val) {
    if (val) {
      val = val.toLowerCase();
    } else {
      return this.filteredUserAdmin = [...this.userAdminList];
    }

    const columns = Object.keys(this.userAdminList[0]);
    if (!columns.length) {
      return;
    }

    const rows = this.userAdminList.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        const column = columns[i];
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });
    this.filteredUserAdmin = rows;
  }

  submit() {
    const dataObj = new Object();
    dataObj['firstName'] = this.formUserAdmin.value.firstName;
    dataObj['lastName'] = this.formUserAdmin.value.lastName;
    dataObj['userName'] = this.formUserAdmin.value.username;
    dataObj['role_id'] = this.formUserAdmin.value.role;

    console.log(JSON.stringify(dataObj));
    this.moduleLoading = true;
    this.service.getAllUserAdmin(dataObj).subscribe((items) => {
      console.log(items);
      this.userAdminList = items;
      if(this.userAdminList.length > 0){
        this.search=true;
      }else{
        this.search=false;
      }
      this.filteredUserAdmin = items;
      this.moduleLoading = false;
    }, error => {
      console.log(error);
      this.moduleLoading = false;
    });
  }

  addUser(content){
    this.mode = 'INSERT';
    this.submited = false;
    this.buildFormAddUserAdmin();
    this.modaladdUser = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'user-modal' });
  }

  clearCriteria(){
    this.buildFormUserAdmin();
    this.userAdminList = [];
    this.filteredUserAdmin = [];
    this.search = false;
  }

  afterCallModal(event){
    this.modaladdUser.dismiss();
  }

  handleFileInput(files: FileList) {
    console.log('handleFileInput');
    this.fileToUpload = files.item(0);
      if(this.fileToUpload.type == "image/png" || this.fileToUpload.type == "image/jpeg"){
          //this.uploadFileToActivity(); 
      }else{
       this.dialogSuccessMessage = 'ชนิดของไฟล์รูปไม่ถูกต้อง ชนิดไฟล์รูปต้องเป็น (JPG,PNG)';
      }
  }
  

  clearFile(){
    this.fileToUpload = null;
  }

  saveUser(){
    const password = this.formAddUserAdmin.value.password;
    const confirmPassword = this.formAddUserAdmin.value.confirmPassword;
    this.submited = true;
    console.log(this.formAddUserAdmin);

    if(password != confirmPassword){
      const messageError = new MessageModal;
      messageError.title = 'แจ้งเตือน';
      messageError.description = 'กรุณาระบุรหัสผ่านให้ถูกต้อง';
      this.vrgoAdminModal.openMessageModal(messageError);
    }else{
      if(this.formAddUserAdmin.status === 'VALID'){
        const userAdminObject = new UserAdmin;
        let objKey = Object.keys(this.formAddUserAdmin.value);
        for (const key of objKey) {
          userAdminObject[key] =  this.formAddUserAdmin.value[key];
        }
        console.log('userAdminObject : '+JSON.stringify(userAdminObject));
        this.service.createUserAdmin(userAdminObject).subscribe(data =>{
          console.log(data);
          const messageError = new MessageModal;
          messageError.title = 'แจ้งเตือน';
          messageError.description = data['message'];
          this.vrgoAdminModal.openMessageModal(messageError);
        });
      }
    }
  }

}
