import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { FormControl, FormGroup } from '@angular/forms';
import { FunctionUtils } from 'src/app/shared/utils/functionUtils';
import { DatePipe } from '@angular/common';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'src/app/shared/authentication/token-storage.service';

@Component({
  selector: 'app-vrgo-admin-report-user',
  templateUrl: './vrgo-admin-report-user.component.html',
  styleUrls: ['./vrgo-admin-report-user.component.scss']
})
export class VrgoAdminReportUserComponent implements OnInit {

  constructor(private service: HttpClientService,
    private fn: FunctionUtils,
    private modaladdUser: NgbActiveModal  ,
    private datePipe: DatePipe,
    private tokenService : TokenStorageService
    ) { }

  userProfile : any;

  selectName: string = '';
  selectNameClass: string = 'form-control select-form';
  selectNameClassErrorMsg: string = '';

  startDate: string = '';
  startDateClass: string = 'form-control';
  startDateClassErrorMsg: string = '';

  endDate: string = '';
  endDateClass: string = 'form-control';
  endDateClassErrorMsg: string = '';

  formBasic: FormGroup;

  listEventAll: any = [];
  eventId : any ;
  listTicketByEvent: any;
  ticketId: any;

  ngOnInit() {
    this.userProfile = JSON.parse(this.tokenService.getUserProfile());
    this.buildFormBasic();
    this.getEventListOrder();
  }
  getEventListOrder(){
    this.service.getEventListOrder(this.userProfile.roleId).subscribe(data => {this.mapEevetListOrder(data)});
   
  }
  mapEevetListOrder(data : any){
    // console.log('event=',data);
  this.listEventAll = data ;

  }
  getEventIdByEvent(event){
    let eventId = event.target.value;
    this.eventId = eventId
    console.log('this.eventId =',this.eventId);
    this.service.getTicketByEvent(eventId).subscribe(data => { this.mapTicketByEvent(data) });
  }
  mapTicketByEvent(data: any) {
    // console.log('ticket=',data);
    this.listTicketByEvent = data;
    this.ticketId = this.listTicketByEvent.ticketId
  }
  
  buildFormBasic() {
    this.formBasic = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      selectticketName : new FormControl('')
    });
  }

  submit(){
    let startDate = this.fn.formatDate(this.formBasic.value.startDate);
    let endDate = this.fn.formatDate(this.formBasic.value.endDate);
    let isNull = 0;
    /*if(this.eventId === 0){
      isNull = isNull+1;
      this.selectNameClass = 'form-control text-error';
      this.selectNameClassErrorMsg = 'กรุณาระบุ ชื่อกิจกรรม';
    }else{
      this.selectNameClass = 'form-control select-form';
      this.selectNameClassErrorMsg = ''
    }*/
    if(startDate == null || startDate == '' ){
      isNull = isNull+1;
      this.startDateClass = 'form-control text-error';
      this.startDateClassErrorMsg = 'กรุณาระบุ วันที่เริ่มบริการ';
    }else{
      this.startDateClass = 'form-control';
      this.startDateClassErrorMsg = '';
    }
    if(endDate == null || endDate == '' ){
      isNull = isNull+1;
      this.endDateClass = 'form-control text-error';
      this.endDateClassErrorMsg = 'กรุณาระบุ วันที่สิ้นสุดการบริการ';
    }else{
      this.endDateClass = 'form-control';
      this.endDateClassErrorMsg = '';
    }
    if(isNull === 0){
      if(this.ticketId === '' || this.ticketId === undefined || this.ticketId === -1){
        this.ticketId = '0';
        this.service.userSummary(startDate,endDate)
      }else{
        this.service.userSummary(startDate,endDate)
      }
    }
    // console.log('isNull', isNull);
    // console.log('startDate', startDate);
    // console.log('endDate', endDate);
    // console.log('status', this.status);
    // console.log('eventId', this.eventId);
     
    // }  
  }
  clearCriteria(){
    // this.buildFormBasic();
    // this.getEventListOrder();
    // this.getStatusListOrder();
    // this.listTicketByEvent =[];
    // this.preApprovePayment = [];
    // this.filteredPreApprovePayment = [];
    window.location.reload();
  }
}
