import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { FormControl, FormGroup } from '@angular/forms';
import { FunctionUtils } from 'src/app/shared/utils/functionUtils';
import { DatePipe } from '@angular/common';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FileSaverService } from 'ngx-filesaver';
import { TokenStorageService } from 'src/app/shared/authentication/token-storage.service';

@Component({
  selector: 'app-vrgo-admin-reportorder',
  templateUrl: './vrgo-admin-reportorder.component.html',
  styleUrls: ['./vrgo-admin-reportorder.component.scss']
})
export class VrgoAdminReportorderComponent implements OnInit {

  constructor(
    private service: HttpClientService,
    private fn: FunctionUtils,
    private modaladdUser: NgbActiveModal  ,
    private datePipe: DatePipe,
    private fileSaverService : FileSaverService,
    private tokenService : TokenStorageService
    ) { }
  
  userProfile : any;

  formBasic: FormGroup;
  // eventId : any ;
  eventId : string = '';
  eventNameEng : any = '';
  status : any = '';

  selectName: string = '';
  selectNameClass: string = 'form-control select-form';
  selectNameClassErrorMsg: string = '';

  startDate: string = '';
  startDateClass: string = 'form-control';
  startDateClassErrorMsg: string = '';

  endDate: string = '';
  endDateClass: string = 'form-control';
  endDateClassErrorMsg: string = '';

  // startDate: any;
  // endDate: any ;
  listEventAll: any = [];
  statusListOrder : any =[];

  ngOnInit() {
    this.userProfile = JSON.parse(this.tokenService.getUserProfile());
    this.buildFormBasic();
    this.getEventListOrder();
    this.getStatusListOrder();
  }

  getEventListOrder(){
    this.service.getEventListOrder(this.userProfile.roleId).subscribe(data => {this.mapEevetListOrder(data)});
   
  }
  getStatusListOrder(){
    this.service.getStatusListOrder().subscribe(data => {this.mapStatusListOrder(data)});
  }

  mapEevetListOrder(data : any){
  this.listEventAll = data ;

  }
  mapStatusListOrder(data : any){
    // console.log('status=',data);
  this.statusListOrder = data ;
  }
  getEventIdByEvent(event){
    const f = this.listEventAll.filter(c => c.eventId == event.target.value);
    this.eventNameEng = f[0].eventNameEng ;
    this.listEventAll 
  }
  getStatusByName(event){
    this.status = event.target.value;
  }

  buildFormBasic() {
    this.formBasic = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      event: new FormControl(''),
    });
  }

  submit(){
    let startDate = this.fn.formatDate(this.formBasic.value.startDate);
    let endDate = this.fn.formatDate(this.formBasic.value.endDate);
    this.eventId = this.formBasic.controls['event'].value;
    let isNull = 0;
    // console.log(this.eventId)
    if(this.eventId === undefined || this.eventId === null || this.eventId ===  ''){
      this.eventId = '0';
    }
    // console.log(this.eventId)
    if(this.eventId === '0'){
      isNull = isNull+1;
      this.selectNameClass = 'form-control text-error';
      this.selectNameClassErrorMsg = 'กรุณาระบุ ชื่อกิจกรรม';
    }else{
      this.selectNameClass = 'select-form form-control';
      this.selectNameClassErrorMsg = ''
      // console.log(this.eventId)
    }
    if(startDate == null || startDate == '' ){
      isNull = isNull+1;
      this.startDateClass = 'form-control text-error';
      this.startDateClassErrorMsg = 'กรุณาระบุ วันที่เริ่มบริการ';
    }else{
      this.startDateClass = 'form-control';
      this.startDateClassErrorMsg = '';
    }
    if(endDate == null || endDate == '' ){
      isNull = isNull+1;
      this.endDateClass = 'form-control text-error';
      this.endDateClassErrorMsg = 'กรุณาระบุ วันที่สิ้นสุดการบริการ';
    }else{
      this.endDateClass = 'form-control';
      this.endDateClassErrorMsg = '';
    }
    if(isNull === 0){
      if(this.status === '' || this.status === undefined || this.status === -1){
        this.status = '';
        this.service.orderTransactionSummary(this.status,this.eventId,this.eventNameEng,startDate,endDate).subscribe(response => {
          let filename = 'downloadreport';
          let disposition = response.headers.get('Content-Disposition');
          let filenameRegex =  /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g,'');
          let blob:any = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); 
          this.fileSaverService.save(blob, filename);
        },err =>{
          console.log(err);
        });
      }else{
        this.service.orderTransactionSummary(this.status,this.eventId,this.eventNameEng,startDate,endDate).subscribe(response => {
          let filename = 'downloadreport';
          let disposition = response.headers.get('Content-Disposition');
          let filenameRegex =  /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g,'');
          let blob:any = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); 
          this.fileSaverService.save(blob, filename);
        },err =>{
          console.log(err);
        });
      }
    }
  }
  clearCriteria(){
    // this.buildFormBasic();
    // this.getEventListOrder();
    // this.getStatusListOrder();
    // this.listTicketByEvent =[];
    // this.preApprovePayment = [];
    // this.filteredPreApprovePayment = [];
    window.location.reload();
  }
  afterCallModal(event){
    this.modaladdUser.dismiss();
  }
}
