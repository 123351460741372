import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserProfile } from '../class/UserProfile';
import { ZipCode } from '../class/ZipCode';
import { Province } from '../class/Province';
import { Amphur } from '../class/Amphur';
import { Distict } from '../class/distict';
import { EventModel } from '../models/event.model'
import { EmailModel } from '../models/email.model'
import { EBibECertConfig } from '../models/ebib-ecert-config.model';
import { orderPayment } from '../models/order-payment.model';
import { UserProfiles } from '../models/userprofile.model';
import { TicketModel } from '../models/ticket.model';
import { TicketPriceModel } from '../models/ticketprice.model';
import { TicketFieldModel } from '../models/ticket-field.model';
import { TicketFieldObjectModel } from '../models/field-object.model';
import { CouponModel } from '../models/coupon.model';

const httpOptions = {
};

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  constructor(
    private httpClient: HttpClient
  ) { }

  productionMode: boolean = true;


  deleteVRLog(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/VRController/deleteVRLog?vrLogId=' + param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/VRController/deleteVRLog?vrLogId=' + param, httpOptions);
  }
  deleteEventTag(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/EventController/deleteEventTag?eventTagId=' + param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/EventController/deleteEventTag?eventTagId=' + param, httpOptions);
  }
  editEventTag(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/EventController/editEventTag', param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/EventController/editEventTag', param, httpOptions);
  }
  cancelOrder(orderNumber, updateBy): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/cancelOrder?orderNumber=' + orderNumber + '&' + 'updateBy=' + updateBy, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/cancelOrder?orderNumber=' + orderNumber + '&' + 'updateBy=' + updateBy, httpOptions);
  }
  deleteSectionContent(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EventController/deleteSectionContent?sectionContentId=' + param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/EventController/deleteSectionContent?sectionContentId=' + param, httpOptions);
  }
  deleteSection(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EventController/deleteSection?sectionId=' + param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/EventController/deleteSection?sectionId=' + param, httpOptions);
  }
  deleteTicketPrice(param): Observable<TicketPriceModel> {
    if (this.productionMode)
      return this.httpClient.post<TicketPriceModel>('/TicketController/deleteTicketPrice?ticketPriceId=' + param, httpOptions);
    else
      return this.httpClient.post<TicketPriceModel>('http://localhost:8003/TicketController/deleteTicketPrice?ticketPriceId=' + param, httpOptions);
  }
  deleteTicketField(param): Observable<TicketFieldModel> {
    if (this.productionMode)
      return this.httpClient.post<TicketFieldModel>('/TicketController/deleteTicketField?ticketFieldId=' + param, httpOptions);
    else
      return this.httpClient.post<TicketFieldModel>('http://localhost:8003/TicketController/deleteTicketField?ticketFieldId=' + param, httpOptions);
  }
  deleteTicketFieldObject(param): Observable<TicketFieldObjectModel> {
    if (this.productionMode)
      return this.httpClient.post<TicketFieldObjectModel>('/TicketController/deleteTicketFieldObject?fieldObjectId=' + param, httpOptions);
    else
      return this.httpClient.post<TicketFieldObjectModel>('http://localhost:8003/TicketController/deleteTicketFieldObject?fieldObjectId=' + param, httpOptions);
  }
  editFieldObject(param): Observable<TicketFieldObjectModel> {
    if (this.productionMode)
      return this.httpClient.post<TicketFieldObjectModel>('/TicketController/editFieldObject', param, httpOptions);
    else
      return this.httpClient.post<TicketFieldObjectModel>('http://localhost:8003/TicketController/editFieldObject', param, httpOptions);
  }
  getFieldObjectById(param): Observable<TicketFieldObjectModel> {
    if (this.productionMode)
      return this.httpClient.post<TicketFieldObjectModel>('/TicketController/getFieldObjectById', param, httpOptions);
    else
      return this.httpClient.post<TicketFieldObjectModel>('http://localhost:8003/TicketController/getFieldObjectById', param, httpOptions);
  }
  saveTicketImage(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/TicketController/saveTicketImage', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/TicketController/saveTicketImage', param, httpOptions);
  }
  editTicketField(param): Observable<TicketFieldModel> {
    if (this.productionMode)
      return this.httpClient.post<TicketFieldModel>('/TicketController/editTicketField', param, httpOptions);
    else
      return this.httpClient.post<TicketFieldModel>('http://localhost:8003/TicketController/editTicketField', param, httpOptions);
  }
  listTicketField(param): Observable<TicketFieldModel> {
    if (this.productionMode)
      return this.httpClient.post<TicketFieldModel>('/TicketController/listTicketField', param, httpOptions);
    else
      return this.httpClient.post<TicketFieldModel>('http://localhost:8003/TicketController/listTicketField', param, httpOptions);
  }
  editTicketPrice(param): Observable<TicketPriceModel> {
    if (this.productionMode)
      return this.httpClient.post<TicketPriceModel>('/TicketController/editTicketPrice', param, httpOptions);
    else
      return this.httpClient.post<TicketPriceModel>('http://localhost:8003/TicketController/editTicketPrice', param, httpOptions);
  }
  addTicket(param): Observable<TicketModel> {
    if (this.productionMode)
      return this.httpClient.post<TicketModel>('/TicketController/addTicket', param, httpOptions);
    else
      return this.httpClient.post<TicketModel>('http://localhost:8003/TicketController/addTicket', param, httpOptions);
  }
  editTicket(param): Observable<TicketModel> {
    if (this.productionMode)
      return this.httpClient.post<TicketModel>('/TicketController/editTicket', param, httpOptions);
    else
      return this.httpClient.post<TicketModel>('http://localhost:8003/TicketController/editTicket', param, httpOptions);
  }
  listTicketPrice(param): Observable<TicketPriceModel> {
    if (this.productionMode)
      return this.httpClient.post<TicketPriceModel>('/TicketController/listTicketPrice', param, httpOptions);
    else
      return this.httpClient.post<TicketPriceModel>('http://localhost:8003/TicketController/listTicketPrice', param, httpOptions);
  }
  getTicketById(param): Observable<TicketModel> {
    if (this.productionMode)
      return this.httpClient.post<TicketModel>('/TicketController/getTicketById', param, httpOptions);
    else
      return this.httpClient.post<TicketModel>('http://localhost:8003/TicketController/getTicketById', param, httpOptions);
  }
  saveImageEventSection(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EventController/saveImageEventSection', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/EventController/saveImageEventSection', param, httpOptions);
  }
  editSectionContent(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/EventController/editSectionContent', param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/EventController/editSectionContent', param, httpOptions);
  }
  addEvent(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/EventController/addEvent', param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/EventController/addEvent', param, httpOptions);
  }
  saveImageEvent(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EventController/saveImageEvent', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/EventController/saveImageEvent', param, httpOptions);
  }
  resetPassword(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/UserProfileController/resetPassword', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/UserProfileController/resetPassword', param, httpOptions);
  }
  listUser(param): Observable<UserProfiles> {
    if (this.productionMode)
      return this.httpClient.post<UserProfiles>('/UserProfileController/listUser', param, httpOptions);
    else
      return this.httpClient.post<UserProfiles>('http://localhost:8003/UserProfileController/listUser', param, httpOptions);
  }
  editUser(param): Observable<UserProfiles> {
    if (this.productionMode)
      return this.httpClient.post<UserProfiles>('/UserProfileController/editUser', param, httpOptions);
    else
      return this.httpClient.post<UserProfiles>('http://localhost:8003/UserProfileController/editUser', param, httpOptions);
  }
  editOrder(param): Observable<orderPayment> {
    if (this.productionMode)
      return this.httpClient.post<orderPayment>('/PaymentController/editOrder', param, httpOptions);
    else
      return this.httpClient.post<orderPayment>('http://localhost:8003/PaymentController/editOrder', param, httpOptions);
  }
  getAllProvince(): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<Province>('/CommonController/getAllProvince', httpOptions)
    else
      return this.httpClient.post<Province>('http://localhost:8003/CommonController/getAllProvince', httpOptions)
  }

  getAmphureByProvince(param): Observable<Amphur> {
    if (this.productionMode)
      return this.httpClient.post<Amphur>('/CommonController/getAmphureByProvince?provinceId=' + param, httpOptions)
    else
      return this.httpClient.post<Amphur>('http://localhost:8003/CommonController/getAmphureByProvince?provinceId=' + param, httpOptions)
  }

  getDistrictByAmphure(param): Observable<Distict> {
    if (this.productionMode)
      return this.httpClient.post<Distict>('/CommonController/getDistrictByAmphure?amphureId=' + param, httpOptions)
    else
      return this.httpClient.post<Distict>('http://localhost:8003/CommonController/getDistrictByAmphure?amphureId=' + param, httpOptions)
  }

  getZipcodeByDistrict(districtId, amphureCode): Observable<ZipCode> {
    if (this.productionMode)
      return this.httpClient.post<ZipCode>('/CommonController/getZipcodeByDistrict?districtId=' + districtId + '&amphureCode=' + amphureCode, httpOptions)
    else
      return this.httpClient.post<ZipCode>('http://localhost:8003/CommonController/getZipcodeByDistrict?districtId=' + districtId + '&amphureCode=' + amphureCode, httpOptions)
  }
  getOrderByOrderNumber(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/getOrderByOrderNumber?orderNumber=' + param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/getOrderByOrderNumber?orderNumber=' + param, httpOptions);
  }
  sendMail(param): Observable<EmailModel> {
    if (this.productionMode)
      return this.httpClient.post<EmailModel>('/PaymentController/resendEmailConfirmOrder?orderNumber=' + param, httpOptions);
    else
      return this.httpClient.post<EmailModel>('http://localhost:8003/PaymentController/resendEmailConfirmOrder?orderNumber=' + param, httpOptions);
  }
  approveSlipWithImage(param1, param2, param3, param4): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/approveSlipWithImage?orderNumber=' + param1 + '&' + 'approveStatus=' + param2 + '&' + 'imageSlip=' + param3 + '&' + 'approveBy=' + param4, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/approveSlipWithImage?orderNumber=' + param1 + '&' + 'approveStatus=' + param2 + '&' + 'imageSlip=' + param3 + '&' + 'approveBy=' + param4, httpOptions);
  }
  uploadimageSlip(fileToUpload: File, orderNumber: string, userId: string, ticketId: string): Observable<boolean> {
    if (this.productionMode) {
      const endpoint = '/PaymentController/imageSlip';
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append('orderNumber', orderNumber);
      formData.append('userId', userId);
      formData.append('ticketId', ticketId);
      return this.httpClient.post<any>(endpoint, formData, httpOptions)
    } else {
      const endpoint = 'http://localhost:8003/PaymentController/imageSlip';
      const formData: FormData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append('orderNumber', orderNumber);
      formData.append('userId', userId);
      formData.append('ticketId', ticketId);
      return this.httpClient.post<any>(endpoint, formData, httpOptions);
    }
  }
  deleteEventImage(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/EventController/deleteEventImage?eventImageId=' + param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/EventController/deleteEventImage?eventImageId=' + param, httpOptions);
  }
  deleteSectionEventImage(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/EventController/deleteSectionEventImage?eventSectionImageId=' + param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/EventController/deleteSectionEventImage?eventSectionImageId=' + param, httpOptions);
  }
  editSection(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/EventController/editSection', param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/EventController/editSection', param, httpOptions);
  }
  getContentById(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/EventController/getSectionById?sectionId=' + param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/EventController/getSectionById?sectionId=' + param, httpOptions);
  }
  getSectionById(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/EventController/listSectionById?eventId=' + param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/EventController/listSectionById?eventId=' + param, httpOptions);
  }
  getEventById(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/EventController/getEventById', param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/EventController/getEventById', param, httpOptions);
  }
  getEventTagById(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/EventController/getEventTagById', param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/EventController/getEventTagById', param, httpOptions);
  }
  saveEditEvent(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/EventController/editEvent', param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/EventController/editEvent', param, httpOptions);
  }
  getlistEvent(param): Observable<EventModel> {
    if (this.productionMode)
      return this.httpClient.post<EventModel>('/EventController/listEvent', param, httpOptions);
    else
      return this.httpClient.post<EventModel>('http://localhost:8003/EventController/listEvent', param, httpOptions);
  }

  orderTransactionSummary(status, eventId, eventName, dateFrom, dateTo) {
    if (this.productionMode) {
      //window.location.href = '/ReportController/orderTransactionSummary?status='+status+'&'+'eventId='+eventId+'&'+'eventName='+eventName+'&'+'dateFrom='+dateFrom+'&'+'dateTo='+dateTo;
      return this.httpClient.get<any>('/ReportController/orderTransactionSummary?status=' + status + '&' + 'eventId=' + eventId + '&' + 'eventName=' + eventName + '&' + 'dateFrom=' + dateFrom + '&' + 'dateTo=' + dateTo, { observe: 'response', responseType: 'blob' as 'json' });
    } else {
      //window.location.href = 'http://localhost:8003/ReportController/orderTransactionSummary?status='+status+'&'+'eventId='+eventId+'&'+'eventName='+eventName+'&'+'dateFrom='+dateFrom+'&'+'dateTo='+dateTo;
      return this.httpClient.get<any>('http://localhost:8003/ReportController/orderTransactionSummary?status=' + status + '&' + 'eventId=' + eventId + '&' + 'eventName=' + eventName + '&' + 'dateFrom=' + dateFrom + '&' + 'dateTo=' + dateTo, { observe: 'response', responseType: 'blob' as 'json' });
    }
  }

  getlistOrder(param1, param2, param3, param4, param5): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/listOrder?status=' + param1 + '&' + 'eventId=' + param2 + '&' + 'ticketId=' + param3 + '&' + 'dateFrom=' + param4 + '&' + 'dateTo=' + param5, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/listOrder?status=' + param1 + '&' + 'eventId=' + param2 + '&' + 'ticketId=' + param3 + '&' + 'dateFrom=' + param4 + '&' + 'dateTo=' + param5, httpOptions);
  }
  getTicketByEvent(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/getTicketByEvent?eventId=' + param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/getTicketByEvent?eventId=' + param, httpOptions);
  }
  getStatusListOrder(): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/getStatusListOrder', httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/getStatusListOrder', httpOptions);
  }
  getEventListOrder(roleId): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/getEventListOrder?roleId='+roleId, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/getEventListOrder?roleId='+roleId, httpOptions);
  }
  approveVRLog(param1, param2 ,param3): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/VRController/approveVRLog?vrLogId=' + param1 + '&' + 'approveStatus=' + param2 + '&' + 'approveDistance=' + param3, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/VRController/approveVRLog?vrLogId=' + param1 + '&' + 'approveStatus=' + param2 + '&' + 'approveDistance=' + param3, httpOptions);
  }
  approveSlip(param1, param2, param3): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/approveSlip?orderNumber=' + param1 + '&' + 'approveStatus=' + param2 + '&' + 'approveBy=' + param3, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/approveSlip?orderNumber=' + param1 + '&' + 'approveStatus=' + param2 + '&' + 'approveBy=' + param3, httpOptions);
  }
  getMenu(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/MenuController/getMenuByRole?role_id=' + param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/MenuController/getMenuByRole?role_id=' + param, httpOptions);
  }
  getListBoxByGroup(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/CommonController/getListBox?listBoxGroup=' + param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/CommonController/getListBox?listBoxGroup=' + param, httpOptions);
  }
  getOrderPayment(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/listOrder?status=' + param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/listOrder?status=' + param, httpOptions);
  }
  getVRLog(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/VRController/listVRLog', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/VRController/listVRLog', param, httpOptions);
  }
  getRole(): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/UserAdminController/getRole', httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/UserAdminController/getRole', httpOptions);
  }
  getAllUserAdmin(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/UserAdminController/getAllUserAdmin', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/UserAdminController/getAllUserAdmin', param, httpOptions);
  }
  createUserProfile(param): Observable<UserProfile> {
    if (this.productionMode)
      return this.httpClient.post<UserProfile>('/UserProfileController/createProfile?', param, httpOptions);
    else
      return this.httpClient.post<UserProfile>('http://localhost:8003/UserProfileController/createProfile?', param, httpOptions);
  }
  getAddressByZipcode(param): Observable<ZipCode> {
    if (this.productionMode)
      return this.httpClient.post<ZipCode>('/CommonController/getAddressByZipcode?zipcode=' + param, httpOptions);
    else
      return this.httpClient.post<ZipCode>('http://localhost:8003/CommonController/getAddressByZipcode?zipcode=' + param, httpOptions);
  }
  createUserAdmin(param): Observable<ZipCode> {
    if (this.productionMode)
      return this.httpClient.post<ZipCode>('/UserAdminController/createUserAdmin', param, httpOptions);
    else
      return this.httpClient.post<ZipCode>('http://localhost:8003/UserAdminController/createUserAdmin', param, httpOptions);
  }
  vrSummary(ticketId, eventId, eventName, dateFrom, dateTo) {
    if (this.productionMode)
      window.location.href = '/ReportController/vrSummary?ticketId=' + ticketId + '&' + 'eventId=' + eventId + '&' + 'eventName=' + eventName + '&' + 'dateFrom=' + dateFrom + '&' + 'dateTo=' + dateTo;
    else
      window.location.href = 'http://localhost:8003/ReportController/vrSummary?ticketId=' + ticketId + '&' + 'eventId=' + eventId + '&' + 'eventName=' + eventName + '&' + 'dateFrom=' + dateFrom + '&' + 'dateTo=' + dateTo;
  }
  userSummary(dateFrom, dateTo) {
    if (this.productionMode)
      window.location.href = '/ReportController/userSummary?dateFrom=' + dateFrom + '&' + 'dateTo=' + dateTo;
    else
      window.location.href = 'http://localhost:8003/ReportController/userSummary?dateFrom=' + dateFrom + '&' + 'dateTo=' + dateTo;
  }
  getEventListOrderVR(): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/getEventListOrderVR', httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/getEventListOrderVR', httpOptions);
  }

  getListEbibConfig(param: FormData): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EbibEcertController/listConfigEbib', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/EbibEcertController/listConfigEbib', param, httpOptions);
  }

  getListEcertConfig(param: FormData): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EbibEcertController/listConfigEcert', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/EbibEcertController/listConfigEcert', param, httpOptions);
  }

  previewEbib(param: EBibECertConfig): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EbibEcertController/previewEbib', param, httpOptions);
    else
      return this.httpClient.post<any>('http://103.86.48.68:8901/EbibEcertController/previewEbib', param, httpOptions);
  }

  previewEcert(param: EBibECertConfig): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EbibEcertController/previewEcert', param, httpOptions);
    else
      return this.httpClient.post<any>('http://103.86.48.68:8901/EbibEcertController/previewEcert', param, httpOptions);
  }

  uploadImageEbib(param: FormData): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EbibEcertController/uploadImageEbib', param, httpOptions);
    else
      return this.httpClient.post<any>('http://103.86.48.68:8901/EbibEcertController/uploadImageEbib', param, httpOptions);
  }

  uploadImageEcert(param: FormData): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EbibEcertController/uploadImageEcert', param, httpOptions);
    else
      return this.httpClient.post<any>('http://103.86.48.68:8901/EbibEcertController/uploadImageEcert', param, httpOptions);
  }

  saveConfigEbib(param: EBibECertConfig): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EbibEcertController/saveConfigEbib', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/EbibEcertController/saveConfigEbib', param, httpOptions);
  }

  saveConfigEcert(param: EBibECertConfig): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EbibEcertController/saveConfigEcert', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/EbibEcertController/saveConfigEcert', param, httpOptions);
  }

  getConfigEbadge(param: FormData): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EbadgeController/getConfigEbadge', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/EbadgeController/getConfigEbadge', param, httpOptions);
  }

  saveConfigEbadge(param: FormData): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EbadgeController/saveConfigEbadge', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/EbadgeController/saveConfigEbadge', param, httpOptions);
  }

  getListEbadgeConfig(param: FormData): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/EbadgeController/getListEbadgeConfig', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/EbadgeController/getListEbadgeConfig', param, httpOptions);
  }

  getListVRLogById(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/VRController/listVRLogById?orderNumber=' + param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/VRController/listVRLogById?orderNumber=' + param, httpOptions);
  }
  uploadTrackingNum(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/CommonController/importTrackingNumber', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/CommonController/importTrackingNumber', param, httpOptions);
  }
  saveChangeTicket(param, param2, param3) {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/changeTicket?vrId=' + param + '&' + 'ticketId=' + param2 + '&' + 'orderDetailId=' + param3, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/changeTicket?vrId=' + param + '&' + 'ticketId=' + param2 + '&' + 'orderDetailId=' + param3, httpOptions);

  }
  editVRLog(param, param2, param3, param4, param5, param6, param7) {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/editVRLog?VRLogId=' + param + '&' + 'userId=' + param2 + '&' + 'distance=' + param3 + '&' + 'location=' + param4 + '&' + 'hour=' + param5 + '&' + 'minute=' + param6 + '&' + 'second=' + param7, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/editVRLog?VRLogId=' + param + '&' + 'userId=' + param2 + '&' + 'distance=' + param3 + '&' + 'location=' + param4 + '&' + 'hour=' + param5 + '&' + 'minute=' + param6 + '&' + 'second=' + param7, httpOptions);

  }
  getListVRByUser(param) {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/getTicketVRByUser?userId=' + param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/getTicketVRByUser?userId=' + param, httpOptions);
  }

  addVRLogAdmin(file, VRId, userId, ticketId, distance, location, hour, minute, second) {

    if (this.productionMode) {
      const endpoint = '/PaymentController/uploadVRLog';
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append('VRId', VRId);
      formData.append('userId', userId);
      formData.append('ticketId', ticketId);
      formData.append('distance', distance);
      formData.append('location', location);
      formData.append('hour', hour);
      formData.append('minute', minute);
      formData.append('second', second);
      return this.httpClient.post<any>(endpoint, formData, httpOptions)
    } else {
      const endpoint = 'http://localhost:8003/PaymentController/uploadVRLog';
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append('VRId', VRId);
      formData.append('userId', userId);
      formData.append('ticketId', ticketId);
      formData.append('distance', distance);
      formData.append('location', location);
      formData.append('hour', hour);
      formData.append('minute', minute);
      formData.append('second', second);
      return this.httpClient.post<any>(endpoint, formData, httpOptions);
    }
  }
  getOrderEditTrans(param) {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/listOrderEditTrans?orderId=' + param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/listOrderEditTrans?orderId=' + param, httpOptions);
  }

  sendResetPasswordMail(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/UserProfileController/sendResetPasswordMail', param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/UserProfileController/sendResetPasswordMail', param, httpOptions);
  }

  sendConfirmPaymentMail(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<any>('/PaymentController/sendConfirmPaymentMail?orderNumber=' + param, httpOptions);
    else
      return this.httpClient.post<any>('http://localhost:8003/PaymentController/sendConfirmPaymentMail?orderNumber=' + param, httpOptions);
  }

  listEventCoupon(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<CouponModel>('/CouponController/listEventCoupon?eventId=' + param, httpOptions);
    else
      return this.httpClient.post<CouponModel>('http://localhost:8003/CouponController/listEventCoupon?eventId=' + param, httpOptions);
  }

  saveCoupon(param): Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<CouponModel>('/CouponController/saveCoupon', param, httpOptions);
    else
      return this.httpClient.post<CouponModel>('http://localhost:8003/CouponController/saveCoupon', param, httpOptions);
  }

  couponSummary(couponId, eventName) {
    if (this.productionMode)
      window.location.href = '/CouponController/couponSummary?couponId=' + couponId + '&' + 'eventName=' + eventName;
    else
      window.location.href = 'http://localhost:8003/CouponController/couponSummary?couponId=' + couponId + '&' + 'eventName=' + eventName;
  }

  searchCode(eventId, couponCode) : Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<CouponModel>('/CouponController/searchCode?eventId=' + eventId + '&' + 'couponCode=' + couponCode, httpOptions);
    else
      return this.httpClient.post<CouponModel>('http://localhost:8003/CouponController/searchCode?eventId=' + eventId + '&' + 'couponCode=' + couponCode, httpOptions);
  }
  redeemCode(param) : Observable<any> {
    if (this.productionMode)
      return this.httpClient.post<CouponModel>('/CouponController/redeemCode',param, httpOptions);
    else
      return this.httpClient.post<CouponModel>('http://localhost:8003/CouponController/redeemCode',param, httpOptions);
  }
  
  orderAllSummary(month, year) {
    if (this.productionMode)
      window.location.href = '/ReportController/orderTransactionSummaryAll?month=' + month + '&' + 'year=' + year;
    else
      window.location.href = 'http://localhost:8003/ReportController/orderTransactionSummaryAll?month=' + month + '&' + 'year=' + year;
  }

}