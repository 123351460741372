import { TicketModel} from './ticket.model';
import { EventTagModel } from   './event-tag.model';
import { EventImageModel } from './event-image.model'
import {EventSectionModel } from './event-section.model'

export class EventModel{
    eventId : any ; 
    orgId: number ; 
    eventName : any; 
    eventNameEng : any;
    eventDetail: any; 
    eventDate : any; 
    eventRegisStartDate: any; 
    eventRegisEndDate: any; 
    eventLocation: any; 
    province: any; 
    status: any; 
    shippingStatus: any; 
    shippingFee: any; 
    additionalShippingFee: any; 
    createDate: any; 
    createBy: any; 
    updateDate: any; 
    updateBy: any; 
    registerStatus: any;
    userId: any;
    registerStatusDesc: any;
    eventRegisStartDateDesc: any; 
    eventRegisEndDateDesc: any; 
    eventImage: any; 
    urlPath: any;
    seq:number;
    homeImage:any;
    eventType : any;
    eventLocationEn :any;
    eventCategory : any ;
    couponStatus : any ;
    listTicket : TicketModel [];
    listEventTag : EventTagModel [];
    listEventImage : EventImageModel [];
    listEventSection : EventSectionModel[];
    
}