export class EventSectionContent {
	
	eventSectionContentId : any;
	eventSectionId : any;
	content : any;
	contentEn: any;
	status : any;
	eventId : any;
	seq : any;
    
}