import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { NgbModal, NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'src/app/shared/authentication/token-storage.service';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { CouponModel } from '../../shared/models/coupon.model';

@Component({
  selector: 'app-vrgo-admin-redeem-coupon',
  templateUrl: './vrgo-admin-redeem-coupon.component.html',
  styleUrls: ['./vrgo-admin-redeem-coupon.component.scss']
})
export class VrgoAdminRedeemCouponComponent implements OnInit {

  @ViewChild('saveValidCoupon', { static: false }) saveValidCoupon;
  @ViewChild('warnningValidate', { static: false }) warnningValidate;

  redeemCouponForm: FormGroup;
  listEventAll: any;
  listCouponStatus: any[] = [];
  eventNameErrorMsg: string = '';
  msgDescription: string = '';
  submitRedeem: boolean = false;
  searchStatus: boolean = false;
  userProfile : any;

  constructor(
    private service: HttpClientService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private tokenService : TokenStorageService
  ) { }

  ngOnInit() {
    this.userProfile = JSON.parse(this.tokenService.getUserProfile());
    this.buildRedeemForm();
    this.listEvent();
    this.getCouponStatus();
  }

  buildRedeemForm() {
    this.redeemCouponForm = this.formBuilder.group({
      eventId: new FormControl('', Validators.required),
      couponCode: new FormControl('', Validators.required),
      changeCouponStatus: new FormControl(''),
      couponCodeId : new FormControl(''),
      couponId : new FormControl(''),
    });
  }

  get redeemCoupon() { return this.redeemCouponForm.controls; }

  listEvent() {
    this.service.getEventListOrder(this.userProfile.roleId).subscribe(data => this.listEventAll = data);
  }

  getCouponStatus() {
    this.service.getListBoxByGroup('COUPON_CODE_STATUS').subscribe((data) => {
      var listCouponStatus = [];
      listCouponStatus = data;
      for (let i = 0; i < listCouponStatus.length; i++) {
        if (listCouponStatus[i].value1 != 'USED') {
          this.listCouponStatus.push(listCouponStatus[i]);
        }
      }
    });
  }

  searchCode() {
    this.submitRedeem = true;
    if (this.redeemCouponForm.status == "VALID") {
      this.service.searchCode(this.redeemCoupon.eventId.value, this.redeemCoupon.couponCode.value).subscribe(data => {
        this.redeemCoupon.changeCouponStatus.setValue(data.status);
        this.redeemCoupon.couponCode.setValue(data.couponCode);
        this.redeemCoupon.couponCodeId.setValue(data.couponCodeId);
        this.redeemCoupon.couponId.setValue(data.couponId);
        this.searchStatus = true;
        this.submitRedeem = false;
      });
    }

  }

  submit(status) {
    let couponModel = new CouponModel;
    couponModel['couponCode'] = this.redeemCoupon.couponCode.value;
    couponModel['couponCodeId'] = this.redeemCoupon.couponCodeId.value;
    couponModel['couponId'] = this.redeemCoupon.couponId.value;
    couponModel['status'] = this.redeemCoupon.changeCouponStatus.value;
    if(status == 'true'){
      this.modalService.dismissAll();
      this.service.redeemCode(couponModel).subscribe(data => {
        this.mapChangeStatus(data);
      });
    }else{
      this.msgDescription = 'ยืนยันการแก้ไขข้อมูล';
      this.modalService.open(this.saveValidCoupon, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    }
  }

  mapChangeStatus(data){
    if(data != undefined){
      this.msgDescription = 'ระบบทำการบันทึกเรียบร้อย';
      this.modalService.open(this.warnningValidate , { backdrop: 'static', windowClass: 'myCustomModalClass' });
    }
  }

  clearFrom() {
    this.searchStatus = false;
    this.buildRedeemForm();
  }
}
