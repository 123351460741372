import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VRGoAdminHeaderSidebarComponent } from './vrgo-admin-header-sidebar/vrgo-admin-header-sidebar.component';
import { VRGoAdminSidebarComponent } from './vrgo-admin-sidebar/vrgo-admin-sidebar.component';
import { AuthLayoutComponent } from './vrgo-admin-auth-layout/auth-layout.component';
import { BlankLayoutComponent } from './vrgo-admin-blank-layout/blank-layout.component';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { VRGoAdminComponent } from './vrgo-admin.component';
import { VRGoAdminFooterComponent } from './vrgo-admin-footer/vrgo-admin-footer.component';
import { SharedPipesModule } from '../shared/pipes/shared-pipes.module';
import { SharedDirectivesModule } from '../shared/directives/shared-directives.module';
import { VRGoAdminMainComponent } from './vrgo-admin-main/vrgo-admin-main.component';
import { VRGoAdminRoutingModule } from './vrgo-admin-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FunctionUtils } from '../shared/utils/functionUtils';
import { VRGoAdminModalComponent } from './vrgo-admin-modal/vrgo-admin-modal.component';
import { VRGoAdminCreateUserComponent } from './vrgo-admin-create-user/vrgo-admin-create-user.component';
import { VRGoAdminSearchComponent } from './vrgo-admin-search/vrgo-admin-search.component';
import { VRGoAdminPaymentPreApproveComponent } from './vrgo-admin-payment-pre-approve/vrgo-admin-payment-pre-approve.component';
import { VRGoAdminPaymentPendingComponent } from './vrgo-admin-payment-pending/vrgo-admin-payment-pending.component';
import { VRGoAdminPaymentPaidComponent } from './vrgo-admin-payment-paid/vrgo-admin-payment-paid.component';
import { VRGoAdminApproveVRComponent } from './vrgo-admin-approve-vr/vrgo-admin-approve-vr.component';
import { VrgoAdminOrderpaymentComponent } from './vrgo-admin-orderpayment/vrgo-admin-orderpayment.component';
import { VrgoAdminReportorderComponent } from './vrgo-admin-reportorder/vrgo-admin-reportorder.component';
import { VrgoAdminSearchEventsComponent } from './vrgo-admin-search-events/vrgo-admin-search-events.component';
import { BsDatepickerModule ,DatepickerModule } from 'ngx-bootstrap';
import { VrgoAdminEventDetailComponent } from './vrgo-admin-event-detail/vrgo-admin-event-detail.component';
import { VrgoAdminReportOrderSummaryComponent } from './vrgo-admin-report-order-summary/vrgo-admin-report-order-summary.component';
import { VrgoAdminReportUserComponent } from './vrgo-admin-report-user/vrgo-admin-report-user.component';
import { VrgoAdminConfigEbibComponent } from './vrgo-admin-config-ebib/vrgo-admin-config-ebib.component';
import { VrgoAdminCreateConfigEbibComponent } from './vrgo-admin-create-config-ebib/vrgo-admin-create-config-ebib.component';
import { VrgoAdminConfigEcertComponent } from './vrgo-admin-config-ecert/vrgo-admin-config-ecert.component';
import { VrgoAdminCreateConfigEcertComponent } from './vrgo-admin-create-config-ecert/vrgo-admin-create-config-ecert.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { VrgoAdminOrderEditComponent } from './vrgo-admin-order-edit/vrgo-admin-order-edit.component';
import { VrgoAdminUsersEditComponent } from './vrgo-admin-users-edit/vrgo-admin-users-edit.component';
import { VrgoAdminUsersSearchComponent } from './vrgo-admin-users-search/vrgo-admin-users-search.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { VrgoAdminSectionDetailComponent } from './vrgo-admin-section-detail/vrgo-admin-section-detail.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { VrgoAdminTicketDetailComponent } from './vrgo-admin-ticket-detail/vrgo-admin-ticket-detail.component';
import { VrgoAdminConfigEbadgeComponent } from './vrgo-admin-config-ebadge/vrgo-admin-config-ebadge.component';
import { VrgoAdminListEbadgeComponent } from './vrgo-admin-list-ebadge/vrgo-admin-list-ebadge.component';
import { VrgoAdminUpdateTrackNumberComponent } from './vrgo-admin-update-track-number/vrgo-admin-update-track-number.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import { VrgoAdminRedeemCouponComponent } from './vrgo-admin-redeem-coupon/vrgo-admin-redeem-coupon.component';
import { VrgoAdminReportOrderSummaryAllComponent } from './vrgo-admin-report-order-summary-all/vrgo-admin-report-order-summary-all.component';

// MatFormFieldModule 
@NgModule({
imports: [
  NgbModule,
  VRGoAdminRoutingModule,
  RouterModule,
  FormsModule,
  SharedPipesModule,
  SharedDirectivesModule,
  PerfectScrollbarModule,
  NgxPaginationModule,
  NgxDatatableModule,
  ReactiveFormsModule,
  CommonModule,
  BsDatepickerModule.forRoot(),
  DatepickerModule.forRoot(),
  ColorPickerModule,
  MatSlideToggleModule,
  AngularEditorModule,
  MatChipsModule,
  MatIconModule,
  MatFormFieldModule,
  MatTableModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatSelectModule
],
declarations: [
  VRGoAdminComponent,
  VRGoAdminMainComponent,
  VRGoAdminHeaderSidebarComponent,
  VRGoAdminSidebarComponent,
  VRGoAdminFooterComponent,
  AuthLayoutComponent,
  BlankLayoutComponent,
  VRGoAdminModalComponent,
  VRGoAdminCreateUserComponent,
  VRGoAdminSearchComponent,
  VRGoAdminPaymentPreApproveComponent,
  VRGoAdminPaymentPendingComponent,
  VRGoAdminPaymentPaidComponent,
  VRGoAdminApproveVRComponent,
  VrgoAdminOrderpaymentComponent,
  VrgoAdminReportorderComponent,
  VrgoAdminSearchEventsComponent,
  VrgoAdminEventDetailComponent,
  VrgoAdminReportOrderSummaryComponent,
  VrgoAdminReportUserComponent,
  VrgoAdminConfigEbibComponent,
  VrgoAdminCreateConfigEbibComponent,
  VrgoAdminConfigEcertComponent,
  VrgoAdminCreateConfigEcertComponent,
  VrgoAdminOrderEditComponent,
  VrgoAdminUsersEditComponent,
  VrgoAdminUsersSearchComponent,
  VrgoAdminSectionDetailComponent,
  VrgoAdminTicketDetailComponent,
  VrgoAdminConfigEbadgeComponent,
  VrgoAdminListEbadgeComponent,
  VrgoAdminUpdateTrackNumberComponent,
  VrgoAdminRedeemCouponComponent,
  VrgoAdminReportOrderSummaryAllComponent
],
exports: [],
providers: [FunctionUtils, NgbActiveModal],
bootstrap: [VRGoAdminComponent]
})
export class VRGoAdminModule { }

