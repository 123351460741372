export class EBibECertConfig{
    eventName : string;
	ticketName : string;
	eventId : number;
	ticketId : number;
	configId : number;
	templateImg : string;
	fullName : string;
	nameColor : string;
	nameFontSize : number;
	nameTextAlign : string;
	namePositionX : number;
	namePositionY : number;
	bibNumber : string;
	bibColor : string;
	bibFontSize : number;
	bibTextAlign : string;
	bibPositionX : number;
	bibPositionY : number;
	status : string;
}