export class TicketFieldObjectModel {
	fieldObjectId : any;
	ticketFieldId : any;
	description : any;
	objectDesc : any;
	objectValue : any;
	status : any;
	objectSeq: any;
	fieldInputType: any;
	fieldLabel: any;
}