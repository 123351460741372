import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { EBibECertConfig } from 'src/app/shared/models/ebib-ecert-config.model';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/shared/authentication/token-storage.service';

@Component({
  selector: 'app-vrgo-admin-config-ecert',
  templateUrl: './vrgo-admin-config-ecert.component.html',
  styleUrls: ['./vrgo-admin-config-ecert.component.scss']
})
export class VrgoAdminConfigEcertComponent implements OnInit {
  @ViewChild('SuccessMessage',{static: false}) SuccessMessage;

  searchControl: FormControl = new FormControl();
  moduleLoading: boolean = false;
  search: boolean = false;
  listEbibEcertConfig: EBibECertConfig[];
  filteredListEbibEcertConfig: EBibECertConfig[];
  userProfile : any;


  constructor(
    private service: HttpClientService,
    private router : Router,
    private tokenService : TokenStorageService
    ) { }

  formBasic: FormGroup;

  listEventAll : any ;
  statusListOrder : any;
  listTicketByEvent : any ;

  eventId : number = 0 ;
  ticketId : number = 0 ;

  selectName: string = '';
  selectNameClass: string = 'form-control select-form';
  selectNameClassErrorMsg: string = '';

  selectticketName: string = '';
  selectticketNameClass: string = 'form-control select-form';
  selectticketNameClassErrorMsg: string = '';

  ngOnInit() {
    this.userProfile = JSON.parse(this.tokenService.getUserProfile());
    this.buildFormBasic();

    this.searchControl.valueChanges
    .pipe(debounceTime(200))
    .subscribe(value => {
      this.filerData(value);
    });

    this.getEventListOrder();
    this.getListEcertConfig();
  }

  buildFormBasic() {
    this.formBasic = new FormGroup({
      selectName: new FormControl('0'),
      selectticketName : new FormControl('0')
    });
  }

  getEventListOrder(){
    this.service.getEventListOrder(this.userProfile.roleId).subscribe(data => {this.mapEevetListOrder(data)});
   
  }

  mapEevetListOrder(data : any){
    this.listEventAll = data ;
  }
 
  filerData(val) {
    if (val) {
      val = val.toLowerCase();
    } else {
      return this.filteredListEbibEcertConfig = [...this.listEbibEcertConfig];
    }

    const columns = Object.keys(this.listEbibEcertConfig[0]);
    if (!columns.length) {
      return;
    }

    const rows = this.listEbibEcertConfig.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        const column = columns[i];
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });
    this.filteredListEbibEcertConfig = rows;
  }

  getTicketByEvent(event){
    let eventId = event.target.value;
    this.eventId = eventId;
    this.service.getTicketByEvent(eventId).subscribe(data => {this.mapTicketByEvent(data)});
  }

  mapTicketByEvent(data : any){
    this.listTicketByEvent = data ;
    this.ticketId = this.listTicketByEvent.ticketId
  }

  getListEcertConfig(){
    const param = new FormData();
    param.append('eventId', String(0));
    param.append('ticketId', String(0));
    this.service.getListEcertConfig(param).subscribe(response => {
      this.listEbibEcertConfig = response;
      this.filteredListEbibEcertConfig = response;
    });
  }
  submit() {
    if(this.formBasic.controls['selectName'].value === 0 || this.formBasic.controls['selectName'].value === undefined){
      this.formBasic.controls['selectName'].setValue(0);
    }

    if(this.formBasic.controls['selectticketName'].value == 0 || this.formBasic.controls['selectticketName'].value == undefined){
      this.formBasic.controls['selectticketName'].setValue(0);
    }
      
    const param = new FormData();
    param.append('eventId', this.formBasic.controls['selectName'].value);
    param.append('ticketId', this.formBasic.controls['selectticketName'].value);
    
    this.service.getListEbibConfig(param).subscribe(response => {
      this.listEbibEcertConfig = response;
      this.filteredListEbibEcertConfig = response;
    });
  }

  clearCriteria(){
    // window.location.reload();
    this. buildFormBasic();
    this.formBasic.controls['selectName'].setValue(0);
    this.formBasic.controls['selectticketName'].setValue(0);
    this.listEbibEcertConfig = [];
    this.filteredListEbibEcertConfig = [];
    this.listTicketByEvent = [];
    this.selectNameClass = 'form-control select-form';
    this.selectNameClassErrorMsg= '';
    this.selectticketNameClass = 'form-control select-form';
    this.selectticketNameClassErrorMsg = '';

  }

  createConfigEcert(eventId, ticketId){
    this.router.navigate(['vrgoAdmin/ecert/create/' + eventId + '/' + ticketId ]);
  }

  editConfigEcert(eventId, ticketId){
    this.router.navigate(['vrgoAdmin/ecert/edit/' + eventId + '/' + ticketId ]);
  }
}
