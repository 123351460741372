import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EbadgeConfig } from 'src/app/shared/models/ebadge-config.model';
import { VRGoAdminModalComponent } from '../vrgo-admin-modal/vrgo-admin-modal.component';
import { MessageModal } from 'src/app/shared/models/message-modal.modal';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-vrgo-admin-config-ebadge',
  templateUrl: './vrgo-admin-config-ebadge.component.html',
  styleUrls: ['./vrgo-admin-config-ebadge.component.scss']
})
export class VrgoAdminConfigEbadgeComponent implements OnInit {
  @ViewChild(VRGoAdminModalComponent, { static: false }) vrgoAdminModal: VRGoAdminModalComponent;

  eventId : string;
  eventName : string;
  transactionType : string;
  configId : string;

  formConfig : FormGroup;
  ebadgeConfig : EbadgeConfig;
  imageCompleteNameUrl : string = 'assets/images/empty_background.jpg';
  imageIncompleteNameUrl : string = 'assets/images/empty_background.jpg';

  fileArrayUpload: Array<File> = [];
  fileToUpload : File = null;
  fileMessage : string;

  submited : boolean = false;

  constructor(
    private route: ActivatedRoute,
    private httpClient : HttpClientService,
    private router : Router,
    public _d: DomSanitizer,
  ) { }

  get ebadgeForm() { return this.formConfig.controls; }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.eventId = routeParams.eventId;
    });

    this.route.data.subscribe(data =>{
      this.transactionType  = data.transactionType.toString().toUpperCase();
    });

    this.initialForm();
    this.bindingDataToForm();
  }

  initialForm(){
    this.formConfig = new FormGroup({
      configId : new FormControl('0'),
      templateImgComplete : new  FormControl('', Validators.required),
      templateImgInComplete : new  FormControl('', Validators.required),
      status : new FormControl("Active", Validators.required)
    });
  }

  bindingDataToForm(){
      let params = new FormData();
      params.append('eventId',String(this.eventId));
      this.httpClient.getConfigEbadge(params).subscribe(response => {
        if(response != null){
          this.ebadgeConfig = new EbadgeConfig();
          this.ebadgeConfig = response;
          this.eventName = this.ebadgeConfig.eventName;
          
          if(this.transactionType == 'EDIT'){
            this.formConfig.controls['configId'].setValue(this.ebadgeConfig.configId);
            this.formConfig.controls['templateImgComplete'].setValue(this.ebadgeConfig.imageCompleteName);
            this.formConfig.controls['templateImgInComplete'].setValue(this.ebadgeConfig.imageIncompleteName);
            this.formConfig.controls['status'].setValue(this.ebadgeConfig.status);
            this.imageCompleteNameUrl = this.ebadgeConfig.imageCompleteNameUrl;
            this.imageIncompleteNameUrl = this.ebadgeConfig.imageIncompleteNameUrl;
          }
        }
      });
  }

  getFormData(){

    this.ebadgeConfig = new EbadgeConfig();
    this.ebadgeConfig.configId = this.formConfig.value['configId'];
    this.ebadgeConfig.imageCompleteName = this.formConfig.value['templateImgComplete'];
    this.ebadgeConfig.imageIncompleteName = this.formConfig.value['templateImgInComplete'];
    this.ebadgeConfig.status = this.formConfig.value['status'];

  }

  handleFileInputComplete(files: FileList) {
    this.fileToUpload = files.item(0);
    if(this.fileToUpload.type == "image/png"){
      this.imageCompleteNameUrl = window.URL.createObjectURL(this.fileToUpload);
      this.ebadgeForm['templateImgComplete'].setValue(this.fileToUpload.name);
      this.fileArrayUpload.push(this.fileToUpload);
    }else{
      this.fileMessage = 'ชนิดของไฟล์รูปไม่ถูกต้อง ชนิดไฟล์รูปต้องเป็น PNG';
    }
  }

  handleFileInputInComplete(files: FileList) {
    this.fileToUpload = files.item(0);
      if(this.fileToUpload.type == "image/png"){
        this.imageIncompleteNameUrl = window.URL.createObjectURL(this.fileToUpload);
        this.ebadgeForm['templateImgInComplete'].setValue(this.fileToUpload.name);
        this.fileArrayUpload.push(this.fileToUpload);
      }else{
        this.fileMessage = 'ชนิดของไฟล์รูปไม่ถูกต้อง ชนิดไฟล์รูปต้องเป็น PNG';
      }
  }

  submit(){
    this.submited = true;
    if(this.formConfig.status === 'VALID'){
      
      let mainForm: FormData = new FormData();

      for (let j = 0; j < this.fileArrayUpload.length; j++) {
        mainForm.append(this.fileArrayUpload[j].name, <File>this.fileArrayUpload[j]);
      }

      this.getFormData();
      this.ebadgeConfig.eventId = this.eventId;
      mainForm.append("ebadgeConfig", JSON.stringify(this.ebadgeConfig));

      console.log('ebadgeConfig : '+JSON.stringify(this.ebadgeConfig));

      this.httpClient.saveConfigEbadge(mainForm).subscribe(response => {
        console.log(response);
        if(response != null){
          const messageError = new MessageModal;
          messageError.title = 'แจ้งเตือน';
          messageError.description = 'บันทึก Config Ebadge สำเร็จ';
          this.vrgoAdminModal.openMessageModal(messageError);
        }
      });

    }
  }

  clear(){
    this.initialForm();
    this.bindingDataToForm();
  }

  afterCallModal(event){
    this.router.navigate(['vrgoAdmin/ebadge/edit/' + this.eventId]);
  }
}
