import { Component, OnInit, ViewChild, ElementRef, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ParameterUrl } from '../../shared/class/ParameterUrl';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { EventModel } from '../../shared/models/event.model';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MessageModal } from 'src/app/shared/class/MessageModal';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { EventImageModel } from '../../shared/models/event-image.model';
import { EventSectionModel } from '../../shared/models/event-section.model'
import { NgModule } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { TokenStorageService } from 'src/app/shared/authentication/token-storage.service';
import { EventTagModel } from '../../shared/models/event-tag.model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as _ from 'lodash';
import { CouponModel } from '../../shared/models/coupon.model';
import { CouponTicketModel } from 'src/app/shared/models/coupon-ticket.model';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : '';
  }
}
@Component({
  selector: 'app-vrgo-admin-event-detail',
  templateUrl: './vrgo-admin-event-detail.component.html',
  styleUrls: ['./vrgo-admin-event-detail.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})


export class VrgoAdminEventDetailComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTag: Observable<string[]>;
  eventTagName: string[] = ['Popular'];
  allTag: string[] = ['Popular', 'Comming Soon', 'Free', 'Combo', 'Post Event', 'New'];

  @ViewChild('TagInput', { static: false }) TagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('warnningValidate', { static: false }) warnningValidate;
  @ViewChild('saveValid', { static: false }) saveValid;
  @ViewChild('saveValidCoupon', { static: false }) saveValidCoupon;
  @ViewChild('warnningValidateAdd', { static: false }) warnningValidateAdd;
  @ViewChild('editCouponModal', { static: false }) editCouponModal;

  placement = 'top';

  minDate: Date;
  maxDate: Date;
  submitted: boolean = false;
  openDetail: any = 'NO';
  eventName: string = '';
  eventNameClass: string = 'form-control';
  eventNameErrorMsg: string = '';


  eventDetail: string = '';
  eventDetailClass: string = 'form-control';
  eventDetailErrorMsg: string = '';

  startDate: string = '';
  startDateClass: string = 'form-control';
  startDateClassErrorMsg: string = '';

  endDate: string = '';
  endDateClass: string = 'form-control';
  endDateClassErrorMsg: string = '';

  eventLocation: string = '';
  eventLocationClass: string = 'form-control';
  eventLocationErrorMsg: string = '';

  urlPath: string = '';
  urlPathClass: string = 'form-control';
  urlPathErrorMsg: string = '';

  eventDate: string = '';
  eventDateClass: string = 'form-control';
  eventDateErrorMsg: string = '';

  status: string = '';
  statusClass: string = 'form-control';
  statusErrorMsg: string = '';

  shippingStatus: string = '';
  shippingStatusClass: string = 'form-control';
  shippingStatusErrorMsg: string = '';

  registerStatus: string = '';
  registerStatusClass: string = 'form-control';
  registerStatusErrorMsg: string = '';

  eventTypeClass: string = 'form-control';
  eventTypeErrorMsg: string = '';

  eventNameEngClass: string = 'form-control';
  eventNameEngErrorMsg: string = '';

  eventLocationEnClass: string = 'form-control';
  eventLocationEnErrorMsg: string = '';

  eventCategoryClass: string = 'form-control';
  eventCategoryErrorMsg: string = '';

  couponStatusClass: string = 'form-control';
  couponStatusErrorMsg: string = '';

  seq: string = '';
  seqClass: string = 'form-control';
  seqErrorMsg: string = '';

  listEvent: EventModel;

  formEvent: FormGroup;
  sectionItems: FormGroup;
  imgEvent: FormGroup;
  listTicketForm: FormGroup;
  listEventTag: FormGroup;
  listCouponForm: FormGroup;

  listEventImage: any[] = [];
  listSection: any = [];
  listregisterStatus: any;
  listEventStatus: any;
  listshippingStatus: any;
  listImgEvent: any;
  listStatusImg: any;
  listEventType: any;

  eventDatePipe: any;
  eventStart: any;
  eventEnd: any;
  paramStatus: any = 'A';
  imgbanner: any;
  homeImage: any;
  eventItem: any = [];
  eventHome: any = [];
  eventBanner: any = [];
  seqEventItem: any;
  seqEventHome: any;
  seqEventBanner: any;
  eventId: EventModel;
  listTicket: any[] = [];
  listTicketByCoupon: CouponTicketModel[] = [];

  fileToUpload: File = null;
  imgProfile: string = null;
  flagSave: boolean = false;
  sizeFile: number = 1048576;
  imgsrc: string = '';

  fileData: File = null;
  previewUrl: any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  lengthCheckToaddMore = 0;
  deleteDocumentFile: Array<Object> = [];
  uploadFlag: boolean = false;
  totalfiles: Array<File> = [];
  totalFileName = [];
  eventTag: any;
  isDisabled = true;
  msgError: any = '';
  indexSeq: any = undefined;
  mode: any
  sectionId: any;
  name: any;
  search: any;
  editor1: any;
  replace: any;
  formBasic: FormGroup;
  nameHtml: any;
  eventImageBanner = [];
  isNull: number = 0;
  msgDescription: any;
  listEventCategory: any;
  listCouponType: any;
  listCouponMode: any;
  maxRegisStatus: boolean = false;
  maxRegisMessage: string = '';
  maxQty: boolean = false;
  maxQtyMessage: string = '';
  isDisabledSuffix: boolean = false;
  isDisabledCouponMode: boolean = false;
  listCoupon: CouponModel[] = [];
  listGenerateType: any;
  listUnitRate: any;
  couponTypeOnCheck: any;
  submitCouponForm: boolean = false;
  saveMode: string = '';
  maxSuffixLength: boolean;
  maxSuffixLengthMsg: string;
  discountRateMax: boolean;
  discountRateMaxMsg: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private param: ParameterUrl,
    private service: HttpClientService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private tokenStored: TokenStorageService,
    private http: HttpClient,
  ) {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.filteredTag = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this._filter(tag) : this.allTag.slice()));

    this.minDate.setDate(this.minDate.getDate() + 2);
    this.maxDate.setDate(this.maxDate.getDate() + 7);
  }

  ngOnInit() {
    this.modalService.dismissAll();
    this.tokenStored.removeEventIdSession();
    this.listEvent = new EventModel;
    this.eventItem = []
    this.eventHome = []
    this.eventBanner = []
    this.paramStatus = 'A';
    this.buildFormEvent();
    this.buildFormImageEvent();
    this.buildFormSection();
    this.buildFormTicket();
    this.buildFormEventTag();
    this.getregisterStatus();
    this.getshippingStatus();
    this.getEventImgStatus();
    this.getEventStatus();
    this.getEventsImgType();
    this.getEventType();
    this.getCouponType();
    this.getCouponMode();
    this.getGenerateType();
    this.getUnitRate();
    this.getEventCateGory();
    this.buildFormCoupon();
    const listEvent = new EventModel;
    this.activatedRoute.params.subscribe(routeParams => {
      this.eventId = routeParams.eventId;
      listEvent['eventId'] = this.eventId;
    });
    this.activatedRoute.data.subscribe(data => {
      this.mode = data.transactionType.toString();
      //  // console.log('data', this.mode.toUpperCase());
      if (this.mode.toUpperCase() === 'EDIT') {
        if (this.eventId != null && this.eventId != undefined) {
          this.service.getEventById(listEvent).subscribe(data => {
            this.listEvent = data;
            this.openDetail = 'OPEN';
            // console.log('OPEN  >>',this.listEvent);
            this.urlPath = data.urlPath;
            this.setFormvalue();
            // this.countSeqImg();
            this.imgbanner = this.listEvent.eventImage;
            this.homeImage = this.listEvent.homeImage;
          });
        }
      }
    });
    if (this.mode == 'ADD') {

    }
  }
  ///////////////////////////////////////////////////////////// build form //////////////////////////////////////////////////////////////////////////////////////////////
  get editForm() { return this.formEvent.controls; }
  buildFormEvent() {
    this.formEvent = this.formBuilder.group({
      eventName: new FormControl('', [Validators.required]),
      eventNameEng: new FormControl('', [Validators.required]),
      eventType: new FormControl('', [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      eventDetail: new FormControl('', [Validators.required]),
      eventLocation: new FormControl('', [Validators.required]),
      eventLocationEn: new FormControl('', [Validators.required]),
      urlPath: new FormControl('', [Validators.required]),
      eventDate: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
      shippingStatus: new FormControl('', [Validators.required]),
      registerStatus: new FormControl('', [Validators.required]),
      eventCategory: new FormControl('', [Validators.required]),
      seq: new FormControl('', [Validators.required]),
      couponStatus: new FormControl('', [Validators.required]),

    });
  }
  buildFormImageEvent() {
    this.imgEvent = this.formBuilder.group({
      eventId: new FormControl(''),
      eventImageId: new FormControl(''),
      fileId: new FormControl(''),
      fileName: new FormControl(''),
      status: new FormControl({ value: '', disabled: true }),
      imageType: new FormControl({ value: '', disabled: true }),
      isChecked: new FormControl({ value: '', disabled: true }, Validators.requiredTrue),
      imageSeq: new FormControl(''),
      imgItem: this.formBuilder.array([this.createUploadImgEvents()])

    });
  }
  createUploadImgEvents() {
    return this.formBuilder.group({
      eventId: [''],
      eventImageId: [''],
      fileId: [''],
      fileName: [''],
      status: [''],
      imageType: [''],
      isChecked: [''],
      imageSeq: [''],
      documentFile: File
    });
  }
  get imgItem(): FormArray {
    return this.imgEvent.get('imgItem') as FormArray;
  };

  buildFormTicket() {
    this.listTicketForm = this.formBuilder.group({
      ticketId: new FormControl(''),
      ticketName: new FormControl(''),
      priceId: new FormControl(''),
      price: new FormControl(''),
      status: new FormControl(''),
      ticketItem: this.formBuilder.array([this.createTicketItem()])
    });
  }
  createTicketItem() {
    return this.formBuilder.group({
      ticketId: [''],
      ticketName: [''],
      priceId: [''],
      price: [''],
      status: [''],
    });
  }
  get ticketItem(): FormArray {
    return this.listTicketForm.get('ticketItem') as FormArray;
  };

  buildFormSection() {
    this.sectionItems = this.formBuilder.group({
      eventId: new FormControl(''),
      eventSectionId: new FormControl(''),
      detailHeader: new FormControl(''),
      mainHeader: new FormControl(''),
      sectionName: new FormControl(''),
      Items: this.formBuilder.array([this.createsectionItems()])

    });
  }
  createsectionItems() {
    return this.formBuilder.group({
      eventId: [''],
      eventSectionId: [''],
      detailHeader: [''],
      mainHeader: [''],
      sectionName: [''],
    });
  }
  get Items(): FormArray {
    return this.sectionItems.get('Items') as FormArray;
  };

  buildFormEventTag() {
    this.listEventTag = this.formBuilder.group({
      eventId: new FormControl(''),
      eventTagId: new FormControl(''),
      tagName: new FormControl(''),
      status: new FormControl(''),
      isChecked: new FormControl({ value: '', disabled: false }, Validators.requiredTrue),
      tagItem: this.formBuilder.array([this.createEventTag()])
    });
  }
  createEventTag() {
    return this.formBuilder.group({
      eventId: [''],
      eventTagId: [''],
      tagName: [''],
      status: [''],
      isChecked: [''],
    });
  }
  get tagItem(): FormArray {
    return this.listEventTag.get('tagItem') as FormArray;
  };
  /////////////////////////////////////////////////////////////////////////////// END //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////// START แก้ไข Section ////////////////////////////////////////////////////////////////////////////////////////
  addSection() {
    this.param.data = {};
    this.param.data.mode = "ADD";
    this.param.data.eventId = this.eventId;
    this.router.navigate(['vrgoAdmin/sectionDetail/add']);
  }
  editSection(index, eventSectionId) {
    // // console.log('',index ,eventSectionId)
    this.param.data = {};
    this.param.data.index = index;
    this.param.data.mode = "EDIT";
    this.router.navigate(['vrgoAdmin/sectionDetail/edit/' + eventSectionId]);
    // this.modalService.open(this.editterContent, { backdrop: 'static', windowClass: 'myCustomClass' })
  }
  deleteSection(index, SectionId) {
    console.log('SectionId', SectionId)
    if (SectionId != '') {
      let obj = {
        'SectionId': SectionId,
      }
      this.deleteDocumentFile.push(obj);
    }
    if (SectionId != 0) {
      this.service.deleteSection(SectionId).subscribe(data => {
        // this.mapDelData(data) 
        data
      })
    }
    this.Items.removeAt(index);
  }
  /////////////////////////////////////////////////////////////////////////////// END //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////// START แก้ไข  TICKET//////////////////////////////////////////////////////////////////////////////////
  editTicket(ticketId) {
    // // console.log('ticketId >>',ticketId);
    this.router.navigate(['vrgoAdmin/ticketDetail/edit/' + ticketId]);
  }
  addSTicket() {
    this.param.data = {};
    this.param.data.mode = "ADD";
    this.param.data.eventId = this.eventId;
    this.router.navigate(['vrgoAdmin/ticketDetail/add']);
  }
  deleteTicket() {

  }
  /////////////////////////////////////////////////////////////////////////////// END //////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////// UPLOAD รูปกิจกรมม ////////////////////////////////////////////////////////////////////////////////////////
  addItem(): void {
    var uploadValidate = true;
    for (let i = 0; this.imgEvent.controls.imgItem['controls'].length > i; i++) {
      if (this.imgEvent.controls.imgItem['controls'][i].status == "INVALID" ||
        (this.imgEvent.controls.imgItem['controls'][i].value.fileName == undefined ||
          this.imgEvent.controls.imgItem['controls'][i].value.fileName == "")) {
        var uploadValidate = false;
        break;
      }
    }
    if (uploadValidate) {
      this.imgItem.insert(this.imgItem.length + 1, this.createUploadImgEvents())
      this.lengthCheckToaddMore = this.lengthCheckToaddMore + 1;
    } else {
      this.msgError = 'กรุณากรอกข้อมูลให้ครบถ้วน';
      this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: "myCustomModalClass" });
    }
  }
  addItemTag(): void {
    var uploadValidate = true;
    for (let i = 0; this.listEventTag.controls.tagItem['controls'].length > i; i++) {
      if (this.listEventTag.controls.tagItem['controls'][i].status == "INVALID" ||
        this.listEventTag.controls.tagItem['controls'][i].value.tagName == undefined) {
        break;
      }
    }
    if (uploadValidate) {
      this.tagItem.insert(this.tagItem.length + 1, this.createEventTag())
      this.lengthCheckToaddMore = this.lengthCheckToaddMore + 1;
    } else {
      this.msgError = 'กรุณากรอกข้อมูลให้ครบถ้วน';
      this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: "myCustomModalClass" });
    }
  }
  deleteTag(index, eventTagId) {
    // console.log('eventTagId', eventTagId)
    if (eventTagId != '') {
      let obj = {
        'SectionId': eventTagId,
      }
      this.deleteDocumentFile.push(obj);
    }
    if (eventTagId != 0) {
      this.service.deleteEventTag(eventTagId).subscribe(data => {
        // this.mapDelData(data)
      })
    }
    this.tagItem.removeAt(index);
  }
  uploadValidate(index, item) {
    console.log('uploadValidate item >>', item);
    // console.log('form',item.value.imageType);
    // const eventImageBanner = []
    if (item.value.imageType == 'IMAGE_BANNER') {
      this.eventImageBanner.push(item.value);
    }
    for (let i = 0; i < this.listEventImage.length; i++) {
      if (this.listEventImage[i].imageType == 'IMAGE_BANNER') {
        this.eventImageBanner.push(this.listEventImage[i]);
      }
    }
    this.uploadFlag = true;
    // // console.log('Event >>', this.eventImageBanner)
    if (this.imgEvent.controls.imgItem['controls'][index].controls.imageType.value === "" || this.imgEvent.controls.imgItem['controls'][index].controls.status.value === "") {
      this.msgError = 'กรุณาระบุข้อมูลให้ครบ';
      this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: "myCustomModalClass" });
    } else if (this.imgEvent.controls.imgItem['controls'][index].controls.isChecked.value === false) {
      this.msgError = 'กรุณาเปิดการแก้ไข';
      this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: "myCustomModalClass" });
    } else if (this.eventImageBanner.length > 1 && item.value.imageType == 'IMAGE_BANNER' && item.value.eventId == "") {
      // // console.log('IMAGE_BANNER');
      this.msgError = 'ไม่สามารถเลือก ประเภทเป็น IMAGE_BANNER ได้มากกว่า 1 รูป';
      this.imgEvent.controls.imgItem['controls'][index].controls.imageType.setValue("");
      this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: "myCustomModalClass" });
    } else {
      document.getElementById(index).click();
    }
  }
  validimageBanner(event, i) {
    const eventImageBanner = []
    for (let i = 0; i < this.listEventImage.length; i++) {
      if (this.listEventImage[i].imageType == 'IMAGE_BANNER') {
        eventImageBanner.push(this.listEventImage[i]);
      }
    }
    if (eventImageBanner.length > 0 && event.target.value == 'IMAGE_BANNER') {
      this.imgEvent.controls.imgItem['controls'][i].controls.imageType.setValue("");
      this.msgError = 'ไม่สามารถเลือก ประเภทเป็น IMAGE_BANNER ได้มากกว่า 1 รูป';
      this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: "myCustomModalClass" });
    }

  }
  setFormValid() {

  }
  deleteDocFile(index, eventImageId, fileId) {
    this.eventImageBanner = [];
    const listEvent = new EventModel;
    listEvent['eventId'] = this.eventId;
    if (eventImageId != '' && fileId != '') {
      let obj = {
        'contentDetailImageId': eventImageId,
        'fileId': fileId
      }
      this.deleteDocumentFile.push(obj);
    }
    if (eventImageId != 0) {
      this.service.deleteEventImage(eventImageId).subscribe(data => {
        this.service.getEventById(listEvent).subscribe(data => {
          this.listEvent = data;
          this.openDetail = 'OPEN';
          this.urlPath = data.urlPath;
          this.eventId = data.eventId;
          this.setFormvalue();
          this.imgbanner = this.listEvent.eventImage;
          this.homeImage = this.listEvent.homeImage;
        });
      })
    }
    this.imgItem.removeAt(index);
  }

  fileSelectionEvent(fileInput: any, oldIndex: number) {
    // // console.log('oldIndex', oldIndex);
    // // console.log('fileInput', fileInput);
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      if (oldIndex == 0) {
        this.totalfiles.unshift((fileInput.target.files[0]))
        this.totalFileName.unshift(fileInput.target.files[0].name)
      }
      else {
        this.totalfiles[oldIndex] = (fileInput.target.files[0]);
        this.totalFileName[oldIndex] = fileInput.target.files[0].name
      }

      this.imgItem.value[oldIndex].fileName = fileInput.target.files[0].name;
      this.imgEvent.controls.imgItem['controls'][oldIndex].controls.fileName.value = fileInput.target.files[0].name;
      if (this.imgItem.value[oldIndex].imageSeq == '') {
        if (this.imgItem.value[oldIndex].imageType == 'EVENT_ITEM') {
          this.seqEventItem = this.seqEventItem + 1;
          this.imgItem.value[oldIndex].imageSeq = this.seqEventItem;
        }
        if (this.imgItem.value[oldIndex].imageType == 'IMAGE_BANNER') {
          this.seqEventBanner = this.seqEventBanner + 1;
          this.imgItem.value[oldIndex].imageSeq = this.seqEventBanner;
        }
        if (this.imgItem.value[oldIndex].imageType == 'HOME_BANNER') {
          this.seqEventHome = this.seqEventHome + 1;
          this.imgItem.value[oldIndex].imageSeq = this.seqEventHome;
        }
      }
      // // console.log('a', this.imgItem.value[oldIndex])
      reader.readAsDataURL(fileInput.target.files[0]);
    }

    if (this.totalfiles.length == 1) {
      this.lengthCheckToaddMore = 1;
    }

  }
  /////////////////////////////////////////////////////////////////////////////// END //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////// นับค่าของ Seq ของแต่ละประเภท แล้วเอาค่าสูงสุด //////////////////////////////////////////////////////////////////////////////////
  countSeqImg() {
    for (let i = 0; i < this.listEventImage.length; i++) {
      if (this.listEventImage[i].imageType == 'EVENT_ITEM') {
        this.eventItem.push(this.listEventImage[i].imageSeq)
      }
      if (this.listEventImage[i].imageType == 'IMAGE_BANNER') {
        this.eventBanner.push(this.listEventImage[i].imageSeq)
      }
      if (this.listEventImage[i].imageType == 'HOME_BANNER') {
        this.eventHome.push(this.listEventImage[i].imageSeq)
      }
    }
    if (this.eventItem.length == 0) {
      this.seqEventItem = 0;
    } else {
      this.seqEventItem = Math.max(...this.eventItem);
    }
    if (this.eventHome.length == 0) {
      this.seqEventHome = 0;
    } else {
      this.seqEventHome = Math.max(...this.eventHome); /*  ใข้ Math.max เพื่อหาค่าสูงสุด ใน(...) ต้องมี 3 จุดเสมอเพื่อบอกว่าเป็น arrey   */
    }
    if (this.eventBanner.length == 0) {
      this.seqEventBanner = 0;
    } else {
      this.seqEventBanner = Math.max(...this.eventBanner);
    }
    // // console.log('eventItem',this.seqEventItem)
    // // console.log('eventBanner',this.seqEventBanner)
    // // console.log('eventHome',this.seqEventHome)
  }
  /////////////////////////////////////////////////////////////////////////////// END //////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////// SET ค่าลงฟอร์ม /////////////////////////////////////////////////////////////////////////////////
  setFormvalue() {
    this.eventName = this.listEvent.eventName
    this.formEvent.controls['eventName'].setValue(this.listEvent.eventName);
    this.formEvent.controls['eventNameEng'].setValue(this.listEvent.eventNameEng);
    this.formEvent.controls['startDate'].setValue(this.listEvent.eventRegisStartDate);
    this.formEvent.controls['eventType'].setValue(this.listEvent.eventType);
    this.formEvent.controls['endDate'].setValue(this.listEvent.eventRegisEndDate);
    this.formEvent.controls['eventDetail'].setValue(this.listEvent.eventDetail);
    this.formEvent.controls['eventLocation'].setValue(this.listEvent.eventLocation);
    this.formEvent.controls['eventLocationEn'].setValue(this.listEvent.eventLocationEn);
    this.formEvent.controls['urlPath'].setValue(this.listEvent.urlPath);
    this.formEvent.controls['eventDate'].setValue(this.listEvent.eventDate);
    this.formEvent.controls['status'].setValue(this.listEvent.status);
    this.formEvent.controls['shippingStatus'].setValue(this.listEvent.shippingStatus);
    this.formEvent.controls['registerStatus'].setValue(this.listEvent.registerStatus);
    this.formEvent.controls['seq'].setValue(this.listEvent.seq);
    this.formEvent.controls['eventCategory'].setValue(this.listEvent.eventCategory);
    this.formEvent.controls['couponStatus'].setValue(this.listEvent.couponStatus);
    this.listEventImage = this.listEvent.listEventImage
    this.listTicket = this.listEvent.listTicket
    this.listTicketByCoupon = this.listTicket;
    // // console.log('listTicket',this.listTicket)
    // // console.log('listTicket',this.listEvent.listTicket)
    // this.getSectionById();

    // // console.log('this.listEventImage', this.listEventImage);
    if (this.listEventImage.length > 0) {
      // // console.log('istEventImage', this.listEventImage)
      let indexImg = 0;
      this.imgItem.clear();
      this.imgItem.removeAt(indexImg = 0);
      for (let i = 0; i < this.listEventImage.length; i++) {
        this.imgItem.insert(indexImg, this.formBuilder.group({
          eventId: this.listEventImage[i].eventId,
          eventImageId: this.listEventImage[i].eventImageId,
          fileId: this.listEventImage[i].fileId,
          fileName: this.listEventImage[i].imageName,
          status: this.listEventImage[i].status,
          imageType: this.listEventImage[i].imageType,
          imageSeq: this.listEventImage[i].imageSeq,
          isChecked: true,

        }));
        indexImg = indexImg + 1;
      }
    } else {
      this.buildFormImageEvent();
    }

    if (this.listTicket.length > 0) {
      let indexIicket = 0;
      this.ticketItem.clear();
      this.ticketItem.removeAt(indexIicket = 0);
      for (let i = 0; i < this.listTicket.length; i++) {
        this.ticketItem.insert(indexIicket, this.formBuilder.group({
          ticketId: this.listTicket[i].ticketId,
          ticketName: this.listTicket[i].ticketName,
          priceId: this.listTicket[i].priceId,
          price: this.listTicket[i].price,
          status: this.listTicket[i].status,
        }));
        indexIicket = indexIicket + 1;
      }
    } else {
      this.buildFormTicket();
      this.ticketItem.removeAt(0);
    }

    this.getSectionById();
    this.getEventTagById();
    this.listEventCoupon();
  }
  getEventTagById() {
    var getEventTag = new EventTagModel;
    getEventTag['eventId'] = this.eventId
    this.service.getEventTagById(getEventTag).subscribe((item) => {
      // // console.log('Tag',item)
      this.eventTag = item;
      console.log('eventTag >>', this.eventTag);
      if (this.eventTag.length > 0) {
        let indexIag = 0;
        this.tagItem.clear();
        this.tagItem.removeAt(indexIag = 0);
        for (let i = 0; i < this.eventTag.length; i++) {
          this.tagItem.insert(indexIag, this.formBuilder.group({
            eventId: this.eventTag[i].eventId,
            eventTagId: this.eventTag[i].eventTagId,
            tagName: this.eventTag[i].tagName,
            status: this.eventTag[i].status,
            isChecked: true,
          }));
          indexIag = indexIag + 1;
        }
      } else {
        this.buildFormEventTag();
        this.tagItem.removeAt(0);
      }
    });
  }
  getSectionById() {
    this.service.getSectionById(this.eventId).subscribe((item) => {
      this.listSection = item
      // // console.log('listSection',this.listSection)
      if (this.listSection.length > 0) {
        let indexSection = 0;
        this.Items.clear();
        this.Items.removeAt(indexSection = 0);
        for (let i = 0; i < this.listSection.length; i++) {
          this.Items.insert(indexSection, this.formBuilder.group({
            eventId: this.listSection[i].eventId,
            eventSectionId: this.listSection[i].eventSectionId,
            detailHeader: this.listSection[i].detailHeader,
            mainHeader: this.listSection[i].mainHeader,
            sectionName: this.listSection[i].sectionName,

          }));
          indexSection = indexSection + 1;
          this.sectionItems.disable();
        }
      } else {
        this.buildFormSection();
        this.Items.removeAt(0);
      }
    })
  }
  /////////////////////////////////////////////////////////////////////////////// END //////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////// GET DATA///////////////////////////////////////////////////////////////////////////// 
  getEventType() {
    this.service.getListBoxByGroup('EVENT_TYPE').subscribe((items) => {
      this.listEventType = items;
      // // console.log('getEventType',this.listEventType);
    });
  }
  getregisterStatus() {
    this.service.getListBoxByGroup('EVENT_REGIS_STATUS').subscribe((items) => {
      this.listregisterStatus = items;
    });
  }
  getEventImgStatus() {
    this.service.getListBoxByGroup('EVENT_IMG_STATUS').subscribe((items) => {
      this.listStatusImg = items;
      // // console.log('this.listStatusImg',this.listStatusImg)
    });
  }
  getEventsImgType() {
    this.service.getListBoxByGroup('EVENT_IMG_TYPE').subscribe((items) => {
      this.listImgEvent = items;
    });
  }
  getEventStatus() {
    this.service.getListBoxByGroup('EVENT_STATUS').subscribe((items) => {
      this.listEventStatus = items;
    });
  }
  getshippingStatus() {
    this.service.getListBoxByGroup('SHIPPING_STATUS').subscribe((items) => {
      this.listshippingStatus = items;
    });
  }
  getCouponType() {
    this.service.getListBoxByGroup('COUPON_TYPE').subscribe((items) => {
      this.listCouponType = items;
      // // console.log('getEventType',this.listEventType);
    });
  }
  getGenerateType() {
    this.service.getListBoxByGroup('COUPON_GENERATE_CODE').subscribe((items) => {
      this.listGenerateType = items;
      // // console.log('getEventType',this.listEventType);
    });
  }
  getUnitRate() {
    this.service.getListBoxByGroup('UNIT_RATE').subscribe((items) => {
      this.listUnitRate = items;
      // // console.log('getEventType',this.listEventType);
    });
  }
  getCouponMode() {
    this.service.getListBoxByGroup('COUPON_MODE').subscribe((items) => {
      this.listCouponMode = items;
      // // console.log('getEventType',this.listEventType);
    });
  }

  editImgEvent(index, eventImageId) {
    // // console.log('log',eventImageId)
    if (this.imgEvent.controls.imgItem['controls'][index].controls.isChecked.value === true && eventImageId === '') {
      this.imgEvent.controls.imgItem['controls'][index].controls.imageType.enable();
      this.imgEvent.controls.imgItem['controls'][index].controls.status.enable();
      //  this.isDisabled = false;
      // // console.log('enable')
    } else if (this.imgEvent.controls.imgItem['controls'][index].controls.isChecked.value === true && eventImageId !== '') {
      // this.imgEvent.controls.imgItem['controls'][index].controls.imageType.enable();
      this.imgEvent.controls.imgItem['controls'][index].controls.status.enable();
      //  this.isDisabled = false;
      // // console.log('enable')
    } else {
      this.imgEvent.controls.imgItem['controls'][index].controls.imageType.disable();
      this.imgEvent.controls.imgItem['controls'][index].controls.status.disable();
      // this.isDisabled = true;
    }

  }
  editTagEvent(index, event) {
    // // console.log('log',this.imgEvent.controls.imgItem['controls'][index].controls.isChecked)
    if (this.listEventTag.controls.tagItem['controls'][index].controls.isChecked.value === true) {
      this.listEventTag.controls.tagItem['controls'][index].controls.tagName.enable();
      this.listEventTag.controls.tagItem['controls'][index].controls.status.enable();
      //  this.isDisabled = false;
      // // console.log('enable')
    } else {
      this.listEventTag.controls.tagItem['controls'][index].controls.tagName.disable();
      this.listEventTag.controls.tagItem['controls'][index].controls.status.disable();
      // this.isDisabled = true;
    }

  }
  //////////////////////////////////////////////////////////////////////////////// END /////////////////////////////////////////////////////////////////////////////
  test() {

  }
  //////////////////////////////////////////////////////////////////////////////// SAVE /////////////////////////////////////////////////////////////////////////////
  validSubmit() {
    this.submitted = true;
    let isNull = 0;
    if (this.formEvent.controls.eventName.value === '') {
      this.eventNameClass = 'form-control input-genstar-err';
      this.eventNameErrorMsg = 'กรุณา ระบุชื่อกิจกรรม';
      isNull = isNull + 1;
    } else {
      this.eventNameClass = 'form-control';
      this.eventNameErrorMsg = ''
    }
    if (this.formEvent.controls.eventNameEng.value === '') {
      this.eventNameEngClass = 'form-control input-genstar-err';
      this.eventNameEngErrorMsg = 'กรุณา ระบุชื่อกิจกรรม';
      isNull = isNull + 1;
    } else {
      this.eventNameEngClass = 'form-control';
      this.eventNameEngErrorMsg = ''
    }
    if (this.formEvent.controls.eventDetail.value === '') {
      this.eventDetailClass = 'form-control input-genstar-err';
      this.eventDetailErrorMsg = 'กรุณา ระบุรายละเอียดของกิจกรรม';
      isNull = isNull + 1;
    } else {
      this.eventDetailClass = 'form-control';
      this.eventDetailErrorMsg = ''
    }
    if (this.formEvent.controls.eventLocation.value === '') {
      this.eventLocationClass = 'form-control input-genstar-err';
      this.eventLocationErrorMsg = 'กรุณา ระบุสถานที่วิ่ง';
      isNull = isNull + 1;
    } else {
      this.eventLocationClass = 'form-control';
      this.eventLocationErrorMsg = ''
    }
    if (this.formEvent.controls.eventLocationEn.value === '') {
      this.eventLocationEnClass = 'form-control input-genstar-err';
      this.eventLocationEnErrorMsg = 'กรุณา ระบุสถานที่วิ่ง (อังกฤษ)';
      isNull = isNull + 1;
    } else {
      this.eventLocationEnClass = 'form-control';
      this.eventLocationEnErrorMsg = ''
    }
    if (this.formEvent.controls.urlPath.value === '') {
      this.urlPathClass = 'form-control input-genstar-err';
      this.urlPathErrorMsg = 'กรุณา ลิงค์กิจกรรม';
      isNull = isNull + 1;
    } else {
      this.urlPathClass = 'form-control';
      this.urlPathErrorMsg = ''
    }
    if (this.formEvent.controls.status.value === '') {
      this.statusClass = 'form-control input-genstar-err';
      this.statusErrorMsg = 'กรุณา ระบุสถานะของกิจกรรม';
      isNull = isNull + 1;
    } else {
      this.statusClass = 'form-control';
      this.statusErrorMsg = ''
    }
    if (this.formEvent.controls.shippingStatus.value === '') {
      this.shippingStatusClass = 'form-control input-genstar-err';
      this.shippingStatusErrorMsg = 'กรุณา ระบุสถานะการจัดส่ง';
      isNull = isNull + 1;
    } else {
      this.shippingStatusClass = 'form-control';
      this.shippingStatusErrorMsg = ''
    }
    if (this.formEvent.controls.eventType.value === '') {
      this.eventTypeClass = 'form-control input-genstar-err';
      this.eventTypeErrorMsg = 'กรุณา ประเภทของกิจกรรม';
      isNull = isNull + 1;
    } else {
      this.eventTypeClass = 'form-control';
      this.eventTypeErrorMsg = ''
    }
    if (this.formEvent.controls.registerStatus.value === '') {
      this.registerStatusClass = 'form-control input-genstar-err';
      this.registerStatusErrorMsg = 'กรุณา ระบุสถานะการสมัคร';
      isNull = isNull + 1;
    } else {
      this.registerStatusClass = 'form-control';
      this.registerStatusErrorMsg = ''
    }
    if (this.formEvent.controls.seq.value === '') {
      this.seqClass = 'form-control input-genstar-err';
      this.seqErrorMsg = 'กรุณา ระบุลำดับของกิจกรรม';
      isNull = isNull + 1;
    } else {
      this.seqClass = 'form-control';
      this.seqErrorMsg = ''
    }
    if (this.formEvent.controls.startDate.value === '' || this.formEvent.controls.startDate.value === null) {
      this.startDateClass = 'form-control input-genstar-err';
      this.startDateClassErrorMsg = 'กรุณา ระบุวัน/เดือน/ปี ที่เริ่มจำหน่าย';
      isNull = isNull + 1;
    } else {
      this.startDateClass = 'form-control';
      this.startDateClassErrorMsg = ''
    }
    if (this.formEvent.controls.endDate.value === '' || this.formEvent.controls.endDate.value === null) {
      this.endDateClass = 'form-control input-genstar-err';
      this.endDateClassErrorMsg = 'กรุณา ระบุวัน/เดือน/ปี ที่เริ่มสิ้นสุดการจำหน่าย';
      isNull = isNull + 1;
    } else {
      this.endDateClass = 'form-control';
      this.endDateClassErrorMsg = ''
    }
    if (this.formEvent.controls.eventDate.value === '' || this.formEvent.controls.eventDate.value === null) {
      this.eventDateClass = 'form-control input-genstar-err';
      this.eventDateErrorMsg = 'กรุณา ระบุวัน/เดือน/ปี ที่เริ่มกิจกรรม';
      isNull = isNull + 1;
    } else {
      this.eventDateClass = 'form-control';
      this.eventDateErrorMsg = ''
    }
    if (this.formEvent.controls.eventCategory.value === '') {
      this.eventCategoryClass = 'form-control input-genstar-err';
      this.eventCategoryErrorMsg = 'กรุณา ระบุลักษณะของกิจกรรม';
      isNull = isNull + 1;
    } else {
      this.eventCategoryClass = 'form-control';
      this.eventCategoryErrorMsg = ''
    }
    if (this.formEvent.controls.couponStatus.value === '') {
      this.couponStatusClass = 'form-control input-genstar-err';
      this.couponStatusErrorMsg = 'กรุณา ระบุสถานะคูปอง';
      isNull = isNull + 1;
    } else {
      this.couponStatusClass = 'form-control';
      this.couponStatusErrorMsg = ''
    }
    console.log('isNull >>', isNull);
    if (isNull == 0) {
      this.msgDescription = 'ยืนยันการแก้ไข';
      this.modalService.open(this.saveValid, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    }
  }

  submit() {
    // // console.log('isNull', isNull);
    const listEvent = new EventModel;
    let imageWidth = '';
    let imageHeigth = '';
    let AllFilesObj = [];
    // let eventItem = []
    // let eventHome = []
    // let eventbanner = []
    // let seqArrey 
    this.imgEvent.value.imgItem.forEach((element, index) => {
      if (element.imageType == 'HOME_BANNER') {
        imageWidth = '1920';
        imageHeigth = '700';
      } else {
        imageWidth = '800';
        imageHeigth = '533';
      }
      let eachObj =
      {
        'eventId': (this.eventId || 0),
        'eventImageId': element.eventImageId || 0,
        'fileId': element.fileId,
        'imageName': element.fileName,
        'status': element.status,
        'imageType': element.imageType,
        'imageWidth': imageWidth,
        'imageHeigh': imageHeigth,
        'imageSeq': element.imageSeq,
      }
      AllFilesObj.push(eachObj);
    });
    let main_form: FormData = new FormData();
    for (let j = 0; j < this.totalfiles.length; j++) {
      main_form.append(this.totalFileName[j], <File>this.totalfiles[j]);
      console.log('totalFileName', <File>this.totalfiles[j])
    }
    listEvent['eventId'] = this.eventId;
    listEvent['orgId'] = 1;
    listEvent['eventName'] = this.formEvent.controls.eventName.value;
    listEvent['eventNameEng'] = this.formEvent.controls.eventNameEng.value;
    listEvent['eventType'] = this.formEvent.controls.eventType.value;
    listEvent['eventDetail'] = this.formEvent.controls.eventDetail.value;
    listEvent['eventLocation'] = this.formEvent.controls.eventLocation.value;
    listEvent['eventLocationEn'] = this.formEvent.controls.eventLocationEn.value;
    listEvent['urlPath'] = this.formEvent.controls.urlPath.value;
    listEvent['status'] = this.formEvent.controls.status.value;
    listEvent['shippingStatus'] = this.formEvent.controls.shippingStatus.value;
    listEvent['registerStatus'] = this.formEvent.controls.registerStatus.value;
    listEvent['seq'] = this.formEvent.controls.seq.value;
    listEvent['eventRegisStartDate'] = this.formEvent.controls.startDate.value;
    listEvent['eventRegisEndDate'] = this.formEvent.controls.endDate.value;
    listEvent['eventDate'] = this.formEvent.controls.eventDate.value;
    listEvent['eventCategory'] = this.formEvent.controls.eventCategory.value;
    listEvent['couponStatus'] = this.formEvent.controls.couponStatus.value;
    listEvent['shippingFee'] = 0;
    listEvent['additionalShippingFee'] = 0;
    listEvent['createBy'] = 'ADMIN';
    for (let j = 0; j < AllFilesObj.length; j++) {

      // console.log('listEvent', listEvent);
      if (AllFilesObj[j].imageName !== undefined && AllFilesObj[j].imageName !== '') {
        listEvent['listEventImage'] = AllFilesObj;
      } else {
        listEvent['listEventImage'] = [];
      }
    }
    if (this.mode == 'ADD') {
      listEvent['eventId'] = 0;
      // // console.log('DATA >>', listEvent);
      this.service.addEvent(listEvent).subscribe(data => {
        this.mapAddEventResponse(data)
      });
      this.service.saveImageEvent(main_form).subscribe(data => {
        console.log(data);
      });
    } else if (this.mode == 'EDIT') {
      // // console.log('EDIT');
      // // console.log('DATA >>', listEvent);
      this.service.saveEditEvent(listEvent).subscribe(data => {
        this.mapEditEventResponse(data)
        // this.router.navigate(['vrgoAdmin/SearchEvent'])
      });
      this.service.saveImageEvent(main_form).subscribe(data =>{
        console.log(data);
      })
    }


    // // console.log('listEvent',listEvent);

  }
  mapEditEventResponse(data) {
    // // console.log('UPDATE >>',data);
    this.modalService.dismissAll();
    const listEvent = new EventModel;
    // let allEventTag = [];
    listEvent['eventId'] = this.eventId;
    this.service.getEventById(listEvent).subscribe(data => {
      this.listEvent = data
      // // console.log('data', this.listEvent);
      this.setFormvalue();
      // this.countSeqImg();
      this.imgbanner = this.listEvent.eventImage;
      this.homeImage = this.listEvent.homeImage;
    });
    this.service.editEventTag(this.eventTag).subscribe(data => {
      this.mapDataTag(data);
    });
  }
  mapDataTag(data) {
    this.msgError = 'ระบบทำรายการเรียบร้อย';
    this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    // console.log('Tag',data)
  }
  mapAddEventResponse(data) {
    this.modalService.dismissAll();
    // console.log('UPDATE >>', data);
    this.openDetail = 'OPEN';
    this.urlPath = data.urlPath;
    const listEvent = new EventModel;
    listEvent['eventId'] = data.eventId;
    this.eventId = data.eventId;
    this.msgError = 'ระบบทำรายการเรียบร้อย';
    this.modalService.open(this.warnningValidateAdd, { backdrop: 'static', windowClass: 'myCustomModalClass' });
  }

  goToEdit() {
    this.modalService.dismissAll();
    this.router.navigate(['vrgoAdmin/eventDetail/edit/' + this.eventId]);
  }

  //////////////////////////////////////////////////////////////////////////////// END /////////////////////////////////////////////////////////////////////////////
  goBack() {
    this.router.navigate(['vrgoAdmin/SearchEvent']);
  }
  clearFile() {
    this.fileToUpload = null;
  }
  clickOnPevicew() {
    const url = 'https://checkrace.com/eventDetailPreview?name=' + this.urlPath;
    // const url = 'http://103.86.48.68:8100/eventDetailPreview/?name='+this.urlPath;
    window.open(url, '_blank');
  }
  onChange(event, type) {
    const key = event.key;
    if (type === 'NUMBER') {
      if (!key.match('^-?(0|[1-9]\d*)?$')) {
        event.preventDefault();
      }
    } else if (type === 'TEXT') {
      if (!key.match('^[a-zA-Z]')) {
        event.preventDefault();
      }
    } else if (type === 'ALL') {
      if (!key.match('^[A-Za-z0-9_.]+$')) {
        event.preventDefault();
      }
    }
  }
  getEventCateGory() {
    this.service.getListBoxByGroup('EVENT_CATEGORY').subscribe((items) => {
      this.listEventCategory = items;
      // // console.log('getEventType',this.listEventType);
    });
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const listEventTag = new EventTagModel
    listEventTag['tagName'] = event.value;
    listEventTag['eventId'] = this.eventId;
    if (event.value == 'Popular' || event.value == 'Comming Soon' || event.value == 'Free' || event.value == 'Combo' || event.value == 'Post Event' || event.value == 'New') {
      listEventTag['status'] = 'INACTIVE';
    } else {
      listEventTag['status'] = 'ACTIVE';
    }
    listEventTag['eventTagId'] = 0;
    // Add our fruit
    if (listEventTag['tagName'] != '') {
      this.eventTag.push(listEventTag);
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  remove(Tag: string): void {
    const index = this.eventTag.indexOf(Tag);

    if (index >= 0) {
      this.eventTag.splice(index, 1);
    }
    if (Tag['eventTagId'] != 0) {
      var eventTagId = Tag['eventTagId'];
      console.log('Tag >>', eventTagId);
      this.service.deleteEventTag(eventTagId).subscribe(data => {
        // data
      });
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log('viewValue >>', event.option.viewValue)
    const listEventTag = new EventTagModel
    listEventTag['tagName'] = event.option.viewValue;
    listEventTag['eventId'] = this.eventId;
    if (event.option.viewValue == 'Popular' || event.option.viewValue == 'Comming Soon' || event.option.viewValue == 'Free' || event.option.viewValue == 'Combo' || event.option.viewValue == 'Post Event') {
      listEventTag['status'] = 'INACTIVE';
    } else {
      listEventTag['status'] = 'ACTIVE';
    }
    listEventTag['eventTagId'] = 0;
    this.eventTag.push(listEventTag);
    this.TagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTag.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }

  listEventCoupon() {
    this.service.listEventCoupon(this.eventId).subscribe(data => {
      this.listCoupon = data;
      if (this.listCoupon != undefined && this.listCoupon != null && this.listCoupon.length > 0) {
        for (let i = 0; i < this.listCoupon.length; i++) {
          if (this.listCouponType != undefined && this.listCouponType != null && this.listCouponType.length > 0) {
            for (let j = 0; j < this.listCouponType.length; j++) {
              if (this.listCoupon[i].couponType == this.listCouponType[j].value1) {
                this.listCoupon[i].couponTypeDesc = this.listCouponType[j].name;
              }
            }
          }
          if (this.listCouponMode != undefined && this.listCouponMode != null && this.listCouponMode.length > 0) {
            for (let o = 0; o < this.listCouponMode.length; o++) {
              if (this.listCoupon[i].couponMode == this.listCouponMode[o].value1) {
                this.listCoupon[i].couponModeDesc = this.listCouponMode[o].name;
              }
            }
          }
          if (this.listUnitRate != undefined && this.listUnitRate != null && this.listUnitRate.length > 0) {
            for (let l = 0; l < this.listUnitRate.length; l++) {
              if (this.listCoupon[i].unitRate == this.listUnitRate[l].value1) {
                this.listCoupon[i].unitRateDesc = this.listUnitRate[l].name;
              }
            }
          }
        }
      }
      this.mapDataCoupon();
    });
  }

  buildFormCoupon() {
    this.listCouponForm = this.formBuilder.group({
      couponId: new FormControl(''),
      eventId: new FormControl(''),
      ticketId: new FormControl(''),
      couponType: new FormControl('', Validators.required),
      couponTypeDesc: new FormControl(''),
      couponMode: new FormControl('', Validators.required),
      couponModeDesc: new FormControl(''),
      unitRateDesc: new FormControl(''),
      prefix: new FormControl('', Validators.required),
      suffix: new FormControl('', Validators.required),
      discountRate: new FormControl('', Validators.required),
      unitRate: new FormControl('', Validators.required),
      qty: new FormControl('', Validators.required),
      maxRegister: new FormControl('', Validators.required),
      startDate: new FormControl('', Validators.required),
      expireDate: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required),
      descTh: new FormControl(''),
      descEn: new FormControl(''),
      createDate: new FormControl(''),
      createBy: new FormControl(''),
      updateDate: new FormControl(''),
      updateBy: new FormControl(''),
      couponCode: new FormControl(''),
      suffixLength: new FormControl('', Validators.required),
      saveMode: new FormControl(''),
      couponItem: this.formBuilder.array([this.createCouponItem()]),
      listCouponTicket: this.formBuilder.array([this.createCouponItemTicket()])
    });
  }

  get couponForm() { return this.listCouponForm.controls; }

  createCouponItem() {
    return this.formBuilder.group({
      couponId: [''],
      eventId: [''],
      ticketId: [''],
      couponType: [''],
      couponTypeDesc: [''],
      couponMode: [''],
      couponModeDesc: [''],
      unitRateDesc: [''],
      prefix: [''],
      suffix: [''],
      discountRate: [''],
      unitRate: [''],
      qty: [''],
      maxRegister: [''],
      startDate: [''],
      expireDate: [''],
      status: [''],
      descTh: [''],
      descEn: [''],
      createDate: [''],
      createBy: [''],
      updateDate: [''],
      updateBy: [''],
      couponCode: [''],
      suffixLength: [''],
      saveMode: [''],
    });
  }

  get couponItem(): FormArray {
    return this.listCouponForm.get('couponItem') as FormArray;
  };

  //// ไว้ใส่ list ticket ที่มีอยู่ใน  คูปองนั้น ๆ ///
  createCouponItemTicket() {
    return this.formBuilder.group({
      couponId: [''],
      eventId: [''],
      ticketId: [''],
      ticketName: [''],
      distance: [''],
      unit: [''],
      status: [''],
      statusBoolean: [''],
    });
  }

  get listCouponTicket(): FormArray {
    return this.listCouponForm.get('listCouponTicket') as FormArray;
  };

  //// ไว้ใส่ list ticket ที่มีอยู่ใน  คูปองนั้น ๆ ///

  mapDataCoupon() {
    console.log('listCoupon', this.listCoupon);
    if (this.listCoupon.length > 0) {
      let indexCoupon = 0;
      this.couponItem.clear();
      this.couponItem.removeAt(indexCoupon = 0);
      for (let i = 0; i < this.listCoupon.length; i++) {
        this.couponItem.insert(indexCoupon, this.formBuilder.group({
          couponId: this.listCoupon[i].couponId,
          eventId: this.eventId,
          couponCode: this.listCoupon[i].couponCode,
          couponTypeDesc: this.listCoupon[i].couponTypeDesc,
          couponModeDesc: this.listCoupon[i].couponModeDesc,
          prefix: this.listCoupon[i].prefix,
          suffix: this.listCoupon[i].suffix,
          discountRate: this.listCoupon[i].discountRate,
          couponMode: this.listCoupon[i].couponMode,
          unitRateDesc: this.listCoupon[i].unitRateDesc,
          status: this.listCoupon[i].status,
          unitRate: this.listCoupon[i].unitRate,
          qty: this.listCoupon[i].qty,
          maxRegister: this.listCoupon[i].maxRegister,
          descTh: this.listCoupon[i].descTh,
          descEn: this.listCoupon[i].descEn,
          startDate: this.listCoupon[i].startDate,
          expireDate: this.listCoupon[i].expireDate,
          couponType: this.listCoupon[i].couponType,
          suffixLength: this.listCoupon[i].suffixLength,
        }));
        indexCoupon = indexCoupon + 1;
      }
    } else {
      this.buildFormCoupon();
    }
  }

  editCoupon(event) {
    if (event == 'ADD') {
      this.submitCouponForm = false;
      var datePipe = new DatePipe('en-US')
      var date = datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.saveMode = 'ADD';
      this.listCouponForm.controls.couponCode.setValue("");
      this.listCouponForm.controls.couponId.setValue(0);
      this.listCouponForm.controls.eventId.setValue(this.eventId);
      this.listCouponForm.controls.prefix.setValue("");
      this.listCouponForm.controls.suffix.patchValue("");
      this.listCouponForm.controls.status.setValue("INACTIVE");
      this.listCouponForm.controls.descTh.setValue("");
      this.listCouponForm.controls.descEn.setValue("");
      this.listCouponForm.controls.discountRate.setValue(0);
      this.listCouponForm.controls.unitRate.setValue("");
      this.listCouponForm.controls.couponMode.setValue("");
      this.listCouponForm.controls.qty.setValue(0);
      this.listCouponForm.controls.maxRegister.setValue(0);
      this.listCouponForm.controls.startDate.setValue(date);
      this.listCouponForm.controls.expireDate.setValue(date);
      this.listCouponForm.controls.couponType.setValue("");
      this.listCouponForm.controls.saveMode.setValue("ADD");
      this.couponTypeOnCheck = "SINGLE";
      this.listCouponForm.controls.suffixLength.setValue(0);
      this.mapticketByCoupon(event.couponId);
    } else {
      this.saveMode = 'EDIT';
      this.listCouponForm.controls.couponCode.setValue(event.couponCode);
      this.listCouponForm.controls.couponId.setValue(event.couponId);
      this.listCouponForm.controls.prefix.setValue(event.prefix);
      this.listCouponForm.controls.suffix.patchValue(event.suffix);
      this.listCouponForm.controls.eventId.setValue(this.eventId);
      this.listCouponForm.controls.status.setValue(event.status);
      this.listCouponForm.controls.descTh.setValue(event.descTh);
      this.listCouponForm.controls.descEn.setValue(event.descEn);
      this.listCouponForm.controls.discountRate.setValue(event.discountRate);
      this.listCouponForm.controls.unitRate.setValue(event.unitRate);
      this.listCouponForm.controls.couponMode.setValue(event.couponMode);
      this.listCouponForm.controls.qty.setValue(event.qty);
      this.listCouponForm.controls.maxRegister.setValue(event.maxRegister);
      this.listCouponForm.controls.startDate.setValue(event.startDate);
      this.listCouponForm.controls.expireDate.setValue(event.expireDate);
      this.listCouponForm.controls.couponType.setValue(event.couponType);
      this.listCouponForm.controls.saveMode.setValue("EDIT");
      this.couponTypeOnCheck = event.couponType;
      this.listCouponForm.controls.suffixLength.setValue(event.suffixLength);
      this.changeCouponType(event.couponType)
      this.changeCouponMode(event.couponMode, event.discountRate, event.unitRate);
      this.mapticketByCoupon(event.couponId);
    }
    this.modalService.open(this.editCouponModal, { backdrop: 'static', windowClass: "couponModal" });
  }

  mapticketByCoupon(couponId) {
    if (this.saveMode == 'ADD') {
      if (this.listTicketByCoupon.length > 0) {
        let indexCouponTicket = 0;
        this.listCouponTicket.clear();
        this.listCouponTicket.removeAt(indexCouponTicket = 0);
        for (let i = 0; i < this.listTicketByCoupon.length; i++) {
          this.listCouponTicket.insert(indexCouponTicket, this.formBuilder.group({
            couponId: this.listTicketByCoupon[i].couponId,
            ticketId: this.listTicketByCoupon[i].ticketId,
            ticketName: this.listTicketByCoupon[i].ticketName,
            statusBoolean: this.listTicketByCoupon[i].statusBoolean,
            status: "INACTIVE",
          }));
          indexCouponTicket = indexCouponTicket + 1;
        }
      }
    } else {
      const f = this.listCoupon.filter(c => c.couponId == couponId);
      if (f[0].listCouponTicket.length > 0) {
        let indexCouponTicket = 0;
        this.listCouponTicket.clear();
        this.listCouponTicket.removeAt(indexCouponTicket = 0);
        for (let i = 0; i < f[0].listCouponTicket.length; i++) {
          this.listCouponTicket.insert(indexCouponTicket, this.formBuilder.group({
            couponId: f[0].listCouponTicket[i].couponId,
            ticketId: f[0].listCouponTicket[i].ticketId,
            ticketName: f[0].listCouponTicket[i].ticketName,
            statusBoolean: f[0].listCouponTicket[i].statusBoolean,
            status: f[0].listCouponTicket[i].status,
          }));
          indexCouponTicket = indexCouponTicket + 1;
        }
      } else {
        let indexCouponTicket = 0;
        this.listCouponTicket.clear();
        this.listCouponTicket.removeAt(indexCouponTicket = 0);
        for (let i = 0; i < this.listTicketByCoupon.length; i++) {
          this.listCouponTicket.insert(indexCouponTicket, this.formBuilder.group({
            couponId: this.listTicketByCoupon[i].couponId,
            ticketId: this.listTicketByCoupon[i].ticketId,
            ticketName: this.listTicketByCoupon[i].ticketName,
            statusBoolean: this.listTicketByCoupon[i].statusBoolean,
            status: "INACTIVE",
          }));
          indexCouponTicket = indexCouponTicket + 1;
        }
      }
    }
  }

  saveCoupon(status) {
    let formStatus = "INVALID";
    let listCouponModel = new CouponModel;
    let main_form: FormData = new FormData();
    if (this.listCouponTicket.value != undefined) {
      for (let i = 0; i < this.listCouponTicket.value.length; i++) {
        if (this.listCouponTicket.value[i].statusBoolean == true) {
          this.listCouponTicket.value[i].status = "ACTIVE";
        } else {
          this.listCouponTicket.value[i].status = "INACTIVE";
        }
      }
      formStatus = "VALID";
    }
    if (formStatus == "VALID") {
      listCouponModel = this.listCouponForm.value;
      this.submitCouponForm = true;
      if (this.listCouponForm.status == 'VALID' && this.maxQty != true && this.maxRegisStatus != true && this.maxSuffixLength != true
        && this.discountRateMax != true) {
        if (status == true) {
          this.service.saveCoupon(listCouponModel).subscribe(data => {
            this.modalService.dismissAll();
            if (data != null) {
              for (let i = 0; i < this.listCoupon.length; i++) {
                if (this.listCoupon[i].couponId == data.couponId) {
                  this.listCoupon[i] = data;
                }
              }
              this.listEventCoupon();
              this.msgError = 'ระบบทำรายการเรียบร้อย';
              this.modalService.open(this.warnningValidateAdd, { backdrop: 'static', windowClass: 'myCustomModalClass' });
              this.submitCouponForm = true;
            }
          });
        } else {
          if (this.saveMode == 'ADD') {
            this.msgDescription = 'ยืนยันการบันทึกข้อมูล';
            this.modalService.open(this.saveValidCoupon, { backdrop: 'static', windowClass: 'myCustomModalClass' });
          } else {
            this.msgDescription = 'ยืนยันการแก้ไขข้อมูล';
            this.modalService.open(this.saveValidCoupon, { backdrop: 'static', windowClass: 'myCustomModalClass' });
          }
        }
      }
    }
  }

  onItemTicket(event, item) {
    if (item != undefined) {
      for (let i = 0; i < this.listCouponTicket.value.length; i++) {
        if (item.ticketId == this.listCouponTicket.value[i].ticketId) {
          this.listCouponTicket.value[i].statusBoolean = event.target.checked;
          this.listCouponTicket.value[i] = item;
        }
      }
    }
  }

  changeCouponType(event) {
    if (event.target != undefined) {
      this.couponTypeOnCheck = event.target.value;
      if (event.target.value == 'MULTIPLE') {
        this.isDisabledSuffix = true;
        this.listCouponForm.controls.suffix.setValue('NONE');
        this.listCouponForm.controls.suffixLength.setValue(1);
        this.getGenerateType();
      } else {
        this.changeMaxRegister();
        this.isDisabledSuffix = false;
        const listGenerate = [];
        if (this.listGenerateType != null && this.listGenerateType.length > 0) {
          for (let i = 0; i < this.listGenerateType.length; i++) {
            if (this.listGenerateType[i].value1 != 'NONE') {
              console.log(listGenerate[i]);
              listGenerate.push(this.listGenerateType[i]);
            }
          }
          this.listGenerateType = listGenerate;
        }
      }
    } else {
      this.couponTypeOnCheck = event;
      if (event == 'MULTIPLE') {
        this.isDisabledSuffix = true;
        this.listCouponForm.controls.suffix.setValue('NONE');
        this.listCouponForm.controls.suffixLength.setValue(1);
        this.getGenerateType();
      } else {
        this.changeMaxRegister();
        this.isDisabledSuffix = false;
        const listGenerate = [];
        if (this.listGenerateType != null && this.listGenerateType.length > 0) {
          for (let i = 0; i < this.listGenerateType.length; i++) {
            if (this.listGenerateType[i].value1 != 'NONE') {
              console.log(listGenerate[i]);
              listGenerate.push(this.listGenerateType[i]);
            }
          }
          this.listGenerateType = listGenerate;
        }
      }
    }
  }

  changeMaxQty() {
    if (this.listCouponForm.controls.qty.value != '') {
      if (Number(this.listCouponForm.controls.qty.value) <= 1000) {
        this.maxQty = false;
        this.changeMaxRegister();
      } else {
        this.maxQty = true;
        this.maxQtyMessage = 'จำนวนไม่ควรเกิน 1000';
      }
    } else {
      this.maxQty = true;
      this.maxQtyMessage = '';
    }
  }

  changeMaxRegister() {
    if (this.listCouponForm.controls.maxRegister.value != '') {
      console.log('qty', this.listCouponForm.controls.qty.value);
      console.log('maxRegister', this.listCouponForm.controls.maxRegister.value);
      if (Number(this.listCouponForm.controls.maxRegister.value) <= 1000 && Number(this.listCouponForm.controls.maxRegister.value) <= Number(this.listCouponForm.controls.qty.value)) {
        this.maxRegisStatus = false;
        this.maxRegisMessage = '';
        console.log('ใช้งานได้');
      } else {
        this.maxRegisStatus = true;
        this.maxRegisMessage = 'จำนวนให้สมัครเยอะกว่าจำนวนคูปองที่มี';
        console.log('มากกว่า');
      }
    } else {
      this.maxRegisStatus = true;
      this.maxRegisMessage = '';
      console.log('ไม่กรอก');
    }
  }

  changeCouponMode(event, discountRate, unitRate) {
    if (event.target != undefined) {
      if (event.target.value == 'FREE') {
        this.isDisabledCouponMode = true;
        this.listCouponForm.controls.discountRate.setValue(0);
        this.listCouponForm.controls.unitRate.setValue('BAHT');
      } else {
        this.isDisabledCouponMode = false;
      }
    } else {
      if (event == 'FREE') {
        this.isDisabledCouponMode = true;
        this.listCouponForm.controls.discountRate.setValue(0);
        this.listCouponForm.controls.unitRate.setValue('BAHT');
      } else {
        this.isDisabledCouponMode = false;
        this.listCouponForm.controls.discountRate.setValue(discountRate);
        this.listCouponForm.controls.unitRate.setValue(unitRate);
      }
    }
  }

  changeSuffixLength() {
    if (Number(this.listCouponForm.controls.suffixLength.value) <= 10) {
      this.maxSuffixLength = false;
    } else {
      this.maxSuffixLength = true;
      this.maxSuffixLengthMsg = 'จำนวนห้ามเกิน 10';
    }
  }

  changeUnitRate() {
    if (this.listCouponForm.controls.unitRate.value == 'PERCENT') {
      if (this.listCouponForm.controls.discountRate.value != '') {
        if (Number(this.listCouponForm.controls.discountRate.value) <= 100) {
          this.discountRateMax = false;
        } else {
          this.discountRateMax = true;
          this.discountRateMaxMsg = 'ห้ามเกิน 100 %';
        }
      } else {
        this.discountRateMax = true;
        this.discountRateMaxMsg = '';
      }
    } else {
      if (this.listCouponForm.controls.discountRate.value != '') {
        this.discountRateMax = false;
      } else {
        this.discountRateMax = true;
        this.discountRateMaxMsg = '';
      }
    }
  }

  exportCoupon(event) {
    this.service.couponSummary(event.couponId, this.eventName);
  }

}
