import { TicketFieldObjectModel } from'./field-object.model';

export class TicketFieldModel {
	ticketId : any ; 
	ticketFieldId : any ; 
	fieldName  : any;
	fieldLabel  : any;
	fieldDataType  : any;
	fieldInputType  : any;
	fieldPlacehold  : any;
	fieldDesc : any ;
	fieldLimit : any ;
	status : any ;
	createDate  : any;
	createBy : any ;
	fieldSeq :any;
	updateDate  : any;
	updateBy : any ;
	fieldRequired : any;
	fieldPattern : any;
	fieldDisabled : any;
	ticketObjectStatus : any;
	ticketObjectImage : any;
	ticketObjectImagePath :any;
    listFieldObject : TicketFieldObjectModel [] = [];
}