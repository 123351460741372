import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vrgo-admin-payment-paid',
  templateUrl: './vrgo-admin-payment-paid.component.html',
  styleUrls: ['./vrgo-admin-payment-paid.component.scss']
})
export class VRGoAdminPaymentPaidComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
