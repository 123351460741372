export class UserAdmin{
    userId: number;
	userName: string;
	email: string;
	firstName: string;
	lastName: string;
	contact: string;
	password: string;
	tel: string;
	newPassword: string;
	oldPassword: string;
	profileImg: string;
	profileImageUrl: string;
	validate: string;
	errorMessage: string;
	invalidUser: string;
	roleId: string;
	roleName: string;
}