import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './vrgo-admin-main.component.html',
  styleUrls: ['./vrgo-admin-main.component.scss']
})
export class VRGoAdminMainComponent implements OnInit {

    constructor(
    ) { }
  
    ngOnInit() {
    }

}
