import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { TicketModel } from '../../shared/models/ticket.model';
import { TicketPriceModel } from '../../shared/models/ticketprice.model';
import { NgbModal, NgbDateParserFormatter, NgbDateAdapter, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from '../../shared/authentication/token-storage.service';
import { ParameterUrl } from '../../shared/class/ParameterUrl';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { TicketFieldModel } from '../../shared/models/ticket-field.model';
import { TicketFieldObjectModel } from '../../shared/models/field-object.model';
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : '';
  }
}
@Component({
  selector: 'app-vrgo-admin-ticket-detail',
  templateUrl: './vrgo-admin-ticket-detail.component.html',
  styleUrls: ['./vrgo-admin-ticket-detail.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class VrgoAdminTicketDetailComponent implements OnInit {
  @ViewChild('editTicketPriceModal', { static: false }) editTicketPriceModal;
  @ViewChild('editTicketFieldModal', { static: false }) editTicketFieldModal;
  @ViewChild('warnningValidate', { static: false }) warnningValidate;
  @ViewChild('saveValid', { static: false }) saveValid;
  @ViewChild('warnningValidateAdd', { static: false }) warnningValidateAdd;
  @ViewChild('saveValidPriceModel', { static: false }) saveValidPriceModel;
  @ViewChild('warnning', { static: false }) warnning;
  listDataType: any;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private clientservice: HttpClientService,
    private modalvalidate: NgbActiveModal,
    private modalService: NgbModal,
    private token: TokenStorageService,
    private param: ParameterUrl
  ) { }

  ticketId: any;
  listTicketPrice: any = [];
  eventId: any;
  ticketPriceId: any;

  listPriceType: any;
  listTicketApprove: any;
  listTicketSubmit: any;
  listTicketStatus: any;
  listUnitTicket: any;
  listTicketType: any;
  listTicketObjType: any;
  listTicketField: any;
  listTicketPriceStatus: any;
  listshippingStatus: any;
  userProfile: any;
  TicketFieldId: any;
  listDisabledStatus: any;
  inputType: any;
  mode: any;
  msgError: any;
  listTickeFieldType: any;
  fieldInputTypeObj: any;
  listFielObj: TicketFieldObjectModel[] = []
  statusSaveObj: any = 'NO';
  listTicketForm: FormGroup;
  listTicketPriceForm: FormGroup;
  listTicketPriceFormModal: FormGroup;
  listTicketFieldForm: FormGroup;
  listTicketFieldFormModal: FormGroup;
  listTicketFieldObjFormModal: FormGroup;

  submitted: boolean = false;
  submittedPrice: boolean = false;
  submittedField: boolean = false;
  totalfiles: Array<File> = [];
  totalFileName = [];
  deleteDocumentFile: Array<Object> = [];
  lengthCheckToaddMore = 0;

  priceTypeClass: string = 'form-control';
  statusClass: string = 'form-control';
  statusPriceClassModal: string = 'form-control';

  ticketTypeClass: string = 'form-control';
  ticketTypeMsg: string = '';

  addressStatusClass: string = 'form-control';
  submitStatusClass: string = 'form-control';
  approveStatusClass: string = 'form-control';
  fieldNameClass: string = 'form-control';
  statusFieldClassModal: string = 'form-control';
  msgDescription: any;
  statusSave: string = '';
  ticketType: any = '';
  ngOnInit() {
    this.token.getUserProfile();
    this.userProfile = JSON.parse(this.token.getUserProfile());
    this.buildFormTicket();
    this.buildTicketPrice();
    this.buildTicketPriceModal();
    this.buildTicketFieldModal();
    this.buildTicketField();
    this.buildTicketFieldObjModal();
    this.getPriceType();
    this.getTicketType();
    this.getTicketPriceStatus();
    this.getTicketStatus();
    this.getUnitTicket();
    this.getDisabledStatus();
    this.getTicketApprove();
    this.getTicketSubmit();
    this.getshippingStatus();
    this.getTicketObjType();
    this.getTickeFieldType();
    this.getDataType();
    this.route.data.subscribe(data => {
      this.mode = data.transactionType.toString();
      if (this.mode.toUpperCase() === 'ADD') {
        console.log('mode', this.mode);
        this.eventId = this.token.getEventId();
        if (this.param.data !== undefined && this.param.data !== null && this.param.data !== '') {
          this.eventId = this.param.data.eventId;
          this.token.saveEventId(this.eventId);
          // console.log('EventId',this.eventId);
        }
      }
      if (this.mode.toUpperCase() === 'EDIT') {
        this.route.params.subscribe(routeParam => {
          this.ticketId = routeParam.ticketId
          const listTicket = new TicketModel;
          listTicket['ticketId'] = this.ticketId;
          console.log('ticketId', listTicket);
          this.clientservice.getTicketById(listTicket).subscribe(data =>
            this.mapTicketById(data));
        });
      }
    })


  }

  mapTicketById(data) {
    console.log('data >>>', data);
    if (data.ticketId != undefined && data.ticketId != null) {
      const listTicketPrice = new TicketPriceModel;
      const listTicketField = new TicketFieldModel;
      listTicketPrice['ticketId'] = data.ticketId;
      listTicketField['ticketId'] = data.ticketId;
      this.eventId = data.eventId;
      // console.log('data >>',data)
      this.clientservice.listTicketPrice(listTicketPrice).subscribe((data) => {
        // this.listTicketPrice.push(data);
        // console.log('listTicketPrice >>',data)
        this.listTicketPrice = data;
        if (this.listTicketPrice.length > 0) {
          let indexPrice = 0;
          this.TicketPriceItem.clear();
          // this.TicketPriceItem.removeAt(indexPrice = 0);
          for (let i = 0; i < this.listTicketPrice.length; i++) {
            this.TicketPriceItem.insert(indexPrice, this.formBuilder.group({
              ticketPriceId: this.listTicketPrice[i].ticketPriceId,
              desctiption: this.listTicketPrice[i].desctiption,
              price: this.listTicketPrice[i].price,
              priceType: this.listTicketPrice[i].priceType,
              status: this.listTicketPrice[i].status,

            }));
            indexPrice = indexPrice + 1;
          }
        } else {
          this.buildTicketPrice();
        }
      });
      this.clientservice.listTicketField(listTicketField).subscribe(data => {
        this.mapTicketField(data);
      })

    }
    this.listTicketForm.controls.ticketName.setValue(data.ticketName);
    this.listTicketForm.controls.ticketNameEn.setValue(data.ticketNameEn);
    this.listTicketForm.controls.price.setValue(data.price);
    this.listTicketForm.controls.priceType.setValue(data.priceType);
    this.listTicketForm.controls.distance.setValue(data.distance);
    this.listTicketForm.controls.ticketObjectDescription.setValue(data.ticketObjectDescription);
    this.listTicketForm.controls.ticketObjectDescriptionEn.setValue(data.ticketObjectDescriptionEn);
    this.listTicketForm.controls.status.setValue(data.status);
    this.listTicketForm.controls.unit.setValue(data.unit);
    this.listTicketForm.controls.startTime.setValue(data.startTime);
    this.listTicketForm.controls.ticketType.setValue(data.ticketType);
    this.listTicketForm.controls.approveStatus.setValue(data.approveStatus);
    this.listTicketForm.controls.submitStatus.setValue(data.submitStatus);
    if (this.mode === 'ADD') {
      this.listTicketForm.controls['ticketType'].enable();
    }
    this.listTicketForm.controls.addressStatus.setValue(data.addressStatus);


  }
  mapTicketField(data) {
    this.listTicketField = data;
    // console.log('data>>',this.listTicketField);
    if (this.listTicketField.length > 0) {
      let indexField = 0;
      this.TicketFieldItem.clear();
      this.TicketFieldItem.removeAt(indexField = 0);
      for (let i = 0; i < this.listTicketField.length; i++) {
        this.TicketFieldItem.insert(indexField, this.formBuilder.group({
          ticketId: this.listTicketField[i].ticketId,
          TicketFieldId: this.listTicketField[i].ticketFieldId,
          ticketObjectImage: this.listTicketField[i].ticketObjectImage,
          status: this.listTicketField[i].status,
          fieldLabel: this.listTicketField[i].fieldLabel,
          fieldLimit: this.listTicketField[i].fieldLimit,
        }));
        indexField = indexField + 1;
      }
    } else {
      this.buildTicketField();
    }
  }

  ///////////////////////////////////////////////////// BUILD FORM /////////////////////////////////////////////////////////////
  buildFormTicket() {
    this.listTicketForm = this.formBuilder.group({
      ticketId: new FormControl(''),
      ticketName: new FormControl('', [Validators.required]),
      ticketNameEn: new FormControl('', [Validators.required]),
      priceId: new FormControl(''),
      price: new FormControl(''),
      priceType: new FormControl('0'),
      distance: new FormControl('', [Validators.required]),
      ticketObjectDescription: new FormControl('', [Validators.required]),
      ticketObjectDescriptionEn: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
      ticketAmt: new FormControl(''),
      addressStatus: new FormControl('', [Validators.required]),
      unit: new FormControl('', [Validators.required]),
      ticketType: new FormControl('', [Validators.required]),
      approveStatus: new FormControl('', [Validators.required]),
      submitStatus: new FormControl('', [Validators.required]),
      startTime: new FormControl('', [Validators.required]),

    });
  }
  get editForm() { return this.listTicketForm.controls; }

  buildTicketPrice() {
    this.listTicketPriceForm = this.formBuilder.group({
      ticketPriceId: new FormControl(''),
      desctiption: new FormControl(''),
      price: new FormControl(''),
      priceType: new FormControl(''),
      status: new FormControl(''),
      TicketPriceItem: this.formBuilder.array([this.createTicketPrice()])
    });
  }

  createTicketPrice() {
    return this.formBuilder.group({
      ticketPriceId: [''],
      desctiption: [''],
      price: [''],
      priceType: [''],
      status: [''],
    });
  }
  get TicketPriceItem(): FormArray {
    return this.listTicketPriceForm.get('TicketPriceItem') as FormArray;
  };

  buildTicketPriceModal() {
    this.listTicketPriceFormModal = this.formBuilder.group({
      ticketPriceId: new FormControl(''),
      desctiption: new FormControl(''),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      paymentGatewayFee: new FormControl(''),
      platformFee: new FormControl(''),
      price: new FormControl('', [Validators.required]),
      priceType: new FormControl('', [Validators.required]),
      status: new FormControl('', [Validators.required]),
      vat: new FormControl(''),
      TicketPriceItem: this.formBuilder.array([this.createTicketPrice()])
    });
  }
  get priceFormModal() { return this.listTicketPriceFormModal.controls; }
  buildTicketField() {
    this.listTicketFieldForm = this.formBuilder.group({
      ticketId: new FormControl(''),
      TicketFieldId: new FormControl(''),
      ticketObjectImage: new FormControl(''),
      status: new FormControl(''),
      fieldLabel: new FormControl(''),
      TicketFieldItem: this.formBuilder.array([this.createTicketField()])
    });
  }
  createTicketField() {
    return this.formBuilder.group({
      ticketId: [''],
      TicketFieldId: [''],
      ticketObjectImage: [''],
      status: [''],
      fieldLabel: [''],
    });
  }
  get TicketFieldItem(): FormArray {
    return this.listTicketFieldForm.get('TicketFieldItem') as FormArray;
  };
  buildTicketFieldModal() {
    this.listTicketFieldFormModal = this.formBuilder.group({
      ticketId: new FormControl(''),
      TicketFieldId: new FormControl(''),
      fieldLimit: new FormControl(0),
      fieldDataType: new FormControl(''),
      fieldInputType: new FormControl('', [Validators.required]),
      fieldDisabled: new FormControl(''),
      fieldName: new FormControl('', [Validators.required]),
      fieldPlacehold: new FormControl(''),
      fieldRequired: new FormControl(''),
      fieldSeq: new FormControl(''),
      status: new FormControl('', [Validators.required]),
      ticketObjectImage: new FormControl(''),
      ticketObjectStatus: new FormControl(''),
      fieldLabel: new FormControl('', [Validators.required]),
    });
  }
  get TicketFieldFormModal() { return this.listTicketFieldFormModal.controls; }
  buildTicketFieldObjModal() {
    this.listTicketFieldObjFormModal = this.formBuilder.group({
      fieldObjectId: new FormControl(''),
      ticketFieldId: new FormControl(''),
      description: new FormControl(''),
      objectDesc: new FormControl(''),
      objectValue: new FormControl(''),
      status: new FormControl(''),
      objectSeq: new FormControl(''),
      fieldLabel: new FormControl(''),
      isChecked: new FormControl({ value: '', disabled: false }, Validators.requiredTrue),
      TicketFieldObjItem: this.formBuilder.array([this.createTicketFieldObj()])
    });
  }
  createTicketFieldObj() {
    return this.formBuilder.group({
      fieldObjectId: [''],
      ticketFieldId: [''],
      description: [''],
      objectDesc: [''],
      objectValue: [''],
      status: [''],
      objectSeq: [''],
      fieldLabel: [''],
      isChecked: ['']
    });
  }
  get TicketFieldObjItem(): FormArray {
    return this.listTicketFieldObjFormModal.get('TicketFieldObjItem') as FormArray;
  };
  ///////////////////////////////////////////////////// END //////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////// EDIT TICKET PRICE ////////////////////////////////////////////////////
  changeTicketType(event) {
    this.ticketType = event.target.value;
    // console.log('changeTicketType >', event.target.value);
  }
  editTicketPriceItem(ticketPriceId) {
    this.submittedPrice = false;
    // console.log('ticketPriceId',ticketPriceId)
    if (ticketPriceId == undefined) {
      ticketPriceId = 0
    }
    // console.log('ticketPriceId',ticketPriceId)
    if (ticketPriceId != undefined && ticketPriceId != null && ticketPriceId != 0) {
      const listTicketPrice = new TicketPriceModel;
      listTicketPrice['ticketId'] = this.ticketId;
      console.log('ticketId', listTicketPrice['ticketId']);
      this.clientservice.getTicketById(listTicketPrice).subscribe(data =>
        this.mapTicketById(data));
      const f = this.listTicketPrice.filter(c => c.ticketPriceId === ticketPriceId);
      // console.log('f',f);
      this.listTicketPriceFormModal.controls.ticketPriceId.setValue(f[0].ticketPriceId);
      this.listTicketPriceFormModal.controls.priceType.setValue(f[0].priceType);
      this.listTicketPriceFormModal.controls.desctiption.setValue(f[0].desctiption);
      this.listTicketPriceFormModal.controls.startDate.setValue(f[0].startDate);
      this.listTicketPriceFormModal.controls.endDate.setValue(f[0].endDate);
      this.listTicketPriceFormModal.controls.paymentGatewayFee.setValue(f[0].paymentGatewayFee);
      this.listTicketPriceFormModal.controls.vat.setValue(f[0].vat);
      this.listTicketPriceFormModal.controls.price.setValue(f[0].price);
      this.listTicketPriceFormModal.controls.status.setValue(f[0].status);
      this.modalService.open(this.editTicketPriceModal, { backdrop: 'static', windowClass: 'editTicketPriceItemClass' });
    } else {
      this.listTicketPriceFormModal.controls.ticketPriceId.setValue(0);
      this.listTicketPriceFormModal.controls.priceType.setValue("");
      this.listTicketPriceFormModal.controls.desctiption.setValue("");
      this.listTicketPriceFormModal.controls.startDate.setValue("");
      this.listTicketPriceFormModal.controls.endDate.setValue("");
      this.listTicketPriceFormModal.controls.paymentGatewayFee.setValue("0");
      this.listTicketPriceFormModal.controls.vat.setValue("0");
      if (this.ticketType == 'VR_FREE') {
        this.listTicketPriceFormModal.controls.price.setValue("0");
      } else {
        this.listTicketPriceFormModal.controls.price.setValue("");
      }
      this.listTicketPriceFormModal.controls.status.setValue("");
      this.modalService.open(this.editTicketPriceModal, { backdrop: 'static', windowClass: 'editTicketPriceItemClass' });
    }

  }
  deleteTicketPrice(index, ticketPriceId) {
    // console.log('ticketPriceId',ticketPriceId)
    if (ticketPriceId != '') {
      let obj = {
        'ticketPriceId': ticketPriceId
      }
      this.deleteDocumentFile.push(obj);
    }
    if (ticketPriceId != 0) {
      this.clientservice.deleteTicketPrice(ticketPriceId).subscribe(data => {
        this.mapDelData(data)
      })
    }
    this.TicketPriceItem.removeAt(index);
  }
  mapDelData(data) {
    this.ngOnInit();
  }
  ///////////////////////////////////////////////////// END //////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////// EDIT TICKET Field ////////////////////////////////////////////////////
  editTicketField(TicketFieldId) {
    this.TicketFieldId = TicketFieldId
    const listTicketFieldObj = new TicketFieldObjectModel;
    if (TicketFieldId == undefined) {
      TicketFieldId = 0;
    }
    // console.log('listTicketField',this.listTicketField);
    if (TicketFieldId != undefined && TicketFieldId != null && TicketFieldId != 0) {
      const f = this.listTicketField.filter(c => c.ticketFieldId == TicketFieldId);
      this.listTicketFieldFormModal.controls.TicketFieldId.setValue(TicketFieldId);
      this.listTicketFieldFormModal.controls.fieldName.setValue(f[0].fieldName);
      this.listTicketFieldFormModal.controls.status.setValue(f[0].status);
      this.listTicketFieldFormModal.controls.ticketObjectImage.setValue(f[0].ticketObjectImage);
      this.listTicketFieldFormModal.controls.fieldLabel.setValue(f[0].fieldLabel);
      this.listTicketFieldFormModal.controls.fieldPlacehold.setValue(f[0].fieldPlacehold);
      this.listTicketFieldFormModal.controls.fieldDataType.setValue(f[0].fieldDataType);
      this.listTicketFieldFormModal.controls.fieldInputType.setValue(f[0].fieldInputType);
      this.listTicketFieldFormModal.controls.fieldDisabled.setValue(f[0].fieldDisabled);
      this.listTicketFieldFormModal.controls.fieldRequired.setValue(f[0].fieldRequired);
      this.listTicketFieldFormModal.controls.fieldSeq.setValue(f[0].fieldSeq);
      this.listTicketFieldFormModal.controls.fieldLimit.setValue(f[0].fieldLimit);
      this.listTicketFieldFormModal.controls.ticketObjectStatus.setValue(f[0].ticketObjectStatus);
      this.fieldInputTypeObj = f[0].fieldInputType;
      this.inputType = f[0].fieldInputType;
      if (this.fieldInputTypeObj == 'COMBOBOX') {
        this.statusSaveObj = 'YES';
        listTicketFieldObj['ticketFieldId'] = TicketFieldId;
        this.clientservice.getFieldObjectById(listTicketFieldObj).subscribe(data => {
          this.mapFieldObjectById(data)
        });
      } else {
        this.statusSaveObj = 'NO';
      }

      this.modalService.open(this.editTicketFieldModal, { backdrop: 'static', windowClass: 'editTicketFieldModalClass' });
    } else {
      // this.buildTicketFieldObjModal();
      this.statusSaveObj = 'NO';
      this.listTicketFieldFormModal.controls.TicketFieldId.setValue(TicketFieldId);
      this.listTicketFieldFormModal.controls.fieldName.setValue("");
      this.listTicketFieldFormModal.controls.fieldPlacehold.setValue("");
      this.listTicketFieldFormModal.controls.status.setValue("");
      this.listTicketFieldFormModal.controls.ticketObjectImage.setValue("");
      this.listTicketFieldFormModal.controls.fieldLabel.setValue("");
      this.listTicketFieldFormModal.controls.fieldLimit.setValue(0);
      this.modalService.open(this.editTicketFieldModal, { backdrop: 'static', windowClass: 'editTicketFieldModalClass' });
    }
  }
  changeFieldInput(event) {
    this.inputType = event.target.value;
    // console.log('event >>',event.target.value);
    if (event.target.value == 'COMBOBOX') {
      this.statusSaveObj = 'YES';
      if (this.statusSaveObj == 'YES') {
        this.buildTicketFieldObjModal();
      }
    } else {
      this.statusSaveObj = 'NO';
    }
  }
  changeField(event) {
    var target = event.target.value
    this.statusSaveObj = 'NO';
    // console.log('EVENT >>',target);
    if (target == 'shirt_size') {
      this.listTicketFieldFormModal.controls.fieldPlacehold.setValue("กรุณาเลือกขนาดเสื้อ");
      this.listTicketFieldFormModal.controls.fieldLabel.setValue("ขนาดเสื้อ");
      this.listTicketFieldFormModal.controls.fieldDataType.setValue("TEXT");
      this.listTicketFieldFormModal.controls.fieldInputType.setValue("COMBOBOX");
      this.listTicketFieldFormModal.controls.fieldDisabled.setValue("N");
      this.listTicketFieldFormModal.controls.fieldRequired.setValue("Y");
      this.listTicketFieldFormModal.controls.fieldSeq.setValue("0");
      this.listTicketFieldFormModal.controls.ticketObjectStatus.setValue("OBJECT");
      this.fieldInputTypeObj = this.listTicketFieldFormModal.controls.fieldInputType.value;
    } else if (target == 'shirt_size_1') {
      this.listTicketFieldFormModal.controls.fieldPlacehold.setValue("กรุณาเลือกขนาดเสื้อ");
      this.listTicketFieldFormModal.controls.fieldLabel.setValue("ขนาดเสื้อ");
      this.listTicketFieldFormModal.controls.fieldDataType.setValue("TEXT");
      this.listTicketFieldFormModal.controls.fieldInputType.setValue("COMBOBOX");
      this.listTicketFieldFormModal.controls.fieldDisabled.setValue("N");
      this.listTicketFieldFormModal.controls.fieldRequired.setValue("Y");
      this.listTicketFieldFormModal.controls.fieldSeq.setValue("0");
      this.listTicketFieldFormModal.controls.ticketObjectStatus.setValue("OBJECT");
      this.fieldInputTypeObj = this.listTicketFieldFormModal.controls.fieldInputType.value;
    } else if (target == 'shirt_size_2') {
      this.listTicketFieldFormModal.controls.fieldPlacehold.setValue("กรุณาเลือกขนาดเสื้อ");
      this.listTicketFieldFormModal.controls.fieldLabel.setValue("ขนาดเสื้อ");
      this.listTicketFieldFormModal.controls.fieldDataType.setValue("TEXT");
      this.listTicketFieldFormModal.controls.fieldInputType.setValue("COMBOBOX");
      this.listTicketFieldFormModal.controls.fieldDisabled.setValue("N");
      this.listTicketFieldFormModal.controls.fieldRequired.setValue("Y");
      this.listTicketFieldFormModal.controls.fieldSeq.setValue("0");
      this.listTicketFieldFormModal.controls.ticketObjectStatus.setValue("OBJECT");
      this.fieldInputTypeObj = this.listTicketFieldFormModal.controls.fieldInputType.value;
    } else if (target == 'shirt_color') {
      this.listTicketFieldFormModal.controls.fieldPlacehold.setValue("กรุณาเลือก สีเสื้อ");
      this.listTicketFieldFormModal.controls.fieldLabel.setValue("สีเสื้อ");
      this.listTicketFieldFormModal.controls.fieldDataType.setValue("TEXT");
      this.listTicketFieldFormModal.controls.fieldInputType.setValue("COMBOBOX");
      this.listTicketFieldFormModal.controls.fieldDisabled.setValue("N");
      this.listTicketFieldFormModal.controls.fieldRequired.setValue("Y");
      this.listTicketFieldFormModal.controls.fieldSeq.setValue("0");
      this.listTicketFieldFormModal.controls.ticketObjectStatus.setValue("OBJECT");
      this.fieldInputTypeObj = this.listTicketFieldFormModal.controls.fieldInputType.value;
    } else if (target == 'medal') {
      this.listTicketFieldFormModal.controls.fieldPlacehold.setValue("กรุณาเลือกเหรียญ");
      this.listTicketFieldFormModal.controls.fieldLabel.setValue("เหรียญรางวัล");
      this.listTicketFieldFormModal.controls.fieldDataType.setValue("TEXT");
      this.listTicketFieldFormModal.controls.fieldInputType.setValue("TEXTHIDE");
      this.listTicketFieldFormModal.controls.fieldDisabled.setValue("N");
      this.listTicketFieldFormModal.controls.fieldRequired.setValue("N");
      this.listTicketFieldFormModal.controls.fieldSeq.setValue("0");
      this.listTicketFieldFormModal.controls.ticketObjectStatus.setValue("OBJECT");
      this.fieldInputTypeObj = this.listTicketFieldFormModal.controls.fieldInputType.value;
    } else if (target == 'team') {
      this.listTicketFieldFormModal.controls.fieldPlacehold.setValue("กรุณาระบุ ทีม");
      this.listTicketFieldFormModal.controls.fieldLabel.setValue("ทีม");
      this.listTicketFieldFormModal.controls.fieldDataType.setValue("TEXT");
      this.listTicketFieldFormModal.controls.fieldInputType.setValue("INPUT");
      this.listTicketFieldFormModal.controls.fieldDisabled.setValue("N");
      this.listTicketFieldFormModal.controls.fieldRequired.setValue("Y");
      this.listTicketFieldFormModal.controls.fieldSeq.setValue("0");
      this.listTicketFieldFormModal.controls.ticketObjectStatus.setValue("OBJECT");
      this.fieldInputTypeObj = this.listTicketFieldFormModal.controls.fieldInputType.value;
    } else if (target == 'mask') {
      this.listTicketFieldFormModal.controls.fieldPlacehold.setValue("กรุณาระบุ หน้ากากผ้า");
      this.listTicketFieldFormModal.controls.fieldLabel.setValue("หน้ากากผ้า");
      this.listTicketFieldFormModal.controls.fieldDataType.setValue("TEXT");
      this.listTicketFieldFormModal.controls.fieldInputType.setValue("TEXTHIDE");
      this.listTicketFieldFormModal.controls.fieldDisabled.setValue("N");
      this.listTicketFieldFormModal.controls.fieldRequired.setValue("N");
      this.listTicketFieldFormModal.controls.fieldSeq.setValue("0");
      this.listTicketFieldFormModal.controls.ticketObjectStatus.setValue("OBJECT");
      this.fieldInputTypeObj = this.listTicketFieldFormModal.controls.fieldInputType.value;
    }
    else if (target == 'eBadge') {
      this.listTicketFieldFormModal.controls.fieldPlacehold.setValue("");
      this.listTicketFieldFormModal.controls.fieldLabel.setValue("e-Badge");
      this.listTicketFieldFormModal.controls.fieldDataType.setValue("TEXT");
      this.listTicketFieldFormModal.controls.fieldInputType.setValue("TEXTHIDE");
      this.listTicketFieldFormModal.controls.fieldDisabled.setValue("N");
      this.listTicketFieldFormModal.controls.fieldRequired.setValue("N");
      this.listTicketFieldFormModal.controls.fieldSeq.setValue("0");
      this.listTicketFieldFormModal.controls.ticketObjectStatus.setValue("OBJECT");
      this.fieldInputTypeObj = this.listTicketFieldFormModal.controls.fieldInputType.value;
    } else if (target == 'medal_engraved') {
      this.listTicketFieldFormModal.controls.fieldPlacehold.setValue("กรุณากรอกชื่อบนเหรียญ");
      this.listTicketFieldFormModal.controls.fieldLabel.setValue("เหรียญรางวัลสลักชื่อ");
      this.listTicketFieldFormModal.controls.fieldDataType.setValue("TEXT");
      this.listTicketFieldFormModal.controls.fieldInputType.setValue("INPUT");
      this.listTicketFieldFormModal.controls.fieldDisabled.setValue("N");
      this.listTicketFieldFormModal.controls.fieldRequired.setValue("Y");
      this.listTicketFieldFormModal.controls.fieldSeq.setValue("0");
      this.listTicketFieldFormModal.controls.ticketObjectStatus.setValue("OBJECT");
      this.fieldInputTypeObj = this.listTicketFieldFormModal.controls.fieldInputType.value;
    } else if (target == 'medal_engraved_1') {
      this.listTicketFieldFormModal.controls.fieldPlacehold.setValue("กรุณากรอกชื่อบนเหรียญ");
      this.listTicketFieldFormModal.controls.fieldLabel.setValue("เหรียญรางวัลสลักชื่อ");
      this.listTicketFieldFormModal.controls.fieldDataType.setValue("TEXT");
      this.listTicketFieldFormModal.controls.fieldInputType.setValue("INPUT");
      this.listTicketFieldFormModal.controls.fieldDisabled.setValue("N");
      this.listTicketFieldFormModal.controls.fieldRequired.setValue("Y");
      this.listTicketFieldFormModal.controls.fieldSeq.setValue("0");
      this.listTicketFieldFormModal.controls.ticketObjectStatus.setValue("OBJECT");
      this.fieldInputTypeObj = this.listTicketFieldFormModal.controls.fieldInputType.value;
    } else if (target == 'medal_engraved_2') {
      this.listTicketFieldFormModal.controls.fieldPlacehold.setValue("กรุณากรอกชื่อบนเหรียญ");
      this.listTicketFieldFormModal.controls.fieldLabel.setValue("เหรียญรางวัลสลักชื่อ");
      this.listTicketFieldFormModal.controls.fieldDataType.setValue("TEXT");
      this.listTicketFieldFormModal.controls.fieldInputType.setValue("INPUT");
      this.listTicketFieldFormModal.controls.fieldDisabled.setValue("N");
      this.listTicketFieldFormModal.controls.fieldRequired.setValue("Y");
      this.listTicketFieldFormModal.controls.fieldSeq.setValue("0");
      this.listTicketFieldFormModal.controls.ticketObjectStatus.setValue("OBJECT");
      this.fieldInputTypeObj = this.listTicketFieldFormModal.controls.fieldInputType.value;
    }
    this.inputType = this.fieldInputTypeObj;
    //   if(this.fieldInputTypeObj == 'COMBOBOX'){
    //     this.statusSaveObj = 'YES';
    //     console.log('i');
    //     }
    //  console.log('statusSaveObj',this.statusSaveObj);
    //  console.log('this.fieldInputTypeObj',this.fieldInputTypeObj);

  }


  fileSelectionEvent(fileInput: any) {
    // console.log('oldIndex', oldIndex);
    // console.log('fileInput', fileInput);
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      this.totalfiles.unshift((fileInput.target.files[0]))
      this.totalFileName.unshift(fileInput.target.files[0].name)
      this.listTicketFieldFormModal.controls.ticketObjectImage.setValue(fileInput.target.files[0].name);
      reader.readAsDataURL(fileInput.target.files[0]);
    }

    if (this.totalfiles.length == 1) {
      this.lengthCheckToaddMore = 1;
    }

  }
  deleteTicketField(index, TicketFieldId) {
    // console.log('TicketFieldId',TicketFieldId)
    if (TicketFieldId != '') {
      let obj = {
        'TicketFieldId': TicketFieldId,
      }
      this.deleteDocumentFile.push(obj);
    }
    if (TicketFieldId != 0) {
      this.clientservice.deleteTicketField(TicketFieldId).subscribe(data => {
        // this.mapDelData(data)
      })
    }
    this.TicketFieldItem.removeAt(index);
  }
  ///////////////////////////////////////////////////// END //////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////// EDIT TICKET Field Obj////////////////////////////////////////////////////
  addItemFieldObj(): void {
    this.TicketFieldObjItem.insert(this.TicketFieldObjItem.length + 1, this.createTicketFieldObj())
    this.lengthCheckToaddMore = this.lengthCheckToaddMore + 1;
  }
  deleteTicketFieldObj(index, fieldObjectId) {
    // console.log('eventImageId',fieldObjectId)
    if (fieldObjectId != '') {
      let obj = {
        'fieldObjectId': fieldObjectId,
      }
      this.deleteDocumentFile.push(obj);
    }
    if (fieldObjectId != 0) {
      this.clientservice.deleteTicketFieldObject(fieldObjectId).subscribe(data => {
        // this.mapDelData(data)
      })
    }
    this.TicketFieldObjItem.removeAt(index);
  }
  ///////////////////////////////////////////////////// END //////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////// GET DATA /////////////////////////////////////////////////////////////
  getPriceType() {
    this.clientservice.getListBoxByGroup('PRICETICKET_TYPE').subscribe((data) => {
      this.listPriceType = data;
    });
  }
  getTicketStatus() {
    this.clientservice.getListBoxByGroup('TICKET_STATUS_CONFIG').subscribe((data) => {
      this.listTicketStatus = data;
    });
  }
  getUnitTicket() {
    this.clientservice.getListBoxByGroup('UNIT_TICKET').subscribe((data) => {
      this.listUnitTicket = data;
    });
  }
  getTicketPriceStatus() {
    this.clientservice.getListBoxByGroup('TICKET_PRICE_STATUS').subscribe((data) => {
      this.listTicketPriceStatus = data;
    });
  }
  getTicketType() {
    this.clientservice.getListBoxByGroup('TICKET_TYPE').subscribe((data) => {
      this.listTicketType = data;
    })
  }
  getshippingStatus() {
    this.clientservice.getListBoxByGroup('SHIPPING_STATUS').subscribe((items) => {
      this.listshippingStatus = items;
    });
  }
  getTicketSubmit() {
    this.clientservice.getListBoxByGroup('TICKET_SUBMIT_STATUS').subscribe((items) => {
      this.listTicketSubmit = items;
    });
  }
  getTicketApprove() {
    this.clientservice.getListBoxByGroup('TICKET_APPROVE').subscribe((items) => {
      this.listTicketApprove = items;
    });
  }
  getTicketObjType() {
    this.clientservice.getListBoxByGroup('TICKET_OBJECT_TYPE').subscribe((items) => {
      this.listTicketObjType = items;
    });
  }
  getTickeFieldType() {
    this.clientservice.getListBoxByGroup('TICKET_FIELD_INPUT_TYPE').subscribe((items) => {
      this.listTickeFieldType = items;
    });
  }
  getDisabledStatus() {
    this.clientservice.getListBoxByGroup('DISABLED_STATUS').subscribe((items) => {
      this.listDisabledStatus = items;
      // console.log('listDisabledStatus',this.listDisabledStatus)
    });
  }

  getDataType() {
    this.clientservice.getListBoxByGroup('TICKET_FIELD_DATA_TYPE').subscribe((data) => {
      this.listDataType = data;
    });
  }
  ///////////////////////////////////////////////////// END /////////////////////////////////////////////////////////////
  validateSaveTicket() {
    this.submitted = true;
    // this.submittedPrice = true ;
    if (this.listTicketForm.status == 'VALID') {
      this.msgDescription = 'ยืนยันการแก้ไข';
      this.modalService.open(this.saveValid, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    } else {
      // this.statusSave = 'NO';
      // console.log('status >>',this.statusSave);
    }
  }
  validateSaveTicketModel() {
    // this.submitted = true ;
    this.submittedPrice = true;
    if (this.listTicketPriceFormModal.status == 'VALID') {
      this.msgDescription = 'ยืนยันการแก้ไข';
      this.modalvalidate = this.modalService.open(this.saveValidPriceModel, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    } else {
      // this.statusSave = 'NO';
      // console.log('status >>',this.statusSave);
    }
  }
  validateSaveTicketAdd() {
    this.submitted = true;
    this.submittedPrice = true;
    if (this.listTicketForm.status == 'VALID' && this.listTicketPriceFormModal.status == 'VALID') {
      this.msgDescription = 'ยืนยันการแก้ไข';
      this.modalService.open(this.saveValid, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    } else {
      // this.statusSave = 'NO';
      // console.log('status >>',this.statusSave);
    }
  }
  saveTicket() {
    if (this.mode == 'ADD') {
      this.ticketId = 0;
    }
    const listTicket = new TicketModel;
    listTicket['eventId'] = this.eventId;
    listTicket['ticketId'] = this.ticketId;
    listTicket['ticketPriceId'] = 0;
    listTicket['ticketName'] = this.listTicketForm.controls.ticketName.value;
    listTicket['ticketNameEn'] = this.listTicketForm.controls.ticketNameEn.value;
    listTicket['ticketType'] = this.listTicketForm.controls.ticketType.value;
    listTicket['distance'] = this.listTicketForm.controls.distance.value;
    listTicket['ticketObjectDescription'] = this.listTicketForm.controls.ticketObjectDescription.value;
    listTicket['ticketObjectDescriptionEn'] = this.listTicketForm.controls.ticketObjectDescriptionEn.value;
    listTicket['status'] = this.listTicketForm.controls.status.value;
    listTicket['unit'] = this.listTicketForm.controls.unit.value;
    listTicket['startTime'] = this.listTicketForm.controls.startTime.value;
    listTicket['cutoffLength'] = 0;
    listTicket['ticketAmt'] = 0;
    listTicket['addressStatus'] = this.listTicketForm.controls.addressStatus.value;
    listTicket['updateBy'] = this.userProfile.userId;
    listTicket['description'] = '';
    listTicket['approveStatus'] = this.listTicketForm.controls.approveStatus.value;
    listTicket['submitStatus'] = this.listTicketForm.controls.submitStatus.value;
    // console.log('listTicket >>',JSON.stringify(listTicket));
    if (this.listTicketForm.status == 'VALID') {
      if (this.mode == 'EDIT') {
        this.clientservice.editTicket(listTicket).subscribe(data => {
          this.mapEditTicket(data);
        });
      } else if (this.mode == 'ADD') {
        this.clientservice.addTicket(listTicket).subscribe(data => {
          this.mapAddTicket(data);
        });
      }

    }

  }
  mapEditTicket(data) {
    this.modalService.dismissAll();
    this.msgError = 'ระบบทำรายการเรียบร้อย';
    this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    // console.log('mapEditTicket >>',data);

  }
  mapAddTicket(data) {
    this.modalService.dismissAll();
    this.ticketId = data.ticketId
    this.saveTicketPriceModal();
  }
  goToEdit() {
    this.modalService.dismissAll();
    this.router.navigate(['vrgoAdmin/ticketDetail/edit/' + this.ticketId]);
    const listTicket = new TicketModel;
    listTicket['ticketId'] = this.ticketId;
    // console.log('data >>',data);
    this.clientservice.getTicketById(listTicket).subscribe(data =>
      this.mapTicketById(data));
  }
  saveTicketPriceModal() {
    var status = 'NO';
    var listTicketPriceNormal = [];
    var listTicketPriceEARLY_BIRD = [];
    // console.log('arrey >> ',this.listTicketPrice);
    if (this.listTicketPrice != undefined && this.listTicketPrice != null && this.listTicketPrice != null && this.listTicketPrice.length > 0 && this.listTicketPriceFormModal.controls.ticketPriceId.value != 0) {
      for (let i = 0; i < this.listTicketPrice.length; i++) {
        if (this.listTicketPrice[i].ticketPriceId == this.listTicketPriceFormModal.controls.ticketPriceId.value) {
          this.listTicketPrice[i].priceType = this.listTicketPriceFormModal.controls.priceType.value;
          this.listTicketPrice[i].ticketId = this.ticketId;
          this.listTicketPrice[i].desctiption = this.listTicketPriceFormModal.controls.desctiption.value;
          this.listTicketPrice[i].startDate = this.listTicketPriceFormModal.controls.startDate.value;
          this.listTicketPrice[i].endDate = this.listTicketPriceFormModal.controls.endDate.value;
          this.listTicketPrice[i].paymentGatewayFee = this.listTicketPriceFormModal.controls.paymentGatewayFee.value;
          this.listTicketPrice[i].vat = this.listTicketPriceFormModal.controls.vat.value;
          this.listTicketPrice[i].price = this.listTicketPriceFormModal.controls.price.value;
          this.listTicketPrice[i].status = this.listTicketPriceFormModal.controls.status.value;
          this.listTicketForm.controls.priceType.setValue(this.listTicketPriceFormModal.controls.priceType.value);
          this.listTicketForm.controls.price.setValue(this.listTicketPriceFormModal.controls.price.value);
          status = 'YES';
        }
        if (this.listTicketPrice[i].status == 'ACTIVE') {
          if (this.listTicketPrice[i].ticketPriceId != this.listTicketPriceFormModal.controls.ticketPriceId.value) {
            this.listTicketPrice[i].status = 'INACTIVE';
          }
          status = 'YES';
          // console.log('>>',this.listTicketPrice[i]);
        }
        // else{
        //   this.listTicketPrice[i].status = this.listTicketPriceFormModal.controls.status.value;
        //   console.log('2 >>',this.listTicketPrice[i]);
        // }
      }
    } else {
      const listTicketPrice = new Object
      // listTicketPriceNormal  = [];
      // listTicketPriceEARLY_BIRD  = [];
      // console.log('listTicketPriceNormal',listTicketPriceNormal);
      // console.log('listTicketPriceEARLY_BIRD',listTicketPriceEARLY_BIRD);


      // console.log('listTicketPriceNormal',listTicketPriceNormal);
      // console.log('listTicketPriceEARLY_BIRD',listTicketPriceEARLY_BIRD);
      // if(listTicketPriceNormal.length > 0 && this.listTicketPriceFormModal.controls.priceType.value == 'NORMAL'){
      //     console.log('NORMAL มากกว่า 1');
      //     status = 'NO';
      //     this.modalvalidate.close();
      //     this.msgError = 'ประเภทราคาไม่ควรมีมากกว่า 1';
      //     this.modalService.open(this.warnning,{backdrop:'static' ,windowClass:'myCustomModalClass'});
      // }else if(listTicketPriceEARLY_BIRD.length > 0 && this.listTicketPriceFormModal.controls.priceType.value == 'EARLY_BIRD'){
      //     console.log('EARLY_BIRD มากกว่า 1');
      //     status = 'NO';
      //     this.modalvalidate.close();
      //     this.msgError = 'ประเภทราคาไม่ควรมีมากกว่า 1';
      //     this.modalService.open(this.warnning,{backdrop:'static' ,windowClass:'myCustomModalClass'});
      // }else
      // console.log('arrey >> ',this.listTicketPrice);
      for (let i = 0; i < this.listTicketPrice.length; i++) {
        if (this.listTicketPrice[i].status == 'ACTIVE') {
          if (this.listTicketPrice[i].ticketPriceId != this.listTicketPriceFormModal.controls.ticketPriceId.value) {
            this.listTicketPrice[i].status = 'INACTIVE';
          }
          // console.log('>>',this.listTicketPrice[i]);
        }
      }
      listTicketPrice['ticketId'] = this.ticketId;
      listTicketPrice['ticketPriceId'] = 0;
      listTicketPrice['priceType'] = this.listTicketPriceFormModal.controls.priceType.value;
      listTicketPrice['desctiption'] = this.listTicketPriceFormModal.controls.desctiption.value;
      listTicketPrice['startDate'] = this.listTicketPriceFormModal.controls.startDate.value;
      listTicketPrice['endDate'] = this.listTicketPriceFormModal.controls.endDate.value;
      listTicketPrice['paymentGatewayFee'] = this.listTicketPriceFormModal.controls.paymentGatewayFee.value;
      listTicketPrice['vat'] = this.listTicketPriceFormModal.controls.vat.value;
      listTicketPrice['price'] = this.listTicketPriceFormModal.controls.price.value;
      listTicketPrice['status'] = this.listTicketPriceFormModal.controls.status.value;
      listTicketPrice['platformFee'] = 0;
      this.listTicketForm.controls.priceType.setValue(this.listTicketPriceFormModal.controls.priceType.value);
      this.listTicketForm.controls.price.setValue(this.listTicketPriceFormModal.controls.price.value);
      this.listTicketPrice.push(listTicketPrice);
      status = 'YES';
    }
    if (status == 'YES') {
      this.clientservice.editTicketPrice(this.listTicketPrice).subscribe(data => {
        this.mapEditTicketPrice(data);
      });
    }
  }
  mapEditTicketPrice(data) {
    this.modalService.dismissAll();
    this.listTicketPrice = data
    if (this.listTicketPrice.length > 0) {
      let indexPrice = 0;
      this.TicketPriceItem.clear();
      this.TicketPriceItem.removeAt(indexPrice = 0);
      for (let i = 0; i < this.listTicketPrice.length; i++) {
        this.TicketPriceItem.insert(indexPrice, this.formBuilder.group({
          ticketPriceId: this.listTicketPrice[i].ticketPriceId,
          desctiption: this.listTicketPrice[i].desctiption,
          price: this.listTicketPrice[i].price,
          priceType: this.listTicketPrice[i].priceType,
          status: this.listTicketPrice[i].status,

        }));
        indexPrice = indexPrice + 1;
      }
    } else {
      this.buildTicketPrice();
    }
    this.submittedPrice = false;
    this.msgError = 'ทำการบันทึกข้อมูลเรียบร้อย';
    this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    // console.log('mapEditTicketPrice >>',data)
  }
  saveTicketFieldModal() {
    // console.log(this.listTicketFieldFormModal.status);
    this.submittedField = true;
    const fieldSeq = [];
    const listTicketField = new Object;
    let seq = 0;
    let main_form: FormData = new FormData();
    for (let j = 0; j < this.totalfiles.length; j++) {
      main_form.append(this.totalFileName[j], <File>this.totalfiles[j]);
    }
    for (let i = 0; i < this.listTicketField.length; i++) {
      fieldSeq.push(this.listTicketField[i].fieldSeq);
    }
    if (fieldSeq.length == 0) {
      seq = 0;
    } else {
      seq = Math.max(...fieldSeq);
    }

    if (this.listTicketField.length > 0 && this.listTicketFieldFormModal.controls.TicketFieldId.value != 0) {
      // console.log('!');
      if (this.listTicketFieldFormModal.controls.fieldLimit.value == 0) {
        this.listTicketFieldFormModal.controls.fieldLimit.setValue("");
      }
      for (let i = 0; i < this.listTicketField.length; i++) {
        if (this.listTicketField[i].ticketFieldId == this.listTicketFieldFormModal.controls.TicketFieldId.value) {
          this.listTicketField[i].ticketId = Number(this.ticketId);
          this.listTicketField[i].TicketFieldId = 0;
          this.listTicketField[i].fieldDesc = '';
          this.listTicketField[i].fieldLimit = this.listTicketFieldFormModal.controls.fieldLimit.value;
          this.listTicketField[i].listFieldObject = [];
          this.listTicketField[i].fieldPattern = null;
          this.listTicketField[i].fieldDataType = this.listTicketFieldFormModal.controls.fieldDataType.value;
          this.listTicketField[i].fieldInputType = this.listTicketFieldFormModal.controls.fieldInputType.value;
          this.listTicketField[i].fieldDisabled = this.listTicketFieldFormModal.controls.fieldDisabled.value;
          this.listTicketField[i].fieldName = this.listTicketFieldFormModal.controls.fieldName.value;
          this.listTicketField[i].fieldPlacehold = this.listTicketFieldFormModal.controls.fieldPlacehold.value;
          this.listTicketField[i].fieldRequired = this.listTicketFieldFormModal.controls.fieldRequired.value;
          this.listTicketField[i].fieldSeq = this.listTicketFieldFormModal.controls.fieldSeq.value;
          this.listTicketField[i].status = this.listTicketFieldFormModal.controls.status.value;
          this.listTicketField[i].ticketObjectImage = this.listTicketFieldFormModal.controls.ticketObjectImage.value;
          this.listTicketField[i].ticketObjectStatus = this.listTicketFieldFormModal.controls.ticketObjectStatus.value;
          this.listTicketField[i].fieldLabel = this.listTicketFieldFormModal.controls.fieldLabel.value;

          if (this.listTicketFieldFormModal.status == 'VALID') {
            this.clientservice.editTicketField(this.listTicketField[i]).subscribe(data => {
              this.mapEditTicketField(data);
            });
            this.clientservice.saveTicketImage(main_form).subscribe(data => { data });
          }
        }

      }
    } else {
      listTicketField['ticketId'] = Number(this.ticketId);
      listTicketField['TicketFieldId'] = 0;
      listTicketField['fieldDesc'] = '';
      listTicketField['fieldLimit'] = this.listTicketFieldFormModal.controls.fieldLimit.value;
      listTicketField['listFieldObject'] = [];
      listTicketField['fieldPattern'] = null;
      listTicketField['fieldDataType'] = this.listTicketFieldFormModal.controls.fieldDataType.value;
      listTicketField['fieldInputType'] = this.listTicketFieldFormModal.controls.fieldInputType.value;
      listTicketField['fieldDisabled'] = this.listTicketFieldFormModal.controls.fieldDisabled.value;
      listTicketField['fieldName'] = this.listTicketFieldFormModal.controls.fieldName.value;
      listTicketField['fieldPlacehold'] = this.listTicketFieldFormModal.controls.fieldPlacehold.value;
      listTicketField['fieldRequired'] = this.listTicketFieldFormModal.controls.fieldRequired.value;
      listTicketField['fieldSeq'] = seq + 1;
      listTicketField['status'] = this.listTicketFieldFormModal.controls.status.value;
      listTicketField['ticketObjectImage'] = this.listTicketFieldFormModal.controls.ticketObjectImage.value;
      listTicketField['ticketObjectStatus'] = this.listTicketFieldFormModal.controls.ticketObjectStatus.value;
      listTicketField['fieldLabel'] = this.listTicketFieldFormModal.controls.fieldLabel.value;
      if (this.listTicketFieldFormModal.status == 'VALID') {
        this.clientservice.editTicketField(listTicketField).subscribe(data => {
          this.mapEditTicketField(data);
        });
        this.clientservice.saveTicketImage(main_form).subscribe(data => { data });
      }
    }

  }
  mapEditTicketField(data) {
    this.submittedField = false;
    // this.statusSaveObj = 'YES';
    // this.modalService.dismissAll();
    // console.log('statusSaveObj',this.statusSaveObj)
    this.listTicketField = data
    this.TicketFieldId = this.listTicketField.ticketFieldId
    this.fieldInputTypeObj = this.listTicketField.fieldInputType;
    // console.log(this.fieldInputTypeObj);
    if (this.fieldInputTypeObj == 'TEXTHIDE' || this.fieldInputTypeObj == 'INPUT') {
      this.modalService.dismissAll();
      this.msgError = 'ทำการบันทึกข้อมูลเรียบร้อย';
      this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    } else {
      this.statusSaveObj = 'YES';
    }
    this.inputType = this.listTicketField.fieldInputType;
    // console.log('fieldInputTypeObj >>',this.inputType);
    const listTicketField = new TicketFieldModel;
    const listTicketFieldObj = new TicketFieldObjectModel;

    listTicketField['ticketId'] = data.ticketId;
    this.clientservice.listTicketField(listTicketField).subscribe(data => {
      this.mapTicketField(data);
    });

    listTicketFieldObj['ticketFieldId'] = this.listTicketField.ticketFieldId;
    this.clientservice.getFieldObjectById(listTicketFieldObj).subscribe(data => {
      this.mapFieldObjectById(data)
    });
  }
  mapFieldObjectById(data) {
    // console.log('TicketFieldObj >>',data);
    this.listFielObj = data;
    if (this.listFielObj.length > 0) {
      let indexFielObj = 0;
      this.TicketFieldObjItem.clear();
      this.TicketFieldObjItem.removeAt(indexFielObj = 0);
      for (let i = 0; i < this.listFielObj.length; i++) {
        this.TicketFieldObjItem.insert(indexFielObj, this.formBuilder.group({
          fieldObjectId: this.listFielObj[i].fieldObjectId,
          ticketFieldId: this.listFielObj[i].ticketFieldId,
          description: this.listFielObj[i].description,
          objectDesc: this.listFielObj[i].objectDesc,
          objectValue: this.listFielObj[i].objectValue,
          status: this.listFielObj[i].status,
          objectSeq: this.listFielObj[i].objectSeq,
          fieldLabel: this.listFielObj[i].fieldLabel,
          // isChecked: false,
        }));
        indexFielObj = indexFielObj + 1;
      }
    } else {
      this.buildTicketFieldObjModal();
    }
  }
  saveTicketFieldObjModal() {
    // console.log('1');
    // console.log('length',this.listTicketFieldObjFormModal.value.TicketFieldObjItem)
    const listFielObj = new Object;
    const AllFilesObj = []
    const ObjdSeq = [];
    let seq = 0;
    // for(let i=0;i<this.listFielObj.length;i++){
    //   ObjdSeq.push(this.listFielObj[i].objectSeq)
    // }
    // if(ObjdSeq.length == 0){
    //   seq = 0 ;
    // }else{
    //   seq = Math.max(...ObjdSeq);
    // }
    this.listTicketFieldObjFormModal.value.TicketFieldObjItem.forEach((element, index) => {
      let eachObj =
      {
        'fieldObjectId': (element.fieldObjectId || 0),
        'ticketFieldId': this.TicketFieldId || 0,
        'description': element.objectDesc,
        'objectDesc': element.objectDesc,
        'objectValue': element.objectValue,
        'status': element.status,
        'objectSeq': element.objectSeq,
        'fieldLabel': element.fieldLabel
      }
      AllFilesObj.push(eachObj);
    });
    // console.log('AllFilesObj',AllFilesObj);
    // console.log('AllFilesObj',JSON.stringify(AllFilesObj));
    this.clientservice.editFieldObject(AllFilesObj).subscribe(data => {
      this.mapEditFieldObject(data);
    });
  }
  mapEditFieldObject(data) {
    this.modalService.dismissAll();
    // this.statusSaveObj = false;
    this.msgError = 'ทำการบันทึกข้อมูลเรียบร้อย';
    this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    // console.log('mapEditFieldObject >>',data);
    // console.log('mapEditFieldObject >>',JSON.stringify(data));
  }
  onChange(event, type) {
    const key = event.key;
    // console.log('type',type ,event)
    if (type === 'NUMBER') {
      if (!key.match('^-?(0|[1-9]\d*)?$')) {
        event.preventDefault();
      }
    } else if (type === 'TEXT') {
      if (!key.match('^[a-zA-Z]')) {
        event.preventDefault();
      }
    } else if (type === 'ALL') {
      if (!key.match('^[A-Za-z0-9_.]+$')) {
        event.preventDefault();
      }
    }
  }
  goBack() {
    this.router.navigate(['vrgoAdmin/eventDetail/edit/' + this.eventId]);
  }
}
