import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { EBibECertConfig } from 'src/app/shared/models/ebib-ecert-config.model';
import { Router } from '@angular/router';
import { UserProfiles } from '../../shared/models/userprofile.model';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from '../../shared/authentication/token-storage.service';

@Component({
  selector: 'app-vrgo-admin-users-search',
  templateUrl: './vrgo-admin-users-search.component.html',
  styleUrls: ['./vrgo-admin-users-search.component.scss']
})
export class VrgoAdminUsersSearchComponent implements OnInit {
  @ViewChild('editUserModel', { static: false }) editUserModel;
  @ViewChild('saveSuccess', { static: false }) saveSuccess;
  @ViewChild('saveValid', { static: false }) saveValid;
  constructor(
    private http: HttpClientService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private token: TokenStorageService,
  ) { }

  searchControl: FormControl = new FormControl();
  moduleLoading: boolean = false;
  search: boolean = false;
  formUserSearch: FormGroup;
  formUserEdit: FormGroup;
  listUsers: any;
  filterlistUsers: any;
  listProvider: any;
  provider = 'USER_PROVIDER';
  providerValue: any;
  listUserById: any;
  currentLesson: any = '1';
  submitted: boolean = false;
  msgDescription: any;
  createByClass: string = "form-control";
  createByErrorMsg: string = '';
  telClass = "form-control";
  userId: UserProfiles;
  userStatus: UserProfiles;
  privateUser: UserProfiles;
  userProfile: any;
  mode: any = '';
  email: UserProfiles;
  firstName: any;
  msgBox: any;
  msgBoxNewPassword: any;
  errors: boolean = false;
  userEmail: string = '';
  providerNameMsg: string = '';
  userEmailMsg: string = '';
  providerNameClass: string = 'form-control';
  emailClass: string = 'form-control';

  ngOnInit() {
    this.mode = '';
    this.token.getUserProfile();
    this.userProfile = JSON.parse(this.token.getUserProfile());
    this.buildUserSearch();
    this.buildUserEdit();
    this.searchControl.valueChanges
      .pipe(debounceTime(200))
      .subscribe(value => {
        this.filerData(value);
      });
    this.getProviderUsers();
    this.chackmail();
  }
  buildUserSearch() {
    this.formUserSearch = this.formBuilder.group(
      {
        providerName: new FormControl(''),
        email: new FormControl(''),
      });
  }
  buildUserEdit() {
    this.formUserEdit = this.formBuilder.group(
      {
        email: new FormControl('', [Validators.required]),
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        tel: new FormControl('', [Validators.required]),
      });
  }
  get userForm() { return this.formUserEdit.controls; }

  getlistUser() {
    var isNull: boolean = false;
    if (this.formUserSearch.controls.providerName.value == '' || this.userEmail == '') {
      if (this.formUserSearch.controls.providerName.value == '' && isNull == false) {
        this.providerNameClass = 'text-red form-control';
        // isNull = isNull + 1 ;
        this.providerNameMsg = 'กรุณาระบุ ประเภทของผูัสมัครการใช้งาน';
      } else {
        isNull = true
        this.providerNameClass = 'form-control';
        this.providerNameMsg = '';
      }

      if (this.userEmail == '' && isNull == false) {
        this.emailClass = 'text-red form-control';
        this.userEmailMsg = 'กรุณาระบุ อีเมล์';
        // isNull = isNull + 1 ;
      } else if (this.userEmail.length < 5 && this.userEmail != '') {
        this.emailClass = 'text-red form-control';
        // isNull = isNull + 1 ;
        this.userEmailMsg = 'กรุณาระบุ อีเมล์ อย่างน้อย 5 ตัวอักษร';
        this.providerNameClass = 'form-control';
        this.providerNameMsg = '';
      } else {
        isNull = true
        this.emailClass = 'form-control';
        this.userEmailMsg = '';
        this.providerNameClass = 'form-control';
        this.providerNameMsg = '';
      }

    } else if (this.formUserSearch.controls.providerName.value != '' && this.userEmail != '') {
      if (this.userEmail.length < 5) {
        this.emailClass = 'text-red form-control';
        this.userEmailMsg = 'กรุณาระบุ อีเมล์ อย่างน้อย 5 ตัวอักษร';
        this.providerNameClass = 'form-control';
        this.providerNameMsg = '';
      } else {
        isNull = true
        this.emailClass = 'form-control';
        this.userEmailMsg = '';
        this.providerNameClass = 'form-control';
        this.providerNameMsg = '';
      }
    }
    if (isNull == true) {
      this.moduleLoading = true;
      const listUser = new UserProfiles;
      listUser['provider'] = this.providerValue;
      listUser['email'] = this.userEmail;
      console.log('listUser >>', listUser);
      this.http.listUser(listUser).subscribe(data => {
        this.maplistUser(data);
      });
    }

  }
  getValueProvider(event) {
    // console.log('as',event.target.value)
    this.providerValue = event.target.value
  }
  getProviderUsers() {
    this.http.getListBoxByGroup(this.provider).subscribe(data => {
      this.listProvider = data
      // console.log('listProvider',this.listProvider);
    });
  }
  maplistUser(data) {
    this.moduleLoading = false;
    // console.log('data',data);
    this.listUsers = data;
    this.filterlistUsers = data;
    this.listUsers = data;

  }
  getUserId(userId) {
    // console.log('userId',userId);
    this.userId = userId
    const f = this.listUsers.filter(c => c.userId == userId);
    this.listUserById = f;
    // console.log('f',this.listUserById);
    this.userStatus = this.listUserById[0].status;
    this.privateUser = this.listUserById[0].provider;
    this.email = this.listUserById[0].email;
    this.firstName = this.listUserById[0].firstName;
    this.buildFormData();
    this.chackmail();

  }
  buildFormData() {
    this.formUserEdit.controls['email'].setValue(this.listUserById[0].email);
    this.formUserEdit.controls['firstName'].setValue(this.listUserById[0].firstName);
    this.formUserEdit.controls['lastName'].setValue(this.listUserById[0].lastName);
    this.formUserEdit.controls['tel'].setValue(this.listUserById[0].tel);
  }
  filerData(val) {
    if (val) {
      val = val.toLowerCase();
    } else {
      return this.filterlistUsers = [...this.listUsers];
    }

    const columns = Object.keys(this.listUsers[0]);
    if (!columns.length) {
      return;
    }

    const rows = this.listUsers.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        const column = columns[i];
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });
    this.filterlistUsers = rows;
  }

  openUserModel() {
    this.modalService.open(this.editUserModel, { backdrop: 'static', windowClass: 'myUserModalClass' });
  }
  closeModal() {
    this.modalService.dismissAll();
  }
  chackmail() {
    this.createByClass = "form-control";
    this.createByErrorMsg = '';
  }
  submit() {
    this.submitted = true;
    this.errors = false;
    let isNull = 0;
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.formUserEdit.controls.firstName.status == 'INVALID') {
      isNull = isNull + 1;
    }
    if (this.formUserEdit.controls.lastName.status == 'INVALID') {
      isNull = isNull + 1;
    }
    if (this.formUserEdit.controls['tel'].value != "") {
      if (this.formUserEdit.controls['tel'].value.length < 10) {
        this.telClass = "form-control text-error";
        this.errors = true;
        isNull = isNull + 1
      }
    } else {
      this.telClass = "form-control";
    }
    if (!this.formUserEdit.controls.email.value.match(regex)) {
      isNull = isNull + 1
      this.createByClass = "form-control text-error";
      this.createByErrorMsg = 'กรุณาระบุ อีเมล์ให้ถูกต้อง';
    } else {
      this.createByClass = "form-control";
      this.createByErrorMsg = '';
    }
    if (isNull == 0) {
      this.msgDescription = 'ยืนยันการแก้ไขข้อมูล';
      this.mode = 'SAVE';
      this.modalService.open(this.saveValid, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    }
  }
  saveUser() {
    this.moduleLoading = true;
    if (this.mode === 'SAVE') {
      const listUser = new UserProfiles;
      listUser['userId'] = this.userId;
      listUser['email'] = this.formUserEdit.controls.email.value;
      listUser['firstName'] = this.formUserEdit.controls.firstName.value;
      listUser['lastName'] = this.formUserEdit.controls.lastName.value;
      listUser['tel'] = this.formUserEdit.controls.tel.value;
      listUser['status'] = this.userStatus;
      listUser['provider'] = this.privateUser;
      listUser['updateBy'] = this.userProfile.userId;
      // console.log('listUser',listUser)
      this.http.editUser(listUser).subscribe(data => {
        this.mapEditUser(data)
      });
    } else if (this.mode === 'RESETPASS') {
      const listMail = new Object;
      listMail['email'] = this.email;
      listMail['firstName'] = this.firstName;
      this.http.resetPassword(listMail).subscribe(data => {
        this.mapPassword(data);
      });
    }

  }
  mapEditUser(data) {
    this.moduleLoading = false;
    // console.log('data',data)
    if (data.code == '-100') {
      this.msgBox = data.message;
      this.modalService.open(this.saveSuccess, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    } else if (data.code == '100') {
      this.getlistUser();
      this.msgBox = 'ระบบทำการบันทึกเรียบร้อย';
      this.modalService.open(this.saveSuccess, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    }
  }
  mapPassword(data) {
    this.http.sendResetPasswordMail(data).subscribe();
    // console.log('data',data)
    this.moduleLoading = false;
    // console.log('pass >>',data)
    this.msgBox = 'ทำการรีเซ็ตรหัสผ่านและส่งอีเมล์ให้ผู้ใช้งานเรียบร้อย';
    this.msgBoxNewPassword = data.newPassword;
    this.modalService.open(this.saveSuccess, { backdrop: 'static', windowClass: 'myCustomModalClass' });
  }
  onChange(event, type) {
    const key = event.key;
    if (type === 'NUMBER') {
      if (!key.match('^-?(0|[1-9]\d*)?$')) {
        event.preventDefault();
      }
    } else if (type === 'TEXT') {
      if (!key.match('^[a-zA-Z]')) {
        event.preventDefault();
      }
    }
  }
  resetPasswordModel() {
    this.mode = 'RESETPASS';
    this.msgDescription = 'ยืนยันการรีเซ็ตรหัสผ่าน';
    this.modalService.open(this.saveValid, { backdrop: 'static', windowClass: 'myCustomModalClass' });
  }
  clearCriteria() {
    this.formUserSearch.controls['providerName'].setValue("");
    this.filterlistUsers = [];
    this.listUsers = [];
    this.emailClass = 'form-control';
    this.userEmail = '';
    this.userEmailMsg = '';
  }
}
