import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModal } from 'src/app/shared/class/ConfirmModal';
import { MessageModal } from 'src/app/shared/models/message-modal.modal';

@Component({
  selector: 'app-vrgo-admin-modal',
  templateUrl: './vrgo-admin-modal.component.html',
  styleUrls: ['./vrgo-admin-modal.component.scss'],
  animations: [SharedAnimations]
})
export class VRGoAdminModalComponent implements OnInit {

  @ViewChild('modalConfirm', { static: false }) modalConfirm;
  @ViewChild('modalMessage', { static: false }) modalMessage;

  @Output() messageEvent = new EventEmitter<any>();

  message: MessageModal;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  openConfirmModal(confirm :ConfirmModal){
    console.log(JSON.stringify(confirm));
    const event = new Object();
    this.modalService.open(this.modalConfirm).result.then((result) => {
      console.log(result);
        event['event'] = result;
    }, (reason) => {
      console.log('Err!', reason);
      event['event'] = 'error';
    });
    this.messageEvent.emit(JSON.stringify(event));
  }

  openMessageModal(message :MessageModal){
    console.log(JSON.stringify(message));
    this.message = message;
    const event = new Object();
    this.modalService.open(this.modalMessage,{windowClass:"message-modal"}).result.then((result) => {
      console.log(result);
        event['event'] = result;
    }, (reason) => {
      console.log('Err!', reason);
      event['event'] = 'error';
    });
    this.messageEvent.emit(JSON.stringify(event));
  }

}
