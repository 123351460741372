export class orderPayment {
    orderNumber :any;
	orderId:any;
	invoiceNo:any;
	netAmt:any;
	vat:any;
	address:any;
	province:any;
	amphure:any;
	district:any;
	zipcode:any;
	country:any;
	paymentDate:any;
	paymentMethod:any;
	ticketId:any;
	eventId:any;
	imageSlip:any;
	status:any;
	price:any;
	firstName:any;
	lastName:any;
	statusDesc:any;
    uploadDate:any;
    updateBy:any;
	eventName:any;
	ticketName:any;
	fullName:any;
	approveBy:any;
	approveName:any;
	createBy:any;
	amphureCode:any;
	provinceCode:any;
    orderDetailId:any;
	updateDate:any;
	tel:any;
	remark:any;
	createDate:any;

	listOrderObjectModel : any = [];
	
}