import { CouponCodeModel } from './coupon-code.model';
import { CouponTicketModel } from './coupon-ticket.model';
export class CouponModel {
    couponId : number;
	eventId : number;
	ticketId : number;
	couponType : string;
	couponTypeDesc : string;
	couponMode: string;
	couponModeDesc : string;
	unitRateDesc : string;
	prefix: string;
	suffix: string;
	discountRate : number;
	unitRate: string;
	maxRegister : number;
	qty : number;
	startDate: string;
	expireDate: string;
	status: string;
	descTh: string;
	descEn: string;
	createDate: string;
	createBy: string;
	updateDate: string;
	updateBy: string;
	couponCode: string;
	suffixLength : number;
	saveMode: string;
	actionBy: string;
	listCouponCode : CouponCodeModel [] = [];
	listCouponTicket : CouponTicketModel [] = [];
}