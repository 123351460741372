import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EBibECertConfig } from 'src/app/shared/models/ebib-ecert-config.model';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { VRGoAdminModalComponent } from '../vrgo-admin-modal/vrgo-admin-modal.component';
import { MessageModal } from 'src/app/shared/models/message-modal.modal';

@Component({
  selector: 'app-vrgo-admin-create-config-ecert',
  templateUrl: './vrgo-admin-create-config-ecert.component.html',
  styleUrls: ['./vrgo-admin-create-config-ecert.component.scss']
})
export class VrgoAdminCreateConfigEcertComponent implements OnInit {
  @ViewChild(VRGoAdminModalComponent, { static: false }) vrgoAdminModal: VRGoAdminModalComponent;

  eventId : number;
  ticketId : number;
  eventName : string;
  ticketName : string;
  transactionType : string;
  configId : number;

  formConfig : FormGroup;
  ecertConfig : EBibECertConfig;
  imageUrl : string = 'assets/images/empty_background.jpg';

  fileToUpload : File = null;
  fileMessage : string;

  submited : boolean = false;

  constructor(
    private route: ActivatedRoute,
    private httpClient : HttpClientService,
    private router : Router
  ) { }

  get ebibForm() { return this.formConfig.controls; }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.eventId = routeParams.eventId;
      this.ticketId = routeParams.ticketId;
    });

    this.route.data.subscribe(data =>{
      this.transactionType  = data.transactionType.toString().toUpperCase();
    });

    this.initialForm();
    this.bindingDataToForm();
  }

  initialForm(){
    this.formConfig = new FormGroup({
      templateImg : new  FormControl("empty_background.jpg", Validators.required),
      fullName : new FormControl("xxxxxx xxxxxx", Validators.required),
      nameFontSize : new FormControl(60, Validators.required),
      nameFontColor : new FormControl('#000000', Validators.required),
      nameGravityType : new FormControl('center', Validators.required),
      namePositionX : new FormControl(0, Validators.required),
      namePositionY : new FormControl(100, Validators.required)
    });
  }

  bindingDataToForm(){
      let params = new FormData();
      params.append('eventId',String(this.eventId));
      params.append('ticketId', String(this.ticketId));
      this.httpClient.getListEcertConfig(params).subscribe(response => {
        if(response != null){
          this.ecertConfig = new EBibECertConfig();
          this.ecertConfig = response[0];
          this.configId = this.ecertConfig.configId;
          this.eventName = this.ecertConfig.eventName;
          this.ticketName = this.ecertConfig.ticketName;
          
          if(this.transactionType == 'EDIT'){
            this.formConfig.controls['templateImg'].setValue(this.ecertConfig.templateImg);
            this.formConfig.controls['nameFontSize'].setValue(this.ecertConfig.nameFontSize);
            this.formConfig.controls['nameFontColor'].setValue(this.ecertConfig.nameColor);
            this.formConfig.controls['nameGravityType'].setValue(this.ecertConfig.nameTextAlign);
            this.formConfig.controls['namePositionX'].setValue(this.ecertConfig.namePositionX);
            this.formConfig.controls['namePositionY'].setValue(this.ecertConfig.namePositionY);
          }
        }
        
        this.preview();
      });
  }

  preview(){

    this.getFormData();

    this.imageUrl = 'assets/images/empty_background.jpg';
    this.httpClient.previewEcert(this.ecertConfig).subscribe(response => {
      this.imageUrl = response.imageUrl;
    });
  }

  getFormData(){

    this.ecertConfig = new EBibECertConfig();
    this.ecertConfig.configId = this.configId;
    this.ecertConfig.templateImg = this.formConfig.value['templateImg'];
    this.ecertConfig.fullName = this.formConfig.value['fullName'];
    this.ecertConfig.nameFontSize = this.formConfig.value['nameFontSize'];
    this.ecertConfig.nameColor = this.formConfig.value['nameFontColor'];
    this.ecertConfig.nameTextAlign = this.formConfig.value['nameGravityType'];
    this.ecertConfig.namePositionX = this.formConfig.value['namePositionX'];
    this.ecertConfig.namePositionY = this.formConfig.value['namePositionY'];

  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
      if(this.fileToUpload.type == "image/jpeg"){

        this.formConfig.controls['templateImg'].setValue(this.fileToUpload.name);
        if(this.formConfig.value['templateImg'] != ""){
          
          let formData: FormData = new FormData();
          formData.append('file', this.fileToUpload, this.fileToUpload.name);

          this.httpClient.uploadImageEcert(formData).subscribe(response => {
            this.formConfig.controls['templateImg'].setValue(response.templateImg);
            this.preview();
          });
        }

      }else{
        this.fileMessage = 'ชนิดของไฟล์รูปไม่ถูกต้อง ชนิดไฟล์รูปต้องเป็น JPG';
      }
  }

  submit(){
    this.submited = true;
    if(this.formConfig.status === 'VALID'){
      
      this.getFormData();
      this.ecertConfig.eventId = this.eventId;
      this.ecertConfig.ticketId = this.ticketId;

      this.httpClient.saveConfigEcert(this.ecertConfig).subscribe(response => {
        console.log(response);
        if(response != null){
          const messageError = new MessageModal;
          messageError.title = 'แจ้งเตือน';
          messageError.description = 'บันทึก Config E-Certificate สำเร็จ';
          this.vrgoAdminModal.openMessageModal(messageError);
        }
      });

    }
  }

  clear(){
    this.initialForm();
    this.bindingDataToForm();
  }

  afterCallModal(event){
    this.router.navigate(['vrgoAdmin/ecert/edit/' + this.eventId + '/' + this.ticketId ]);
  }
}
