export class TicketPriceModel {
	ticketId : any ; 
	ticketPriceId  : any ; 
	priceType  : any ;
	desctiption  : any ;
	startDate  : any ;
	endDate  : any ;
	price  : any ;
	platformFee  : any ;
	paymentGatewayFee  : any ;
	vat  : any ;
	status  : any ;
	createDate  : any ;
	createBy  : any ;
	updateDate  : any ;
	updateBy  : any ;
}