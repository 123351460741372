import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtResponse } from '../authentication/jwt-response';
import { AuthLoginInfo } from '../authentication/login-info';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  productionMode: boolean = true;

  constructor(
    private httpClient: HttpClient
  ) {}
  authenticate(credentials: AuthLoginInfo): Observable<JwtResponse> {
    if (this.productionMode)
      return this.httpClient.post<JwtResponse>('/authenticate', credentials, httpOptions);
    else
      return this.httpClient.post<JwtResponse>('http://localhost:8003/authenticate', credentials, httpOptions);
  }

  logOut() {
    sessionStorage.removeItem('username')
  }
}