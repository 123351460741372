import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EbadgeConfig } from 'src/app/shared/models/ebadge-config.model';
import { VRGoAdminModalComponent } from '../vrgo-admin-modal/vrgo-admin-modal.component';
import { MessageModal } from 'src/app/shared/models/message-modal.modal';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-vrgo-admin-update-track-number',
  templateUrl: './vrgo-admin-update-track-number.component.html',
  styleUrls: ['./vrgo-admin-update-track-number.component.scss']
})
export class VrgoAdminUpdateTrackNumberComponent implements OnInit {
  @ViewChild('saveSuccess',{static:false})saveSuccess;
  
  formConfig : FormGroup;
 
  imageCompleteNameUrl : string = 'assets/images/icon/plus.png';
 
  fileArrayUpload: Array<File> = [];
  fileToUpload : File = null;
  fileMessage : string;
  templateImgCompleteClass : string = 'form-control'
  submited : boolean = false;
  errorMsg;
  constructor(
    private route: ActivatedRoute,
    private httpClient : HttpClientService,
    private router : Router,
    private modalService: NgbModal
    
  ) { }

  ngOnInit() {

    this.initialForm();
  }

  initialForm(){
    this.formConfig = new FormGroup({
      configId : new FormControl('0'),
      templateImgComplete : new  FormControl('', Validators.required),
      status : new FormControl("Active", Validators.required)
    });
  }

  handleFileInputComplete(files: any) {
    console.log('files',files);

    this.fileToUpload = files.target.files[0];
    var name = this.fileToUpload.name;
    if(name.substring(name.lastIndexOf('.') + 1) == 'xlsx' || name.substring(name.lastIndexOf('.') + 1) == 'xls' || name.substring(name.lastIndexOf('.') + 1) == 'csv')  {
      this.templateImgCompleteClass = 'form-control';
      this.errorMsg = '';
      this.formConfig.controls.templateImgComplete.setValue(name);
    }else{
      this.templateImgCompleteClass = 'form-control text-error';
      this.errorMsg = 'กรุณาอัพโหลดไฟล์ที่เป็น Excel เท่านั้น';
    }
    
  }

  inputFile(){
    document.getElementById('but').click();
  }
  submit(){
    console.log('submit')
    this.submited = true;
    if(this.formConfig.status === 'VALID'){
      
      let mainForm: FormData = new FormData();
      mainForm.append(this.fileToUpload.name, this.fileToUpload);
      console.log('mainForm',mainForm);
      this.mapSubmit("");
      this.httpClient.uploadTrackingNum(mainForm).subscribe(response => {
       
      });
    }else{
      this.templateImgCompleteClass = 'form-control text-error';
      this.errorMsg = 'กรุณา Upload VR Summary';
    }
  }
  mapSubmit(response){
    this.modalService.open(this.saveSuccess,{backdrop:'static',windowClass:'myCustomModalClass'});
  }

  clear(){
    this.initialForm();
    this.templateImgCompleteClass = 'form-control';
    this.errorMsg = '';
  }
  closeMadal(){
    this.modalService.dismissAll();
  }
  
}
