import {TicketPriceModel} from './ticketprice.model';
import {TicketFieldModel} from './ticket-field.model';

export class TicketModel {
    ticketId: any ; 
	eventId: any ; 
	ticketType: any ; 
	ticketName: any;
	description: any;
	startTime: any;
	cutoffLength: any;
	distance: any;
	ticketAmt: any;
	status: any;
	statusDesc: any;
	addressStatus: any;
	createDate: any;
	createBy: any;
	updateDate: any;
	updateBy: any;
	price: any;
	priceType: any;
	unit: any;
	ticketObjectDescription: any;
	eventName: any;
	eventLocation: any;
	shippingStatus: any;
	shippingFee: any;
	additionalShippingFee: any;
	province: any;
	userId: any;
	eventRegisStartDate: any;
	eventRegisEndDate: any;
	priceDesc: any;
	approveStatus : any;
	submitStatus : any;

	
	listTicketTicketPrice : TicketPriceModel [] = [];
	listTicketTicketField : TicketFieldModel [] = [];
	listOrderDetail = [];
}