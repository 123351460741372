import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { FunctionUtils } from 'src/app/shared/utils/functionUtils';
import { orderPayment } from 'src/app/shared/models/order-payment.model';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Router} from '@angular/router';
import { ParameterUrl } from '../../shared/class/ParameterUrl';
import { EventModel } from '../../shared/models/event.model';

@Component({
  selector: 'app-vrgo-admin-search-events',
  templateUrl: './vrgo-admin-search-events.component.html',
  styleUrls: ['./vrgo-admin-search-events.component.scss']
})
export class VrgoAdminSearchEventsComponent implements OnInit {

  constructor(
    private service: HttpClientService,
    private fn: FunctionUtils,
    private modalOrderDetail: NgbActiveModal,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private modaladdUser: NgbActiveModal  ,
    private router :Router,
    private param : ParameterUrl
  ) { }


  formEvent: FormGroup;

  eventName: string = '';
  eventNameClass: string = 'form-control';
  eventNameErrorMsg: string = '';

  startDate: string = '';
  startDateClass: string = 'form-control';
  startDateClassErrorMsg: string = '';

  endDate: string = '';
  endDateClass: string = 'form-control';
  endDateClassErrorMsg: string = '';

  listEvent : EventModel;
  filteredListEvents ;
  ListEvents ;
  searchControl: FormControl = new FormControl();

  ngOnInit() {
    this.buildFormBasic();

    this.searchControl.valueChanges
    .pipe(debounceTime(200))
    .subscribe(value => {
      this.filerData(value);
    });

  }

  buildFormBasic() {
    this.formEvent = new FormGroup({
      eventName : new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),

    });
  }

  filerData(val) {
    if (val) {
      val = val.toLowerCase();
    } else {
      return this.filteredListEvents = [...this.ListEvents];
    }

    const columns = Object.keys(this.ListEvents[0]);
    if (!columns.length) {
      return;
    }

    const rows = this.ListEvents.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        const column = columns[i];
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });
    this.filteredListEvents = rows;
  }

  // orderDetail(orderNumber){
  //   for (let order of this.orderDetails) {
  //     if (orderNumber == order.orderNumber) {
  //       this.ordDetail = order;
  //       // console.log('aa', this.ordDetail.lastName)
  //     }
  //   }
  // }

  submit() {
    const listEvents = new EventModel ;
    listEvents['eventId'] = 0;
    listEvents['eventName'] =this.formEvent.value.eventName;
    listEvents['seq'] = 0;
    listEvents['eventRegisStartDate'] = this.fn.formatDate(this.formEvent.value.startDate);
    listEvents['eventRegisEndDate'] = this.fn.formatDate(this.formEvent.value.endDate);
    console.log('listEvents',listEvents);
    this.service.getlistEvent(listEvents).subscribe( data => {this.mapdata(data)
      // console.log('data',data)
      }); 
    }

  
  mapdata(data){
    // console.log('data', data)
    this.listEvent = data
    // this.search = true;
    this.ListEvents = data;
    this.filteredListEvents = data;
    // this.moduleLoading = false;
  }

  clearCriteria(){
    this.buildFormBasic();
    this.ListEvents = [];
    this.filteredListEvents = [];
    // window.location.reload();
  }
  viewCaregiver(eventId){
    console.log(eventId)
  }
  editEvent(eventId){
    this.param.data = {};
    this.param.data = this.listEvent
    this.param.data.eventId = eventId;
    // this.param.data.status = 'EDIT';
    console.log('ID',eventId);
    // this.router.navigate(['vrgoAdmin/eventDetail'] , {queryParams : {eventId: eventId} });
    this.router.navigate(['vrgoAdmin/eventDetail/edit/'+ eventId])
  }

  createEvent(){
    // this.param.data.status = 'ADD';
    this.router.navigate(['vrgoAdmin/eventDetail/add']);
  }


}
