import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { HttpClientModule} from '@angular/common/http';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EventSectionContent } from '../../shared/models/section-con.model';
import { EventSectionModel } from '../../shared/models/event-section.model';
import { EventSectionImage } from '../../shared/models/section-img.model';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { ParameterUrl } from '../../shared/class/ParameterUrl';
import { TokenStorageService } from 'src/app/shared/authentication/token-storage.service';

@Component({
  selector: 'app-vrgo-admin-section-detail',
  templateUrl: './vrgo-admin-section-detail.component.html',
  styleUrls: ['./vrgo-admin-section-detail.component.scss']
})
export class VrgoAdminSectionDetailComponent implements OnInit {

  @ViewChild ('saveValid',{static:false})saveValid;
  @ViewChild ('saveSuccess',{static:false})saveSuccess;
  @ViewChild('warnningValidate',{static:false})warnningValidate;
  constructor(
    private router : Router,
    private clientservice: HttpClientService,
    private route: ActivatedRoute,
    private formBuilder:FormBuilder,
    private modalService:NgbModal,
    private param: ParameterUrl,
    private tokenStored: TokenStorageService,
    // private angularEditorModule: AngularEditorModule
  ) { }

  mode:any
  sectionId : any;
  htmlEditter: any;
  defaultRTE: any;
  htmlContentWithoutStyles:any;
  htmlContent: any;
  htmlContentEng: any;
  search: any;
  editor1: any;
  replace: any;
  eventId : any;
  nameHtml: any;
  msgDescription : any ;
  listStatusImg : any;
  lengthCheckToaddMore = 0;
  msgError : any ='';
  deleteDocumentFile: Array<Object> = [];
  uploadFlag: boolean = false;
  totalfiles: Array<File> = [];
  totalFileName = [];
  isDisabled = true;

  submitted : boolean = false;

  formSection : FormGroup;
  imgContentImg : FormGroup;

  
  listSectionContent : EventSectionContent []=[];
  listSectionImage : EventSectionImage [] = [];
  eventSectionContentId : EventSectionContent;
  eventSectionId:EventSectionContent;
  seq : EventSectionContent ;
  contentStatus : EventSectionContent ;
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman',},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['undo','redo', 'subscript','superscript','strikeThrough','heading','fontName','insertUnorderedList','italic',],
      ['fontSize','customClasses','insertImage','insertVideo','removeFormat']
    ]
};


  ngOnInit() {
    this.buildFormSection();
    this.buildFormImageContent();
    this.getEventImgStatus();
    // console.log('eventId',this.param.data)
    this.route.params.subscribe(routeParams => {
      this.eventSectionId = routeParams.sectionId;
      // console.log('sectionId',  routeParams);
    });
    this.route.data.subscribe(data => {
      // console.log('log >>',data)
      this.mode = data.transactionType.toString();
      // console.log('transactionType : ' + this.mode);
      if (this.mode.toUpperCase() === 'EDIT') {
        // console.log('ID',this.eventSectionId)
        this.clientservice.getContentById(this.eventSectionId).subscribe( data =>
          this.mapDataResponse(data))
      }
      
      if (this.mode.toUpperCase() === 'ADD') {
        this.eventId = this.tokenStored.getEventId();
        if(this.param.data !== undefined && this.param.data !== null && this.param.data !== ''){
          this.eventId = this.param.data.eventId;
          this.tokenStored.saveEventId(this.eventId);
        }
      }
    });
  }
  ////////////////////////////////  SET SECTION /////////////////////////////////
  mapDataResponse(data){
    // console.log('data >> ',data);
    this.listSectionContent = data.listSectionContent ;
    this.listSectionImage = data.listSectionImage ;
    this.formSection.controls['detailHeader'].setValue(data.detailHeader);
    // console.log('detailHeader >> ',this.formSection.controls['detailHeader'].value);
    this.formSection.controls['detailHeaderEn'].setValue(data.detailHeaderEn);
    this.formSection.controls['mainHeader'].setValue(data.mainHeader);
    this.formSection.controls['mainHeaderEn'].setValue(data.mainHeaderEn);
    this.formSection.controls['sectionName'].setValue(data.sectionName);
    for(let i=0; i < this.listSectionContent.length; i++){
      // data.listSectionContent[i].content
      // console.log('data',this.listSectionContent[i].content) ;
      this.htmlContent = this.listSectionContent[i].content;
      this.htmlContentEng = this.listSectionContent[i].contentEn
      this.eventId = this.listSectionContent[i].eventId;
      this.eventSectionContentId = this.listSectionContent[i].eventSectionContentId;
      this.eventSectionId = this.listSectionContent[i].eventSectionId;
      this.seq = this.listSectionContent[i].seq;
      this.contentStatus = this.listSectionContent[i].status;
    }
    if(this.listSectionImage.length > 0){
      let indexImg = 0;
      this.imgItemContent.clear();
      this.imgItemContent.removeAt(indexImg = 0);
      for(let i=0; i<this.listSectionImage.length; i++){
        this.imgItemContent.insert(indexImg, this.formBuilder.group({
          eventSectionImageId:this.listSectionImage[i].eventSectionImageId,
          eventSectionId:this.listSectionImage[i].eventSectionId,
          fileId: 0,
          status :this.listSectionImage[i].status,
          imageName : this.listSectionImage[i].imageName,
          imageTitle :  this.listSectionImage[i].imageTitle,
          imageDesc :  this.listSectionImage[i].imageDesc,
          isChecked: true,
          
        }));
        indexImg = indexImg + 1;
      }
    }else{
      this.buildFormImageContent();
    }
  }
  ////////////////////////////////  END SECTION /////////////////////////////////////////////////

  ////////////////////////////////  FORM   /////////////////////////////////////////////////
  // getHtmlThai() {
  //   this.htmlEditter = document.getElementById("htmlDiv").innerHTML;
  // }
  buildFormSection() {
    this.formSection = this.formBuilder.group({
      detailHeader: new FormControl(''),
      detailHeaderEn: new FormControl(''),
      mainHeader: new FormControl(''),
      mainHeaderEn: new FormControl(''),
      sectionName: new FormControl('', [Validators.required]),

    });
  }
  buildFormImageContent(){
    this.imgContentImg = this.formBuilder.group({
      eventSectionImageId:new FormControl(''),
      eventSectionId:new FormControl(''),
      fileId :new FormControl(''),
      status :new FormControl(''),
      imageName :new FormControl(''),
      imageTitle :new FormControl(''),
      imageDesc : new FormControl(''),
      isChecked :new FormControl({ value: '', disabled: false },Validators.requiredTrue),
      imgItemContent: this.formBuilder.array([this.createUploadImgEvents()])

    });
  }
  createUploadImgEvents(){
    return this.formBuilder.group({
      eventSectionImageId:[''],
      eventSectionId:[''],
      fileId :[''],
      status :[''],
      imageName :[''],
      imageTitle :[''],
      imageDesc : [''],
      isChecked: [''],
      documentFile: File
    });
  }
  get imgItemContent(): FormArray {
    return this.imgContentImg.get('imgItemContent') as FormArray;
  };
  get editForm() { return this.formSection.controls; }
////////////////////////////////  FORM   /////////////////////////////////////////////////

///////////////////////////////////////////// UPLOAD IMG /////////////////////////////////////////////////
  addItem(): void {
    var uploadValidate = true;
    for (let i = 0; this.imgContentImg.controls.imgItemContent['controls'].length > i; i++) {
      if (this.imgContentImg.controls.imgItemContent['controls'][i].status == "INVALID" ||
        this.imgContentImg.controls.imgItemContent['controls'][i].value.imageName == undefined || 
         this.imgContentImg.controls.imgItemContent['controls'][i].value.imageName == "") {
        var uploadValidate = false;
        break;
      }
    }
    if (uploadValidate) {
      this.imgItemContent.insert(this.imgItemContent.length + 1, this.createUploadImgEvents())
      this.lengthCheckToaddMore = this.lengthCheckToaddMore + 1;
    } else {
      this.msgError = 'กรุณากรอกข้อมูลให้ครบถ้วน';
      this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: "myCustomModalClass" });
    }
  }
  uploadValidate(index) {
    // console.log('uploadValidate',index);
    // console.log('form',this.imgContentImg.controls.imgItemContent['controls'][index].controls.imageType.value);
    
    this.uploadFlag = true;
    if (this.imgContentImg.controls.imgItemContent['controls'][index].controls.imageTitle.value === ""|| this.imgContentImg.controls.imgItemContent['controls'][index].controls.imageDesc.value === "" || this.imgContentImg.controls.imgItemContent['controls'][index].controls.status.value === "") {
      this.msgError = 'กรุณาระบุข้อมูลให้ครบ';
      this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: "myCustomModalClass" });
    } else if(this.imgContentImg.controls.imgItemContent['controls'][index].controls.isChecked.value === false){
      this.msgError = 'กรุณาเปิดการแก้ไข';
      this.modalService.open(this.warnningValidate, { backdrop: 'static', windowClass: "myCustomModalClass" });
    } else {
      document.getElementById(index).click();
    }
  }
  deleteDocFile(index, contentDetailImageId, fileId) {
    // console.log('s',contentDetailImageId);
    const contentImg = new EventSectionImage;
    contentImg['eventSectionImageId'] = contentDetailImageId ;
    if (contentDetailImageId != '' && fileId != '') {
      let obj = {
        'contentDetailImageId': contentDetailImageId,
        'fileId': fileId
      }
      this.deleteDocumentFile.push(obj);
    }
    if(contentImg['eventSectionImageId'] != 0){
      this.clientservice.deleteSectionEventImage(contentDetailImageId).subscribe(data => {
        // this.mapDelData(data)
      })
    }
    this.imgItemContent.removeAt(index);
  }

  fileSelectionEvent(fileInput: any, oldIndex: number ) {
    // console.log('oldIndex', oldIndex);
    // console.log('fileInput', fileInput);
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      if (oldIndex == 0) {
        this.totalfiles.unshift((fileInput.target.files[0]))
        this.totalFileName.unshift(fileInput.target.files[0].name)
      }
      else {
        this.totalfiles[oldIndex] = (fileInput.target.files[0]);
        this.totalFileName[oldIndex] = fileInput.target.files[0].name
      }

      this.imgItemContent.value[oldIndex].imageName = fileInput.target.files[0].name;
      this.imgContentImg.controls.imgItemContent['controls'][oldIndex].controls.imageName.value =fileInput.target.files[0].name;
 
          
      reader.readAsDataURL(fileInput.target.files[0]);
    }

    if (this.totalfiles.length == 1) {
      this.lengthCheckToaddMore = 1;
    }
    // console.log('imgItemContent >>',this.imgItemContent.value[oldIndex].imageName)
  }
  editImgEvent(index,event){
    
    // console.log('imgItemContent >>',this.imgItemContent.value[index].imageName)
    if(this.imgContentImg.controls.imgItemContent['controls'][index].controls.isChecked.value === true){
       this.imgContentImg.controls.imgItemContent['controls'][index].controls.imageTitle.enable();
       this.imgContentImg.controls.imgItemContent['controls'][index].controls.imageDesc.enable();
       this.imgContentImg.controls.imgItemContent['controls'][index].controls.status.enable();
      // console.log('enable')
    }else{
      this.imgContentImg.controls.imgItemContent['controls'][index].controls.imageTitle.disable();
      this.imgContentImg.controls.imgItemContent['controls'][index].controls.imageDesc.disable();
      this.imgContentImg.controls.imgItemContent['controls'][index].controls.status.disable();
      
      // this.isDisabled = true;
    }
    
  }

   ///////////////////////////////////////////// END UPLOAD IMG /////////////////////////////////////////////////
 
  ///////////////////////////////////////////// GET /////////////////////////////////////////////////
  getEventImgStatus() {
    this.clientservice.getListBoxByGroup('EVENT_IMG_STATUS').subscribe((items) => {
      this.listStatusImg = items;
    });
  }

  ///////////////////////////////////////////// SAVE ////////////////////////////////////////////////
  valid(){
    this.submitted = true;
    if(this.formSection.status == 'VALID'){
      this.msgDescription = 'ยืนยันการแก้ไขข้อมูล';
      this.modalService.open(this.saveValid ,{backdrop:'static',windowClass:'myCustomModalClass'});
    }
  }

  editContentHtml(){
    const section = new EventSectionModel;
    let AllFilesObj = [];
    let main_form: FormData = new FormData();
      for (let j = 0; j < this.totalfiles.length; j++) {
        // console.log('log',this.totalfiles[j])
        main_form.append(this.totalFileName[j], <File>this.totalfiles[j]);
      }
      this.imgContentImg.value.imgItemContent.forEach((element, index) => {
        let eachObj =
        {
          'eventId':  (this.eventId|| 0),
          'eventSectionId': this.eventSectionId || 0,
          'eventSectionImageId': element.eventSectionImageId || 0,
          'imageName': element.imageName,
          'status': element.status,
          'imageTitle': element.imageTitle,
          'imageDesc': element.imageDesc,
        }
        AllFilesObj.push(eachObj);
      });
    section['eventId'] = this.eventId;
    section['eventSectionId'] = (this.eventSectionId || 0);
    section['sectionName'] = this.formSection.controls['sectionName'].value;
    section['mainHeader'] = this.formSection.controls['mainHeader'].value;
    section['mainHeaderEn'] = this.formSection.controls['mainHeaderEn'].value;
    section['detailHeader'] = this.formSection.controls['detailHeader'].value;
    section['detailHeaderEn'] = this.formSection.controls['detailHeaderEn'].value;
    for (let j = 0; j < AllFilesObj.length; j++) {
          
      // console.log('fileName', AllFilesObj[j].imageName);
      if (AllFilesObj[j].imageName !== undefined && AllFilesObj[j].imageName !== "") {
        section['listSectionImage'] = AllFilesObj;
      } else {
        this.imgItemContent.removeAt(0);
        section['listSectionImage'] = [];
      }
    }
    // console.log('section >>',section);
    // if(this.mode == 'ADD'){
      this.clientservice.editSection(section).subscribe( data =>
        this.mapEditSection(data));
      this.clientservice.saveImageEventSection(main_form).subscribe(data =>
        data);
    // }else{
    //     this.clientservice.editSectionContent(content).subscribe( data =>
    //     this.mapEditContent(data));
    //     this.clientservice.editSection(section).subscribe( data =>
    //     this.mapEditSection(data));
    //     this.clientservice.saveImageEventSection(main_form).subscribe(data =>
    //     data);
    // }
    
   
  }
  mapEditSection(data){
    const content = new EventSectionContent;
    this.eventSectionId = data.eventSectionId
    // console.log('eventSectionId >>',this.eventSectionId);
    // if(this.mode == 'ADD'){
      content['content'] = document.getElementById("htmlDiv").innerHTML;
      content['contentEn'] = document.getElementById("htmlDivEng").innerHTML;
      content['eventId'] = this.eventId;
      content['eventSectionContentId'] = (this.eventSectionContentId || 0);
      content['eventSectionId'] = (this.eventSectionId || 0);
      content['seq'] = (this.seq || 0);
      content['status'] = (this.contentStatus || 'ACTIVE');
      this.clientservice.editSectionContent(content).subscribe( data =>
      this.mapEditContent(data));
    // }else{
      
    // }
  }
  mapEditContent(data){
    // console.log('EDIT Content RES >>',data);
    this.htmlContent = data.content ;
    this.router.navigate(['vrgoAdmin/sectionDetail/edit/'+ this.eventSectionId]);
    this.modalService.dismissAll();
    this.modalService.open(this.saveSuccess,{backdrop:'static',windowClass:'myCustomModalClass'});
    // console.log('EDIT Section RES >>',data);
  }
  goBack() {
    this.router.navigate(['vrgoAdmin/eventDetail/edit/'+ this.eventId]);
  }
}
