import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { FunctionUtils } from 'src/app/shared/utils/functionUtils';
import { orderPayment } from 'src/app/shared/models/order-payment.model';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from '../../shared/authentication/token-storage.service';

@Component({
  selector: 'app-vrgo-admin-payment-pre-approve',
  templateUrl: './vrgo-admin-payment-pre-approve.component.html',
  styleUrls: ['./vrgo-admin-payment-pre-approve.component.scss']
})
export class VRGoAdminPaymentPreApproveComponent implements OnInit {

  @ViewChild('SuccessMessage',{static: false}) SuccessMessage;

  searchControl: FormControl = new FormControl();
  moduleLoading: boolean = false;
  search: boolean = false;
  preApprovePayment: orderPayment[];
  filteredPreApprovePayment: orderPayment[];
  
  orderDetails : any ;
  ordDetail;
  APPROVE = 'APPROVE';
  REJECT = 'REJECT';
  fileToUpload: File = null;
  filename: string = '';
  uploaded : boolean = false;
  fileData: File = null;
  userProfile : any ;
  userId : any;

  constructor(
    private service: HttpClientService,
    private fn: FunctionUtils,
    private modalOrderDetail: NgbActiveModal,
    private modalService: NgbModal,
    private tokenService : TokenStorageService
    ) { }

  ngOnInit() {
    this.userProfile = JSON.parse(this.tokenService.getUserProfile());
    // console.log('UserId',this.userProfile);
    this.userId = this.userProfile.userId;
    console.log('UserId',this.userId );
    this.searchControl.valueChanges
      .pipe(debounceTime(200))
      .subscribe(value => {
        this.filerData(value);
      });

      setTimeout(() => {
        this.getPreApprovePayment();
      }, 1000);
  }

  getPreApprovePayment(){
    this.moduleLoading = true;
    this.service.getOrderPayment('PENDING_APPROVE').subscribe((items) => {
      console.log(items);
      this.orderDetails = items
      this.search = true;
      this.preApprovePayment = items;
      this.filteredPreApprovePayment = items;
      this.moduleLoading = false;
    });
  }

  filerData(val) {
    if (val) {
      val = val.toLowerCase();
    } else {
      return this.filteredPreApprovePayment = [...this.preApprovePayment];
    }

    const columns = Object.keys(this.preApprovePayment[0]);
    if (!columns.length) {
      return;
    }

    const rows = this.preApprovePayment.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        const column = columns[i];
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });
    this.filteredPreApprovePayment = rows;
  }

  orderDetail(orderNumber){
    for (let order of this.orderDetails) {
      if (orderNumber == order.orderNumber) {
        this.ordDetail = order;
        console.log('aa', this.ordDetail.lastName)
        // this.netAmt = this.ordDetail.netAmt;
      }
    }
  }

  openOrderModel(content){
    this.modalOrderDetail = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'order-modal' });

  }
  closeModal(){
    this.modalService.dismissAll();
  }

  approveSlip(){
    this.service.approveSlip(this.ordDetail.orderNumber,this.APPROVE,this.userId ).subscribe(
      data =>  this.mapapproveSlip(data))
  }
  rejectSlip(){
    this.service.approveSlip(this.ordDetail.orderNumber,this.REJECT,this.userId ).subscribe(
      data =>  this.mapapproveSlip(data))
  }
  mapapproveSlip(data){
    data
   this.modalService.dismissAll();
   this.modalService.open(this.SuccessMessage,{ariaLabelledBy: 'modal-basic-title', windowClass: 'order-modal'})
  }

  onClickOk(){
    this.modalService.dismissAll();
    window.location.reload();
  }


}
