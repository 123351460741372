import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vrgo-admin-payment-pending',
  templateUrl: './vrgo-admin-payment-pending.component.html',
  styleUrls: ['./vrgo-admin-payment-pending.component.scss']
})
export class VRGoAdminPaymentPendingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
