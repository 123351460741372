export class UserProfiles {
    userId : any;
	email : any;
	firstName : any;
	middleName : any;
	lastName : any;
	password : any;
	tel : any;
	authType : any;
	newPassword : any;
	oldPassword : any;
	imageProfile : any;
	imageProfileUrl : any;
	validate : boolean;
	errorMessage : any;
	invalidUser : boolean;	
	provider : any;
	providerId : any;
	idCard  : any;
	birthDate  : any;
	gender  : any;
	address : any;
	province : any;
	amphure : any;
	district : any;
	zipcode : any;
	updateBy: any;
}