import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from '../shared/services/navigation.service';
import { SearchService } from 'src/app/shared/services/search.service';
import { Router, RouteConfigLoadStart, ResolveStart, RouteConfigLoadEnd, ResolveEnd } from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-vrgo-admin',
  templateUrl: './vrgo-admin.component.html',
  styleUrls: ['./vrgo-admin.component.scss']
})
export class VRGoAdminComponent implements OnInit {

    moduleLoading: boolean;
    @ViewChild(PerfectScrollbarDirective, { static: true }) perfectScrollbar: PerfectScrollbarDirective;
  
    constructor(
      public navService: NavigationService,
      public searchService: SearchService,
      private router: Router
    ) { }
  
    ngOnInit() {
      // console.log('main');
   
      this.router.events.subscribe(event => {
        if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
          this.moduleLoading = true;
        }
        if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
          this.moduleLoading = false;
        }
      });
    }

}
