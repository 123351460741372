import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { HttpClientModule } from '@angular/common/http';
import { VRGoAdminModule } from './vrgo/vrgo-admin.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { VRGoLoginComponent } from './vrgo/vrgo-admin-login/vrgo-admin-login.component';
import { httpInterceptorProviders } from './shared/authentication/auth-interceptor';
import { BsDatepickerModule ,DatepickerModule } from 'ngx-bootstrap';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';



@NgModule({
  declarations: [
    AppComponent,
    VRGoLoginComponent,
  ],
  imports: [
    BrowserModule,
    VRGoAdminModule,
    SharedModule,
    HttpClientModule,
    MatSlideToggleModule,
    BrowserAnimationsModule,
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    AppRoutingModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
  ],
  providers: [ httpInterceptorProviders, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
