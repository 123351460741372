import { Component, OnInit, HostListener } from '@angular/core';
import { NavigationService, IMenuItem, IChildItem } from '../../shared/services/navigation.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Utils } from '../../shared/utils';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { TokenStorageService } from 'src/app/shared/authentication/token-storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './vrgo-admin-sidebar.component.html',
  styleUrls: ['./vrgo-admin-sidebar.component.scss']
})
export class VRGoAdminSidebarComponent implements OnInit {

	selectedItem: IMenuItem;
	
	role: string = '';

	nav: IMenuItem [] = [];

	constructor(
		public router: Router,
		public navService: NavigationService,
		public service: HttpClientService,
		public tokenStored: TokenStorageService
	) { }

	ngOnInit() {
		this.updateSidebar();
		// CLOSE SIDENAV ON ROUTE CHANGE

		let cus= JSON.stringify(this.tokenStored.getUserProfile());
		let customerInfo = JSON.parse(cus);
		let customerInfoObject = JSON.parse(customerInfo);
		// console.log('customerInfoObject : '+JSON.stringify(customerInfoObject));
		this.role = customerInfoObject['roleId']
		console.log('role',this.role)
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((routeChange) => {
				this.closeChildNav();
				if (Utils.isMobile()) {
					this.navService.sidebarState.sidenavOpen = false;
				}
			});

		this.service.getMenu(this.role).subscribe((items) => {
			this.nav = items;
			// console.log('nav',this.nav)
			this.setActiveFlag();
		});
	}

	selectItem(item) {
		// this.navService.sidebarState.childnavOpen = true;
		this.selectedItem = item;
		this.setActiveMainItem(item);
	}
	closeChildNav() {
		this.navService.sidebarState.childnavOpen = false;
		this.setActiveFlag();
	}

	onClickChangeActiveFlag(item) {
		if(item.type == 'link'){
			this.setActiveMainItem(item);
		}else{
			this.navService.sidebarState.childnavOpen = true;
		}
	}
	setActiveMainItem(item) {
		this.nav.forEach(item => {
			item.active = false;
		});
		item.active = true;
	}

	setActiveFlag() {
		if (window && window.location) {
            const activeRoute = window.location.hash || window.location.pathname;
			this.nav.forEach(item => {
				item.active = false;
				if (activeRoute.indexOf(item.state) !== -1) {
                    this.selectedItem = item;
					item.active = true;
				}
				if (item.sub) {
					item.sub.forEach(subItem => {
                        subItem.active = false;
						if (activeRoute.indexOf(subItem.state) !== -1) {
                            this.selectedItem = item;
                            item.active = true;
                        }
                        if (subItem.sub) {
                            subItem.sub.forEach(subChildItem => {
                                if (activeRoute.indexOf(subChildItem.state) !== -1) {
                                    this.selectedItem = item;
                                    item.active = true;
                                    subItem.active = true;
                                }
                            });
                        }
					});
				}
            });
		}
    }

	updateSidebar() {
		if (Utils.isMobile()) {
			this.navService.sidebarState.sidenavOpen = false;
			this.navService.sidebarState.childnavOpen = false;
		} else {
			this.navService.sidebarState.sidenavOpen = true;
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.updateSidebar();
    }

}
