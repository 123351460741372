import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { FunctionUtils } from 'src/app/shared/utils/functionUtils';
import { orderPayment } from 'src/app/shared/models/order-payment.model';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { TokenStorageService } from '../../shared/authentication/token-storage.service';
import { EmailModel } from '../../shared/models/email.model';
import { T } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-vrgo-admin-orderpayment',
  templateUrl: './vrgo-admin-orderpayment.component.html',
  styleUrls: ['./vrgo-admin-orderpayment.component.scss']
})
export class VrgoAdminOrderpaymentComponent implements OnInit {

  @ViewChild('SuccessMessage', { static: false }) SuccessMessage;
  @ViewChild('errorMessage', { static: false }) errorMessage;
  @ViewChild('SuccesssendMail', { static: false }) SuccesssendMail;
  @ViewChild('sendValid', { static: false }) sendValid;

  searchControl: FormControl = new FormControl();
  moduleLoading: boolean = false;
  search: boolean = false;
  preApprovePayment: orderPayment[];
  filteredPreApprovePayment: orderPayment[];

  orderDetails: any;
  ordDetail;
  APPROVE = 'APPROVE';
  REJECT = 'REJECT';

  formBasic: FormGroup;
  listEventAll: any;
  statusListOrder: any;
  listTicketByEvent: any;

  eventId: number = 0;
  ticketId: number = 0;
  status: string = null;

  startDate: any;
  endDate: any;
  previewUrl: any = null;

  fileToUpload: File = null;
  filename: string = '';
  uploaded: boolean = false;
  fileData: File = null;
  fileDownloadName: any;

  selectName: string = '';
  selectNameClass: string = 'form-control select-form';
  selectNameClassErrorMsg: string = '';

  statusData = 'ACTIVE';
  btnsubmit: any = 'อนุมัติ';
  btnReject: any = 'ไม่อนุมัติ';
  currentLesson: any = '1';
  currentLessonMail: any = '1';
  currentLesson1: any = '1';
  currentLessonCancel: any = '1';
  userProfile: any;
  userId: any;
  mail: any = 'ส่งเมล์';
  btnCancel = 'ยกเลิกออเดอร์';
  msgDescription: any;

  submitted: boolean;

  constructor(
    private service: HttpClientService,
    private fn: FunctionUtils,
    private modalOrderDetail: NgbActiveModal,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private modaladdUser: NgbActiveModal,
    private router: Router,
    private tokenService: TokenStorageService
  ) { }

  ngOnInit() {

    this.userProfile = JSON.parse(this.tokenService.getUserProfile());
    // console.log('UserId',this.userProfile);
    this.userId = this.userProfile.userId;
    // console.log('UserId',this.userId );
    this.searchControl.valueChanges
      .pipe(debounceTime(200))
      .subscribe(value => {
        this.filerData(value);
      });

    this.buildFormBasic();
    // this.formBasic.controls['selectName'].setValue(-1);

    this.getStatusListOrder();
    this.getEventListOrder();

    this.ticketId = 0;
  }

  filerData(val) {
    if (val) {
      val = val.toLowerCase();
    } else {
      return this.filteredPreApprovePayment = [...this.preApprovePayment];
    }

    const columns = Object.keys(this.preApprovePayment[0]);
    if (!columns.length) {
      return;
    }

    const rows = this.preApprovePayment.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        const column = columns[i];
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });
    this.filteredPreApprovePayment = rows;
  }

  orderDetail(orderNumber) {
    console.log("orderNumber", orderNumber);

    for (let order of this.orderDetails) {
      if (orderNumber == order.orderNumber) {
        this.ordDetail = order;
        // console.log('Order Detail > ', this.ordDetail);
      }
    }
  }
  editOrderDetail(orderNumber) {
    this.router.navigate(['vrgoAdmin/order/edit/' + orderNumber])
  }
  getStatusListOrder() {
    this.service.getStatusListOrder().subscribe(data => { this.mapStatusListOrder(data) });
  }
  getEventListOrder() {
    this.service.getEventListOrder(this.userProfile.roleId).subscribe(data => { this.mapEevetListOrder(data) });

  }
  getTicketByEvent(event) {
    let eventId = event.target.value;
    this.eventId = eventId;
    // console.log('event = ', eventId);
    this.service.getTicketByEvent(eventId).subscribe(data => { this.mapTicketByEvent(data) });
  }

  mapTicketByEvent(data: any) {
    // console.log('ticket=',data);
    this.listTicketByEvent = data;
    this.ticketId = this.listTicketByEvent.ticketId
  }

  mapEevetListOrder(data: any) {

    this.listEventAll = data;

  }

  mapStatusListOrder(data: any) {
    this.statusListOrder = data;
  }

  buildFormBasic() {
    this.formBasic = new FormGroup({
      selectName: new FormControl(0),
      selectticketName: new FormControl(0),
      selectStatus: new FormControl(""),

    });
  }

  openOrderModel(content) {
    console.log(content);
    this.modalOrderDetail = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'order-modal', backdrop: 'static' });

  }
  closeModal() {
    this.previewUrl = null;
    this.modalService.dismissAll();
  }
  rejectSlip() {
    this.currentLesson1 = '2'
    this.btnReject = 'กรุณารอสักครู่...';
    console.log('REJECT', this.ordDetail.orderNumber, this.REJECT, this.userId)
    this.service.approveSlip(this.ordDetail.orderNumber, this.REJECT, this.userId).subscribe(
      data => this.mapapproveSlip(data))
  }

  approveSlip() {
    this.currentLesson = '2'
    this.btnsubmit = 'กรุณารอสักครู่...';
    this.service.approveSlip(this.ordDetail.orderNumber, this.APPROVE, this.userId).subscribe(
      data =>
        //  console.log('data',data)
        this.mapapproveSlip(data)
    )

  }
  sendMail() {
    this.moduleLoading = true;
    // const sendmail =  new EmailModel
    // sendmail['emailId'] = '1';
    // sendmail['orderNumber'] = this.ordDetail.orderNumber;
    this.currentLessonMail = '2';
    this.mail = 'กรุณารอสักครู่...';
    // console.log('orderNumber',sendmail)
    this.service.sendMail(this.ordDetail.orderNumber).subscribe(
      data =>
        //  console.log('data',data)
        this.mapSendMail(data)
    )
  }
  sendMailPopup() {
    this.msgDescription = 'ยืนยันการส่งอีเมล์ของท่าน';
    this.modalService.open(this.sendValid, { backdrop: 'static', windowClass: 'myCustomModalClass' })
  }
  mapSendMail(data) {
    this.moduleLoading = false;
    this.currentLessonMail = '1';
    this.mail = 'ส่งเมล์';
    this.modalService.open(this.SuccesssendMail, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    // console.log('data',data);
  }
  cancelOrder() {
    this.btnCancel = 'กรุณารอสักครู่...';
    this.currentLessonCancel = '2';
    this.service.cancelOrder(this.ordDetail.orderNumber, this.userId).subscribe(data => {
      this.mapCancelOrder(data);
    })
  }
  mapCancelOrder(data) {
    console.log(data);
    this.btnCancel = 'ยกเลิกออเดอร์';
    this.currentLessonCancel = '1';
    this.modalService.dismissAll();
    this.modalService.open(this.SuccessMessage, { ariaLabelledBy: 'modal-basic-title', windowClass: 'order-modal' })
  }
  approveSlipWithImage() {
    this.currentLesson = '2'
    this.btnsubmit = 'กรุณารอสักครู่...';
    // console.log('orderNumber',this.ordDetail.orderNumber)
    // console.log('APPROVE',this.APPROVE)
    // console.log('fileDownloadUri',this.fileDownloadName)
    this.uploaded = true;
    this.service.approveSlipWithImage(this.ordDetail.orderNumber, this.APPROVE, this.fileDownloadName, this.userId).subscribe(
      data =>
        //  console.log('data',data)
        this.mapapproveSlip(data)
    )

  }

  mapapproveSlip(data) {
    data = this.statusData
    this.modalService.dismissAll();
    this.uploaded = false;
    this.filename = "";
    this.service.sendConfirmPaymentMail(this.ordDetail.orderNumber).subscribe();
    this.modalService.open(this.SuccessMessage, { ariaLabelledBy: 'modal-basic-title', windowClass: 'order-modal' })
  }
  onClickOk() {
    this.currentLesson1 = '1';
    this.btnReject = 'ไม่อนุมัติ';
    this.btnsubmit = 'อนุมัติ';
    this.currentLesson = '1';
    this.preApprovePayment = [];
    this.filteredPreApprovePayment = [];
    this.selectNameClass = 'form-control select-form';
    this.selectNameClassErrorMsg = '';
    this.previewUrl = null;
    this.modalService.dismissAll();

  }
  getTicketId(event) {
    this.ticketId = event.target.value;
    // console.log('ticketId',this.ticketId);
  }
  getStatusByName(event) {
    this.status = event.target.value;
  }
  submit() {

    this.submitted = true;
    // console.log(this.formBasic.controls.selectStatus.value);

    if (this.formBasic.controls.selectStatus.value == "" && this.formBasic.controls.selectName.value == 0) {

      this.formBasic.controls.selectName.setErrors({ 'incorrect': true });

      // console.log("ต้องเลือก Event");


    } else if (this.formBasic.controls.selectName.value == 0 && this.formBasic.controls.selectStatus.value != "") {

      // console.log("ต้องเลือก PENDING_APPROVE");

      if (this.formBasic.controls.selectStatus.value != "PENDING_APPROVE") {

        this.formBasic.controls.selectName.setErrors({ 'incorrect': true });
        // console.log("ต้องเลือก event ด้วย", this.formBasic.controls.selectStatus.value);

      }else{
        // console.log("ผ่านได้", this.formBasic.controls.selectStatus.value);
        this.formBasic.controls.selectName.setErrors({ 'incorrect': null });
        this.formBasic.controls.selectStatus.setErrors({ 'incorrect': null });
        this.formBasic.controls.selectStatus.updateValueAndValidity();
        this.formBasic.controls.selectName.updateValueAndValidity();
      }

    }

    // console.log(this.formBasic.status);

    if (this.formBasic.status == "VALID") {
      this.service.getlistOrder(
        this.formBasic.controls.selectStatus.value,
        this.formBasic.controls.selectName.value,
        this.formBasic.controls.selectticketName.value,
        null,
        null).subscribe(
          data => this.mapdata(data)
        );

    }

  }

  mapdata(data) {
    // console.log('data >>>', data)

    this.orderDetails = data
    this.search = true;
    this.preApprovePayment = data;
    this.filteredPreApprovePayment = data;
    this.moduleLoading = false;
  }

  clearCriteria() {
    this.buildFormBasic();
    this.listTicketByEvent = [];
    this.preApprovePayment = [];
    this.filteredPreApprovePayment = [];
    this.selectNameClass = 'form-control select-form';
    this.selectNameClassErrorMsg = '';
    // window.location.reload();
  }
  afterCallModal(event) {
    this.modaladdUser.dismiss();
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.filename = this.fileToUpload.name;
    this.uploadSlip();
  }
  fileremove() {
    this.filename = null;
  }

  uploadSlip() {
    // console.log('data', this.ordDetail.orderNumber + 'and')
    // console.log(this.fileToUpload)
    if (this.filename == '') {
      this.onclickerror();
    } else {
      this.service.uploadimageSlip(this.fileToUpload, this.ordDetail.orderNumber, String(this.userId), String(this.ticketId)).subscribe(data => {
        // console.log(data);
        this.fileDownloadName = data['fileName'];
        if (data['fileDownloadUri'] != '') {
          this.uploaded = false;
          // this.previewUrl= null;
          // console.log('ok');
          // this.onclickSuccess();
        }
      }, error => {
        console.log(error);
      });
    }

  }
  onclickerror() {
    this.modalService.open(this.errorMessage, { backdrop: 'static' });
  }
  onclickSuccess() {
    this.modalService.open(this.SuccessMessage, { backdrop: 'static' });
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }
  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }
}



