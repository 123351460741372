import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VRGoAdminComponent } from './vrgo/vrgo-admin.component';
import { VRGoLoginComponent } from './vrgo/vrgo-admin-login/vrgo-admin-login.component';

export const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "vrgoAdmin",
    component: VRGoAdminComponent,
    loadChildren: "./vrgo/vrgo-admin.module#VRGoAdminModule",
    data: {
      breadcrumb: "vrgoAdmin"
    }
  },
  {
    path: "login",
    component: VRGoLoginComponent,
  },
  {
    path: "**",
    redirectTo: "login",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
