import { Component, OnInit } from '@angular/core';
import { SharedAnimations } from 'src/app/shared/animations/shared-animations';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { AuthLoginInfo } from 'src/app/shared/authentication/login-info';
import { TokenStorageService } from 'src/app/shared/authentication/token-storage.service';

@Component({
  selector: 'app-vrgo-login',
  templateUrl: './vrgo-admin-login.component.html',
  styleUrls: ['./vrgo-admin-login.component.scss'],
  animations: [SharedAnimations]
})
export class VRGoLoginComponent implements OnInit {
  loading: boolean;
  loadingText: string;
  loginInfo: AuthLoginInfo;
  username: string = '';
  password: string = '';
  form: any = {};

  moduleLoading: boolean;

  userNameModalClass: string = '';
  passwordModalClass: string = '';

  invalidLogin: boolean = (this.tokenStored.getUsername() == null ? false : true);
  LoginClassErrorMsg1: string = '';
  LoginClassErrorMsg2: string = '';
  LoginClassErrorMsg: string = '';

  constructor(
    private loginService: AuthenticationService,
    private tokenStored: TokenStorageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.tokenStored.removeSession();

    if (this.getBrowserName() == 'chrome') {
      console.log("chrome");
    } else {
      window.open(
        'https://admin.checkrace.com/login',
        '_blank' // <- This is what makes it open in a new window.
      );
    }
  }

  checkLogin() {
    this.loginInfo = new AuthLoginInfo(
      this.form.username = this.username,
      this.form.password = this.password
    );

    if (this.form.username === '') {
      this.userNameModalClass = 'form-control input-vrgo-err';
      this.LoginClassErrorMsg = '"* กรุณาระบุ ชื่อผู้ใช้งาน และ รหัสผ่าน"';
    } else {
      this.userNameModalClass = 'form-control input-vrgo';
      this.LoginClassErrorMsg = '';
    }
    if (this.form.password === '') {
      this.passwordModalClass = 'form-control input-vrgo-err';
      this.LoginClassErrorMsg = '"* กรุณาระบุ ชื่อผู้ใช้งาน และ รหัสผ่าน"';
    } else {
      this.passwordModalClass = 'form-control input-vrgo';
      this.LoginClassErrorMsg = '';
    }

    this.moduleLoading = true;

    (this.loginService.authenticate(this.loginInfo).subscribe(
      data => {
        console.log(data)

        if (data['invalidUser'] == true) {
          if (data['errorMessage'] == "INVALID_PASSWORD") {
            this.LoginClassErrorMsg1 = '   > รหัสผ่านของคุณไม่ถูกต้อง';
            this.LoginClassErrorMsg2 = '';
            this.invalidLogin = false;
          } else if (data['errorMessage'] == "INVALID_USER") {
            this.LoginClassErrorMsg2 = '   > ชื่อผู้ใช้งานของคุณไม่ถูกต้อง';
            this.LoginClassErrorMsg1 = '';
            this.invalidLogin = false;
          }
        } else {
          this.tokenStored.saveToken(data.token);
          this.tokenStored.saveUsername(data.username);
          let userProfile = JSON.stringify(data.userProfile);
          console.log(userProfile);
          this.tokenStored.saveUserProfile(userProfile);
          this.invalidLogin = true;
          this.router.navigate(['/vrgoAdmin/main']);
        }
        this.moduleLoading = false;
      }, error => {
        this.LoginClassErrorMsg = '"* ชื่อผู้ใช้งาน หรือ รหัสผ่าน ผิดพลาด"'
        this.invalidLogin = false;
        this.moduleLoading = false;
      }
    ))
  }

  public getBrowserName() {
    // const open = require('open');
    const agent = window.navigator.userAgent.toLowerCase()
    // console.log("agent >> ",agent);
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
      
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
    
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

}
