import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../shared/services/navigation.service';
import { SearchService } from '../../shared/services/search.service';
import { TokenStorageService } from 'src/app/shared/authentication/token-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-sidebar',
  templateUrl: './vrgo-admin-header-sidebar.component.html',
  styleUrls: ['./vrgo-admin-header-sidebar.component.scss']
})
export class VRGoAdminHeaderSidebarComponent implements OnInit {

  notifications: any[];
  firstName: string = '';
  lastName: string = '';
  imgProfile: string = '';

  constructor(
    private navService: NavigationService,
    public searchService: SearchService,
    private tokenStored: TokenStorageService,
    private router: Router
  ) {
  }

  ngOnInit() {
    let cus = JSON.stringify(this.tokenStored.getUserProfile());
    let customerInfo = JSON.parse(cus);
    let customerInfoObject = JSON.parse(customerInfo);
    this.imgProfile = customerInfoObject['profileImageUrl'];
    this.firstName = customerInfoObject['firstName'];
    this.lastName = customerInfoObject['lastName'];
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    if (state.childnavOpen && state.sidenavOpen) {
      return state.childnavOpen = false;
    }
    if (!state.childnavOpen && state.sidenavOpen) {
      return state.sidenavOpen = false;
    }
    if (!state.sidenavOpen && !state.childnavOpen) {
      state.sidenavOpen = true;
      setTimeout(() => {
        state.childnavOpen = true;
      }, 50);
    }
  }

  signout() {
    this.tokenStored.signOut();
    console.log('token : ' + this.tokenStored.getToken());
    if (this.tokenStored.getToken() == null) {
      this.router.navigate(['/lgoin']);
    }
  }

}
