export class FunctionUtils {
  formatDate(objDate) {
    if (objDate != '') {
      const year = objDate['year'];
      const month = this.pad(objDate['month'], 2);
      const day = this.pad(objDate['day'], 2);
      return year + '-' + month + '-' + day;
    }
    return '';
  }
  pad(num, size) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
}