export class EventTagModel {
	eventTagId : any;
	eventId : any;
	tagName : any;
	tagUrl : any;
	tagIcon : any;
	status : any;
	createDate : any;
	createBy : any;
	updateDate : any;
    updateBy : any;
}