import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EBibECertConfig } from 'src/app/shared/models/ebib-ecert-config.model';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { VRGoAdminModalComponent } from '../vrgo-admin-modal/vrgo-admin-modal.component';
import { MessageModal } from 'src/app/shared/models/message-modal.modal';

@Component({
  selector: 'app-vrgo-admin-create-config-ebib',
  templateUrl: './vrgo-admin-create-config-ebib.component.html',
  styleUrls: ['./vrgo-admin-create-config-ebib.component.scss']
})
export class VrgoAdminCreateConfigEbibComponent implements OnInit {

  @ViewChild(VRGoAdminModalComponent, { static: false }) vrgoAdminModal: VRGoAdminModalComponent;

  eventId : number;
  ticketId : number;
  eventName : string;
  ticketName : string;
  transactionType : string;
  configId : number;

  formConfig : FormGroup;
  ebibConfig : EBibECertConfig;
  imageUrl : string = 'assets/images/empty_background.jpg';

  fileToUpload : File = null;
  fileMessage : string;

  submited : boolean = false;

  constructor(
    private route: ActivatedRoute,
    private httpClient : HttpClientService,
    private router : Router
  ) { }

  get ebibForm() { return this.formConfig.controls; }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.eventId = routeParams.eventId;
      this.ticketId = routeParams.ticketId;
    });

    this.route.data.subscribe(data =>{
      this.transactionType  = data.transactionType.toString().toUpperCase();
    });

    this.initialForm();
    this.bindingDataToForm();
  }

  initialForm(){
    this.formConfig = new FormGroup({
      templateImg : new  FormControl("empty_background.jpg", Validators.required),
      fullName : new FormControl("xxxxxx xxxxxx", Validators.required),
      nameFontSize : new FormControl(60, Validators.required),
      nameFontColor : new FormControl('#000000', Validators.required),
      nameGravityType : new FormControl('center', Validators.required),
      namePositionX : new FormControl(0, Validators.required),
      namePositionY : new FormControl(100, Validators.required),
      bibNumber : new FormControl("12345", Validators.required),
      bibFontSize: new FormControl(200, Validators.required),
      bibFontColor : new FormControl('#000000', Validators.required),
      bibGravityType : new FormControl('center', Validators.required),
      bibPositionX : new FormControl(-10, Validators.required),
      bibPositionY : new FormControl(0, Validators.required)
    });
  }

  bindingDataToForm(){
      let params = new FormData();
      params.append('eventId',String(this.eventId));
      params.append('ticketId', String(this.ticketId));
      this.httpClient.getListEbibConfig(params).subscribe(response => {
        if(response != null){
          this.ebibConfig = new EBibECertConfig();
          this.ebibConfig = response[0];
          this.configId = this.ebibConfig.configId;
          this.eventName = this.ebibConfig.eventName;
          this.ticketName = this.ebibConfig.ticketName;
          
          if(this.transactionType == 'EDIT'){
            this.formConfig.controls['templateImg'].setValue(this.ebibConfig.templateImg);
            this.formConfig.controls['nameFontSize'].setValue(this.ebibConfig.nameFontSize);
            this.formConfig.controls['nameFontColor'].setValue(this.ebibConfig.nameColor);
            this.formConfig.controls['nameGravityType'].setValue(this.ebibConfig.nameTextAlign);
            this.formConfig.controls['namePositionX'].setValue(this.ebibConfig.namePositionX);
            this.formConfig.controls['namePositionY'].setValue(this.ebibConfig.namePositionY);
            this.formConfig.controls['bibFontSize'].setValue(this.ebibConfig.bibFontSize);
            this.formConfig.controls['bibFontColor'].setValue(this.ebibConfig.bibColor);
            this.formConfig.controls['bibGravityType'].setValue(this.ebibConfig.bibTextAlign);
            this.formConfig.controls['bibPositionX'].setValue(this.ebibConfig.bibPositionX);
            this.formConfig.controls['bibPositionY'].setValue(this.ebibConfig.bibPositionY);
          }
        }
        
        this.preview();
      });
  }

  preview(){

    this.getFormData();

    this.imageUrl = 'assets/images/empty_background.jpg';
    this.httpClient.previewEbib(this.ebibConfig).subscribe(response => {
      this.imageUrl = response.imageUrl;
    });
  }

  getFormData(){

    this.ebibConfig = new EBibECertConfig();
    this.ebibConfig.configId = this.configId;
    this.ebibConfig.templateImg = this.formConfig.value['templateImg'];
    this.ebibConfig.fullName = this.formConfig.value['fullName'];
    this.ebibConfig.nameFontSize = this.formConfig.value['nameFontSize'];
    this.ebibConfig.nameColor = this.formConfig.value['nameFontColor'];
    this.ebibConfig.nameTextAlign = this.formConfig.value['nameGravityType'];
    this.ebibConfig.namePositionX = this.formConfig.value['namePositionX'];
    this.ebibConfig.namePositionY = this.formConfig.value['namePositionY'];
    this.ebibConfig.bibNumber = this.formConfig.value['bibNumber'];
    this.ebibConfig.bibFontSize = this.formConfig.value['bibFontSize'];
    this.ebibConfig.bibColor = this.formConfig.value['bibFontColor'];
    this.ebibConfig.bibTextAlign = this.formConfig.value['bibGravityType'];
    this.ebibConfig.bibPositionX = this.formConfig.value['bibPositionX'];
    this.ebibConfig.bibPositionY = this.formConfig.value['bibPositionY'];

  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
      if(this.fileToUpload.type == "image/jpeg"){

        this.formConfig.controls['templateImg'].setValue(this.fileToUpload.name);
        if(this.formConfig.value['templateImg'] != ""){
          
          let formData: FormData = new FormData();
          formData.append('file', this.fileToUpload, this.fileToUpload.name);

          this.httpClient.uploadImageEbib(formData).subscribe(response => {
            this.formConfig.controls['templateImg'].setValue(response.templateImg);
            this.preview();
          });
        }

      }else{
        this.fileMessage = 'ชนิดของไฟล์รูปไม่ถูกต้อง ชนิดไฟล์รูปต้องเป็น JPG';
      }
  }

  submit(){
    this.submited = true;
    if(this.formConfig.status === 'VALID'){
      
      this.getFormData();
      this.ebibConfig.eventId = this.eventId;
      this.ebibConfig.ticketId = this.ticketId;

      this.httpClient.saveConfigEbib(this.ebibConfig).subscribe(response => {
        console.log(response);
        if(response != null){
          const messageError = new MessageModal;
          messageError.title = 'แจ้งเตือน';
          messageError.description = 'บันทึก Config Ebib สำเร็จ';
          this.vrgoAdminModal.openMessageModal(messageError);
        }
      });

    }
  }

  clear(){
    this.initialForm();
    this.bindingDataToForm();
  }

  afterCallModal(event){
    this.router.navigate(['vrgoAdmin/ebib/edit/' + this.eventId + '/' + this.ticketId ]);
  }

}
