import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VRGoAdminMainComponent } from './vrgo-admin-main/vrgo-admin-main.component';
import { VRGoAdminCreateUserComponent } from './vrgo-admin-create-user/vrgo-admin-create-user.component';
import { VRGoAdminPaymentPreApproveComponent } from './vrgo-admin-payment-pre-approve/vrgo-admin-payment-pre-approve.component';
import { VRGoAdminPaymentPendingComponent } from './vrgo-admin-payment-pending/vrgo-admin-payment-pending.component';
import { VRGoAdminPaymentPaidComponent } from './vrgo-admin-payment-paid/vrgo-admin-payment-paid.component';
import { VRGoAdminApproveVRComponent } from './vrgo-admin-approve-vr/vrgo-admin-approve-vr.component';
import { VrgoAdminOrderpaymentComponent } from './vrgo-admin-orderpayment/vrgo-admin-orderpayment.component';
import { VrgoAdminReportorderComponent } from './vrgo-admin-reportorder/vrgo-admin-reportorder.component';
import { VrgoAdminSearchEventsComponent } from './vrgo-admin-search-events/vrgo-admin-search-events.component';
import { VrgoAdminEventDetailComponent } from './vrgo-admin-event-detail/vrgo-admin-event-detail.component';
import { VrgoAdminReportOrderSummaryComponent } from './vrgo-admin-report-order-summary/vrgo-admin-report-order-summary.component';
import { from } from 'rxjs';
import { VrgoAdminReportUserComponent } from './vrgo-admin-report-user/vrgo-admin-report-user.component';
import { VrgoAdminConfigEbibComponent } from './vrgo-admin-config-ebib/vrgo-admin-config-ebib.component';
import { VrgoAdminConfigEcertComponent } from './vrgo-admin-config-ecert/vrgo-admin-config-ecert.component';
import { VrgoAdminCreateConfigEbibComponent } from './vrgo-admin-create-config-ebib/vrgo-admin-create-config-ebib.component';
import { VrgoAdminCreateConfigEcertComponent } from './vrgo-admin-create-config-ecert/vrgo-admin-create-config-ecert.component';
import { VrgoAdminOrderEditComponent } from './vrgo-admin-order-edit/vrgo-admin-order-edit.component';
import { VrgoAdminUsersEditComponent } from './vrgo-admin-users-edit/vrgo-admin-users-edit.component';
import { VrgoAdminUsersSearchComponent } from './vrgo-admin-users-search/vrgo-admin-users-search.component';
import { VrgoAdminSectionDetailComponent } from './vrgo-admin-section-detail/vrgo-admin-section-detail.component';
import { VrgoAdminTicketDetailComponent } from './vrgo-admin-ticket-detail/vrgo-admin-ticket-detail.component';
import { VrgoAdminConfigEbadgeComponent } from './vrgo-admin-config-ebadge/vrgo-admin-config-ebadge.component';
import { VrgoAdminListEbadgeComponent } from './vrgo-admin-list-ebadge/vrgo-admin-list-ebadge.component';
import { VrgoAdminUpdateTrackNumberComponent } from './vrgo-admin-update-track-number/vrgo-admin-update-track-number.component';
import { VrgoAdminRedeemCouponComponent } from './vrgo-admin-redeem-coupon/vrgo-admin-redeem-coupon.component';
import { VrgoAdminReportOrderSummaryAllComponent } from './vrgo-admin-report-order-summary-all/vrgo-admin-report-order-summary-all.component';

const routes: Routes = [
  {
    path: 'main',
    component: VRGoAdminMainComponent,
    data: {
      title: 'VRGo Admin'
    }
  },
  {
    path: 'ticketDetail/edit/:ticketId',
    component: VrgoAdminTicketDetailComponent,
    data: {
      transactionType: 'EDIT'
    }
  },
  {
    path: 'ticketDetail/add',
    component: VrgoAdminTicketDetailComponent,
    data: {
      transactionType: 'ADD'
    }
  },
  {
    path: 'eventDetail/edit/:eventId',
    component: VrgoAdminEventDetailComponent,
    data: {
      transactionType: 'EDIT'
    }
  },
  {
    path: 'eventDetail/add',
    component: VrgoAdminEventDetailComponent,
    data: {
      transactionType: 'ADD'
    }
  },
  {
    path: 'SearchEvent',
    component: VrgoAdminSearchEventsComponent,
    data: {
      title: 'Search Event'
    }
  },
  {
    path: 'reportOrder',
    component: VrgoAdminReportorderComponent,
    data: {
      title: 'report Order'
    }
  },

  {
    path: 'createUser',
    component: VRGoAdminCreateUserComponent,
    data: {
      title: 'VRGo Admin'
    }
  },
  {
    path: 'paymentPreApprove',
    component: VRGoAdminPaymentPreApproveComponent,
    data: {
      title: 'payment pre approve'
    }
  },
  {
    path: 'paymentPending',
    component: VRGoAdminPaymentPendingComponent,
    data: {
      title: 'payment pending'
    }
  },
  {
    path: 'paymentPaid',
    component: VRGoAdminPaymentPaidComponent,
    data: {
      title: 'payment paid'
    }
  },
  {
    path: 'approveVR',
    component: VRGoAdminApproveVRComponent,
    data: {
      title: 'approve VR'
    }
  },
  {
    path: 'orderPayment',
    component: VrgoAdminOrderpaymentComponent,
    data: {
      title: 'orderPayment'
    }
  },
  {
    path: 'orderSummaryReport',
    component: VrgoAdminReportOrderSummaryComponent,
    data: {
      title: 'orderSummaryReport'
    }
  },
  {
    path: 'userSummaryReport',
    component: VrgoAdminReportUserComponent,
    data: {
      title: 'userSummaryReport'
    }
  },
  {
    path: 'listConfigEbib',
    component: VrgoAdminConfigEbibComponent,
    data: {
      title: 'config ebib'
    }
  },
  {
    path: 'listConfigEcert',
    component: VrgoAdminConfigEcertComponent,
    data: {
      title: 'config ecert'
    }
  },
  {
    path: 'ebib/create/:eventId/:ticketId',
    component: VrgoAdminCreateConfigEbibComponent,
    data: {
      transactionType: 'CREATE'
    }
  },
  {
    path: 'ebib/edit/:eventId/:ticketId',
    component: VrgoAdminCreateConfigEbibComponent,
    data: {
      transactionType: 'EDIT'
    }
  },
  {
    path: 'ecert/create/:eventId/:ticketId',
    component: VrgoAdminCreateConfigEcertComponent,
    data: {
      transactionType: 'CREATE'
    }
  },
  {
    path: 'ecert/edit/:eventId/:ticketId',
    component: VrgoAdminCreateConfigEcertComponent,
    data: {
      transactionType: 'EDIT'
    }
  },
  {
    path: 'order/edit/:ordernumber',
    component: VrgoAdminOrderEditComponent,
    data: {
      transactionType: 'EDIT'
    }
  },
  {
    path: 'user/edit/:userId',
    component: VrgoAdminUsersEditComponent,
    data: {
      transactionType: 'EDIT'
    }
  },
  {
    path: 'sectionDetail/add',
    component: VrgoAdminSectionDetailComponent,
    data: {
      transactionType: 'ADD'
    }
  },

  {
    path: 'sectionDetail/edit/:sectionId',
    component: VrgoAdminSectionDetailComponent,
    data: {
      transactionType: 'EDIT'
    }
  },
  {
    path: 'usersSearch',
    component: VrgoAdminUsersSearchComponent,
    data: {
      title: 'Search'
    }
  },
  {
    path: 'ebadge/edit/:eventId',
    component: VrgoAdminConfigEbadgeComponent,
    data: {
      transactionType: 'EDIT'
    }
  },
  {
    path: 'ebadge/create/:eventId',
    component: VrgoAdminConfigEbadgeComponent,
    data: {
      transactionType: 'ADD'
    }
  },
  {
    path: 'listConfigEbadge',
    component: VrgoAdminListEbadgeComponent,
    data: {
      title: 'config ebadge'
    }
  },
  {
    path: 'updateTrackNum',
    component: VrgoAdminUpdateTrackNumberComponent,
    data: {
      title: 'Update TrackNumber'
    }
  }, {
    path: 'redeemCoupon',
    component: VrgoAdminRedeemCouponComponent,
  },
  {
    path: 'reportOrderAll',
    component: VrgoAdminReportOrderSummaryAllComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VRGoAdminRoutingModule { }
