import { Component, OnInit } from '@angular/core';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { FormControl, FormGroup } from '@angular/forms';
import { FunctionUtils } from 'src/app/shared/utils/functionUtils';
import { DatePipe } from '@angular/common';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'src/app/shared/authentication/token-storage.service';


@Component({
  selector: 'app-vrgo-admin-report-order-summary-all',
  templateUrl: './vrgo-admin-report-order-summary-all.component.html',
  styleUrls: ['./vrgo-admin-report-order-summary-all.component.scss']
})
export class VrgoAdminReportOrderSummaryAllComponent implements OnInit {

  constructor(
    private service: HttpClientService,
    private fn: FunctionUtils,
    private modaladdUser: NgbActiveModal  ,
    private datePipe: DatePipe,
    private tokenService : TokenStorageService
    ) { }

  userProfile : any;

  eventName : any = '';
  selectName: string = '';
  selectNameClass: string = 'form-control select-form';
  selectNameClassErrorMsg: string = '';

  year: string = '';
  yearClass: string = 'form-control select-form';
  yearClassErrorMsg: string = '';

  endDate: string = '';
  endDateClass: string = 'form-control';
  endDateClassErrorMsg: string = '';

  formBasic: FormGroup;

  listMonth: any = [];
  listYear: any = [];
  eventId : any ;
  listTicketByEvent: any;
  ticketId: any;

  month: string = '';
  

  ngOnInit() {
    this.userProfile = JSON.parse(this.tokenService.getUserProfile());
    this.buildFormBasic();
    this.getListbox();
  }

  getListbox() {
    this.service.getListBoxByGroup('MONTH').subscribe((items) => {
      this.listMonth = items;
    });
    this.service.getListBoxByGroup('YEAR').subscribe((year) => {
      this.listYear = year;
    });
  }


  buildFormBasic() {
    this.formBasic = new FormGroup({
      selectName: new FormControl(''),
      year: new FormControl(''),
    });
  }

  submit(){
    this.month = this.formBasic.value.selectName;
    this.year = this.formBasic.value.year;
    // let startDate = this.fn.formatDate(this.formBasic.value.startDate);
    // let endDate = this.fn.formatDate(this.formBasic.value.endDate);
    let isNull = 0;

    if(this.month == null || this.month == '' || this.month === undefined){
      isNull = isNull+1;
      this.selectNameClass = 'form-control text-error';
      this.selectNameClassErrorMsg = 'กรุณาระบุ เดือน';
    }else{
      this.selectNameClass = 'form-control select-form';
      this.selectNameClassErrorMsg = ''
    }

    if(this.year == null || this.year == '' || this.month === undefined){
      isNull = isNull+1;
      this.yearClass = 'form-control text-error';
      this.yearClassErrorMsg = 'กรุณาระบุ ปี';
    }else{
      this.yearClass = 'form-control';
      this.yearClassErrorMsg = '';
    }
    // console.log("out :",this.month,this.year);
    if(isNull === 0){
    this.service.orderAllSummary(this.month,this.year)
    // console.log(this.month,this.year);
    }
  }
  clearCriteria(){
    window.location.reload();
  }
}
