import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientService } from 'src/app/shared/services/httpclient.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Province } from '../../shared/class/Province';
import { Amphur } from '../../shared/class/Amphur';
import { Distict } from '../../shared/class/distict';
import { ZipCode } from '../../shared/class/ZipCode';
import { orderPayment } from '../../shared/models/order-payment.model';
import { THIS_EXPR, ThrowStmt } from '@angular/compiler/src/output/output_ast';
import { TokenStorageService } from '../../shared/authentication/token-storage.service';
import { NgbModal, NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-vrgo-admin-order-edit',
  templateUrl: './vrgo-admin-order-edit.component.html',
  styleUrls: ['./vrgo-admin-order-edit.component.scss']
})
export class VrgoAdminOrderEditComponent implements OnInit {
  @ViewChild('saveSuccess', { static: false }) saveSuccess;
  @ViewChild('saveValid', { static: false }) saveValid;
  @ViewChild('deleteVr', { static: false }) deleteVr;
  @ViewChild('changeValid', { static: false }) changeValid;
  @ViewChild('changeVRLog', { static: false }) changeVRLog;
  @ViewChild('addVRLog', { static: false }) addVRLog;
  constructor(
    private router: Router,
    private clientservice: HttpClientService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private token: TokenStorageService,
    private modalService: NgbModal,) {
    var time = new Array(
      '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
      '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
      '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
      '31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
      '41', '42', '43', '44', '45', '46', '47', '48', '49', '50',
      '51', '52', '53', '54', '55', '56', '57', '58', '59', '60');
    var hour = new Array(
      '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
      '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
      '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
      '31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
      '41', '42', '43', '44', '45', '46', '47', '48');
    this.hour = hour;
    this.second = time;
    this.minute = time;
  }
  secondValue: string;
  minuteValue: string;
  hourValue: string;
  createByClass: string = "form-control";
  createByErrorMsg: string = '';
  second: any = [];
  minute: any = [];
  hour: any = [];
  titleHeader: string = "";
  mode: any;
  ordernumber: any;
  listProvince: Province[] = [];
  listAmphur: Amphur[] = [];
  listDistrict: Distict[] = [];
  listZipCode: ZipCode[] = [];
  listOrderObjectModel = [];
  listTicketFieldObject = [];
  listTicketFieldObjectToSave = [];
  formOrderEdit: FormGroup;
  formOrderObj: FormGroup;
  addressForm: FormGroup;
  country: orderPayment;
  eventId: orderPayment;
  ticketId: orderPayment;
  approveBy: orderPayment;
  status: orderPayment;
  districtCode: any;
  provinceName: any;
  amphurName: any;
  amphureCode: any;
  provinceCode: any;
  submitted = false;
  userProfile: any;
  updateBy: any;
  msgDescription: any;
  errors: boolean = false;
  vrLogId: any;
  listVRLog: any[] = [];
  formVRLog: FormGroup;
  msgdeleteVr: any;
  changeTicketForm: FormGroup;
  listTicketByEvent: any;
  ticketType: any;
  vrId: any;
  changeTicketId: any;
  orderDetailId: any;
  changeSubmitted = false;
  shirtClass: string = 'form-control';
  editVRLogForm: FormGroup;
  addVRLogForm: FormGroup;
  listVR: any[] = [];
  userId: any;

  fileArrayUpload: Array<File> = [];
  fileToUpload: File = null;
  fileMessage: string;
  editVrLogSubmitted = false;
  submittedAddVRLog = false;
  vrFileName: String = "";
  errorFile: boolean = false;
  errorMsg;
  errorMsgNull;
  errorMsgTime;
  errorAlert: boolean = false;
  errorAlertMsg;
  filename;
  classFileName: string = 'form-control'

  listEditTrans: orderPayment[] = [];
  orderId: any;

  paymentType: string = '';
  addressStatus: string = '';

  ngOnInit() {
    this.token.getUserProfile();
    this.userProfile = JSON.parse(this.token.getUserProfile());
    // console.log(this.userProfile.userId)
    this.userId = this.userProfile.userId;
    this.buildAddressForm();
    this.buildFormOrderEdit();
    this.buildFormOrderObj();
    this.buildFormVRLog();
    this.buildChangeTicketForm();
    this.buildEditVRLogForm();
    this.buildAddVRLogForm();
    this.route.params.subscribe(routeParams => {
      this.ordernumber = routeParams.ordernumber;
      // console.log('ordernumber', routeParams.ordernumber);
    });
    this.route.data.subscribe(data => {
      this.mode = data.transactionType.toString();
      //console.log('transactionType : ' + this.mode);
      if (this.mode.toUpperCase() === 'EDIT') {
        this.titleHeader = "แก้ไขข้อมูล การสั่งซื้อ";
        this.getOrderByOrderNumber();
        // this.getAllProvince();
        // this.getProvinceName();
      }
    });
    this.getVRLog();

  }
  buildFormOrderEdit() {
    this.formOrderEdit = this.formBuilder.group({
      orderNumber: new FormControl(''),
      eventName: new FormControl(''),
      ticketName: new FormControl(''),
      createBy: new FormControl(''),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      tel: new FormControl('', Validators.required),
      object: new FormControl(''),
      paymentMethod: new FormControl(''),
      netAmt: new FormControl(''),
      statusDesc: new FormControl(''),
      paymentDate: new FormControl(''),
      remark: new FormControl('', Validators.required),

    });
  }
  buildFormOrderObj() {
    this.formOrderObj = this.formBuilder.group({
      // medal: new FormControl(''),
      // shirt_size: new FormControl(''),

    });
  }
  getOrderByOrderNumber() {
    // console.log('1',this.ordernumber)
    this.clientservice.getOrderByOrderNumber(this.ordernumber).subscribe(data => {
      this.mapPaymentData(data)
    })
  }
  mapPaymentData(data) {
    // console.log('data', data)
    var paymentMethod = '';
    var paymentDate = '';
    this.ticketId = data.ticketId
    const dataObj = {};
    let option: any;
    this.buildFormOrderObj();
    if (data.listOrderObjectModel.length !== 0) {
      this.listOrderObjectModel = data.listOrderObjectModel
      //console.log('listOrderObjectModel',this.listOrderObjectModel)
      this.listTicketFieldObjectToSave = [];
      this.listTicketFieldObject = data.listOrderObjectModel[0].listTicketFieldObject

      // console.log(data.listOrderObjectModel);

      for (let i = 0; i < this.listOrderObjectModel.length; i++) {
        dataObj[this.listOrderObjectModel[i].fieldName] = new FormControl(this.listOrderObjectModel[i].objectValue);
        this.formOrderObj = this.formBuilder.group(dataObj);
        this.listTicketFieldObjectToSave.push(this.listOrderObjectModel[i])
      }
    }

    if (data.paymentMethod === 'DI') {
      paymentMethod = 'โอนเงินผ่านบัญชีธนาคาร';
    } else if (data.paymentMethod === 'CC') {
      paymentMethod = 'บัตรเครดิต';
    } else if (data.paymentMethod == 'FREE') {
      paymentMethod = 'ฟรี';
    }

    if (data.paymentDate == null) {
      paymentDate = '-';
    } else {
      paymentDate = data.paymentDate;
    }

    this.paymentType = data.paymentMethod;
    this.addressStatus = data.addressStatus;
    this.formOrderObj = this.formBuilder.group(dataObj);
    this.formOrderEdit.controls['orderNumber'].setValue(data.orderNumber);
    this.formOrderEdit.controls['eventName'].setValue(data.eventName);
    this.formOrderEdit.controls['ticketName'].setValue(data.ticketName);
    this.formOrderEdit.controls['createBy'].setValue(data.createBy);
    this.formOrderEdit.controls['firstName'].setValue(data.firstName);
    this.formOrderEdit.controls['lastName'].setValue(data.lastName);
    this.formOrderEdit.controls['paymentMethod'].setValue(paymentMethod);
    this.formOrderEdit.controls['netAmt'].setValue(data.netAmt);
    this.formOrderEdit.controls['statusDesc'].setValue(data.statusDesc);
    this.formOrderEdit.controls['paymentDate'].setValue(paymentDate);
    this.formOrderEdit.controls['tel'].setValue(data.tel);
    this.formOrderEdit.controls['remark'].setValue("");
    if (data.listOrderObjectModel.length !== 0) {
      this.formOrderEdit.controls['object'].setValue(data.listOrderObjectModel[0].objectValue);
    }
    this.addressForm.controls['address'].setValue(data.address);
    this.addressForm.controls['province'].patchValue(data.provinceCode);
    this.provinceCode = data.provinceCode;
    this.amphurName = data.amphure;
    this.districtCode = data.district;

    this.addressForm.controls['zipCode'].setValue(data.zipcode);
    this.country = data.country;
    this.eventId = data.eventId;
    this.ticketId = data.ticketId;
    this.vrId = data.vrId;
    this.ticketType = data.ticketType;
    this.approveBy = data.approveBy;
    this.status = data.status;
    this.updateBy = data.updateBy;
    this.orderDetailId = data.orderDetailId;
    this.orderId = data.orderId;
    // console.log('data ..',data);
    this.getListVRByUser(data.userId);
    if (this.formOrderObj.controls.shirt_size != undefined) {
      if (this.formOrderObj.controls.shirt_size.value == '') {
        this.shirtClass = 'form-control text-error';
      } else {
        this.shirtClass = 'form-control';
      }
    }
    // console.log('>>>',this.formOrderObj.errors)
    this.getAllProvince();
    this.getTicket();
    this.getEditTrans();
  }

  buildAddressForm() {
    this.addressForm = this.formBuilder.group(
      {
        address: new FormControl('', Validators.required),
        province: new FormControl('', Validators.required),
        amphur: new FormControl('', Validators.required),
        district: new FormControl('', Validators.required),
        zipCode: new FormControl('', Validators.required),
      }
    );
  }

  getAllProvince() {
    this.clientservice.getAllProvince().subscribe(data => { this.mapProvinceAll(data) });
  }

  mapProvinceAll(data: any) {
    // console.log(this.provinceCode);
    this.listProvince = data;
    const f = this.listProvince.filter(c => c.provinceCode === this.provinceCode);
    this.provinceName = f[0].provinceName;
    if (this.mode.toUpperCase() === 'EDIT') {
      this.changeProvince(this.provinceCode);
    }
  }

  changeProvince(provinceId) {
    this.clientservice.getAmphureByProvince(provinceId).subscribe(data => {
      this.mapAmphureAfterChange(data)
    });
  }

  mapAmphureAfterChange(data: any) {
    this.listAmphur = data;
    const f = this.listAmphur.filter(c => c.amphurName == this.amphurName);
    this.amphurName = f[0].amphurName;
    // console.log('amphurName',this.amphurName)
    this.addressForm.controls['amphur'].setValue(f[0].amphurCode);
    this.amphureCode = f[0].amphurCode
    this.changeAmphure(f[0].amphurCode);
  }

  changeAmphure(amphureId) {
    // console.log('amphureId',amphureId)
    this.clientservice.getDistrictByAmphure(amphureId).subscribe(data => {
      this.mapDistrictAfterChage(data)
    });
  }

  mapDistrictAfterChage(data: any) {
    this.listDistrict = data;
    // console.log('listDistrict', this.listDistrict)
    const f = this.listDistrict.filter(c => c.districtName == this.districtCode);
    // console.log('f', f[0])
    this.addressForm.controls['district'].setValue(this.districtCode);

  }

  changeDistrict(districtId, amphureId) {
    this.clientservice.getZipcodeByDistrict(districtId, amphureId).subscribe(data => { this.mapZipcode(data) });
  }

  getAmphureByProvince(event) {
    let provinceId = event.target.value
    this.addressForm.controls['amphur'].setValue("");
    this.clientservice.getAmphureByProvince(provinceId).subscribe(data => { this.mapAmphure(data) });
    const f = this.listProvince.filter(c => c.provinceCode === provinceId);
    this.provinceName = f[0].provinceName;
  }
  getDistrictByAmphure(event) {
    let amphurId = event.target.value;
    this.clientservice.getDistrictByAmphure(amphurId).subscribe(data => { this.mapDistrict(data) });
    const f = this.listAmphur.filter(c => c.amphurCode == this.addressForm.controls['amphur'].value);
    this.amphurName = f[0].amphurName;
  }

  getZipcodeByDistrict(event) {
    let districtId = event.target.value;
    let amphureCode = this.addressForm.value.amphur;
    this.clientservice.getZipcodeByDistrict(districtId, amphureCode).subscribe(data => { this.mapZipcode(data) });
  }

  mapAmphure(data: any) {
    this.listAmphur = data;
    if (this.addressForm.controls['amphur'].value != "") {
      // console.log('aa',this.addressForm.controls['amphur'].value)
      const f = this.listAmphur.filter(c => c.amphurCode == this.addressForm.controls['amphur'].value);
      this.amphurName = f[0].amphurName;
    }
  }

  mapDistrict(data: any) {
    this.listDistrict = data;
  }

  mapZipcode(data: any) {
    this.listZipCode = data;
    this.addressForm.controls['zipCode'].setValue(this.listZipCode[0].zipcode);
  }
  get addrForm() { return this.addressForm.controls; }
  get editForm() { return this.formOrderEdit.controls; }
  validOrder() {

    this.submitted = true;
    this.errors = false;
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const listOrder = new orderPayment;
    let isNull = 0;

    console.log(this.formOrderEdit.controls['remark'].value)

    if (this.formOrderEdit.controls['tel'].value.length < 10) {
      this.formOrderEdit.controls['tel'].setErrors({ 'minLength': true });
      this.errors = true;
      isNull = isNull + 1
    }



    if (this.addressStatus == 'INACTIVE') {
      this.addressForm.controls.address.setValue("0");
      this.addressForm.controls.amphur.setValue("0");
      this.addressForm.controls.district.setValue("0");
      this.addressForm.controls.province.setValue("0");
      this.addressForm.controls.zipCode.setValue("0");
      // console.log("Address", this.addressForm.value);
      // console.log("Address Status", this.addressForm.status);
    } else {
      if (this.addressForm.controls.address.status === 'INVALID') {
        isNull = isNull + 1
      }
      if (this.addressForm.controls.amphur.status === 'INVALID') {
        isNull = isNull + 1
      }
      if (this.addressForm.controls.district.status === 'INVALID') {
        isNull = isNull + 1
      }
      if (this.addressForm.controls.zipCode.status === 'INVALID') {
        isNull = isNull + 1
      }
    }


    if (this.formOrderObj.controls.shirt_size != undefined) {
      if (this.formOrderObj.controls.shirt_size.value == '') {
        this.shirtClass = 'form-control text-error';
        isNull = isNull + 1
      } else {
        this.shirtClass = 'form-control';
      }
    }
    //  if(this.formOrderEdit.controls.createBy.status === 'INVALID'){
    //   isNull = isNull + 1
    //  }
    if (this.formOrderEdit.controls.firstName.status === 'INVALID') {
      isNull = isNull + 1
    }
    if (this.formOrderEdit.controls.firstName.status === 'INVALID') {
      isNull = isNull + 1
    }
    if (this.formOrderEdit.controls.remark.status === 'INVALID') {
      isNull = isNull + 1
    }
    //  if(this.formOrderEdit.controls.tel.status === 'INVALID'){
    //   isNull = isNull + 1
    //  }
    if (!this.formOrderEdit.controls.createBy.value.match(regex)) {
      isNull = isNull + 1
      this.createByClass = "form-control text-error";
      this.createByErrorMsg = 'กรุณาระบุ อีเมล์ให้ถูกต้อง';
      // console.log('asdasd')
    } else {
      this.createByClass = "form-control";
      this.createByErrorMsg = '';
    }
     console.log('isNull',isNull) 
    if (isNull === 0) {
      if (this.provinceName === undefined && this.addressStatus == 'ACTIVE') {
        const f = this.listProvince.filter(c => c.provinceCode === this.provinceCode);
        this.provinceName = f[0].provinceName;
      }
      this.msgDescription = 'ยืนยันการแก้ไขข้อมูล';
      this.modalService.open(this.saveValid, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    } else {

    }

  }
  changeTicketFieldObject(event,type) {
    for (let i = 0; i < this.listTicketFieldObjectToSave.length; i++) {
      if (this.listTicketFieldObjectToSave[i].fieldName == type) {
        this.listTicketFieldObjectToSave[i].objectValue = event.target.value;
      }
    }
  }
  changeMedalEngraved(event,type) {
    for (let i = 0; i < this.listTicketFieldObjectToSave.length; i++) {
      if (this.listTicketFieldObjectToSave[i].fieldName == type) {
        this.listTicketFieldObjectToSave[i].objectValue = event.target.value;
      }
    }
  }
  saveOrder() {
    const listOrder = new orderPayment;

    listOrder['orderNumber'] = this.formOrderEdit.controls['orderNumber'].value;
    listOrder['country'] = this.country;
    listOrder['ticketId'] = this.ticketId;
    listOrder['eventId'] = this.eventId;
    listOrder['firstName'] = this.formOrderEdit.controls['firstName'].value;
    listOrder['lastName'] = this.formOrderEdit.controls['lastName'].value;
    listOrder['approveBy'] = this.approveBy;
    listOrder['status'] = this.status;
    listOrder['createBy'] = this.formOrderEdit.controls['createBy'].value;
    listOrder['listOrderObjectModel'] = this.listOrderObjectModel;
    listOrder['updateBy'] = this.userProfile.userId;
    listOrder['updateDate'] = null;
    listOrder['tel'] = this.formOrderEdit.controls['tel'].value;
    listOrder['listOrderObjectModel'] = this.listTicketFieldObjectToSave
    listOrder['remark'] = this.formOrderEdit.controls['remark'].value;
    
    if (this.addressStatus == 'INACTIVE') {
      listOrder['address'] = "";
      listOrder['province'] =  "";
      listOrder['amphure'] =  "";
      listOrder['district'] =  "";
      listOrder['zipcode'] =  "";
    }else{
      listOrder['address'] = this.addressForm.controls['address'].value;
      listOrder['province'] = this.provinceName;
      listOrder['amphure'] = this.amphurName;
      listOrder['district'] = this.addressForm.controls['district'].value;
      listOrder['zipcode'] = this.addressForm.controls['zipCode'].value;
    }
    
    // console.log('listOrder', JSON.stringify(listOrder))
    this.clientservice.editOrder(listOrder).subscribe(data => {
      this.mapEditdata(data)
    });


  }
  mapEditdata(data) {
    this.modalService.dismissAll();
    this.modalService.open(this.saveSuccess, { backdrop: 'static', windowClass: 'myCustomModalClass' });
    this.getEditTrans();
    // console.log('listOrder',data);
  }
  closeMadal() {
    this.modalService.dismissAll();
  }
  goToMain() {
    this.modalService.dismissAll();
    this.router.navigate(['vrgoAdmin/orderPayment']);
  }
  onChange(event, type) {
    const key = event.key;
    if (type === 'NUMBER') {
      if (!key.match('^-?(0|[1-9]\d*)?$')) {
        event.preventDefault();
      }
    } else if (type === 'TEXT') {
      if (!key.match('^[a-zA-Z]')) {
        event.preventDefault();
      }
    } else if (type === 'ALL') {
      if (!key.match('^[A-Za-z0-9_.]+$')) {
        event.preventDefault();
      }
    }
  }

  buildFormVRLog() {
    this.formVRLog = this.formBuilder.group({
      // medal: new FormControl(''),
      // shirt_size: new FormControl(''),

    });
  }
  getVRLog() {
    this.editVrLogSubmitted = false;
    this.clientservice.getListVRLogById(this.ordernumber).subscribe(data => {
      this.mapListVRLog(data)
    })
  }

  mapListVRLog(data: any) {
    // console.log(this.provinceCode);
    this.listVRLog = data;

  }
  deleteVRLogValid(vrLogId: any) {
    vrLogId = this.vrLogId;
    // console.log('TEST : ',vrLogId)
    this.clientservice.deleteVRLog(vrLogId).subscribe(data => {
      this.mapDelVrLog(data);
    });
  }
  mapDelVrLog(data) {
    this.modalService.dismissAll();
    this.getVRLog();
    this.modalService.open(this.saveSuccess, { backdrop: 'static', windowClass: 'myCustomModalClass' });
  }
  validate(vrLogId) {
    this.vrLogId = vrLogId;
    this.msgdeleteVr = 'ยืนยันการแก้ไขข้อมูล';
    this.modalService.open(this.deleteVr, { backdrop: 'static', windowClass: 'myCustomModalClass' });
  }
  buildChangeTicketForm() {
    this.changeTicketForm = this.formBuilder.group(
      {
        orderId: new FormControl('', Validators.required),
        ticketId: new FormControl('', Validators.required),
        orderDetailId: new FormControl('', Validators.required)

      }
    );
  }

  getTicket() {
    this.clientservice.getTicketByEvent(this.eventId).subscribe(data => {
      this.mapTicketByEvent(data)
    });
  }
  mapTicketByEvent(data: any) {
    // console.log('ticket=', data);
    this.listTicketByEvent = data;

  }
  changeTicket(ticketId: any) {
    // console.log(ticketId.target.value);
    this.changeTicketId = ticketId.target.value;
    this.saveChangeTicketModal();
  }
  get changeForm() { return this.changeTicketForm.controls; }
  saveChangeTicketModal() {
    // console.log(this.changeTicketId ,'!=', this.ticketId ,'?',this.changeTicketId != this.ticketId);
    this.changeSubmitted = true;
    if (this.changeTicketId != this.ticketId) {
      this.msgDescription = 'ยันยืนการแก้ไข';
      // console.log(this.vrId,' + ',this.changeTicketId,' + ',this.orderDetailId);
      this.modalService.open(this.changeValid, { backdrop: 'static', windowClass: 'myCustomModalClass' });

    }
  }
  saveChangeTicket() {
    // this.formOrderObj.controls.shirt_size.errors
    //console.log('>>>',this.vrId)
    //console.log('>>>',this.changeTicketId)
    //console.log('>>>',this.orderDetailId)
    this.modalService.dismissAll();
    this.clientservice.saveChangeTicket(this.vrId, this.changeTicketId, this.orderDetailId).subscribe(data => {
      this.getOrderByOrderNumber();
    });
  }

  buildEditVRLogForm() {
    this.editVRLogForm = this.formBuilder.group(
      {
        vrLogId: new FormControl('', Validators.required),
        userId: new FormControl('', Validators.required),
        distance: new FormControl('0', Validators.required),
        location: new FormControl('', Validators.required),
        hour: new FormControl('0', Validators.required),
        minute: new FormControl('0', Validators.required),
        second: new FormControl('0', Validators.required)
      }
    );
  }
  get changeAddVrLogForm() { return this.addVRLogForm.controls; }
  get editVRLogControl() { return this.editVRLogForm.controls; }
  editVRLogModal(data) {
    if (this.hourValue == undefined) {
      this.hourValue = '00';
    }
    this.editVRLogForm.controls.vrLogId.setValue(data.vrLogId);
    this.editVRLogForm.controls.userId.setValue(data.userId);
    this.editVRLogForm.controls.distance.setValue(data.distance);
    this.editVRLogForm.controls.location.setValue(data.location);
    this.hourValue = data.hour;
    this.minuteValue = data.minute;
    this.secondValue = data.second;
    this.modalService.open(this.changeVRLog, { backdrop: 'static', windowClass: 'vrLogEditModalClass' });
  }
  editVRLog() {
    this.modalService.dismissAll();
    this.clientservice.editVRLog(this.editVRLogForm.controls.vrLogId.value,
      this.editVRLogForm.controls.userId.value,
      this.editVRLogForm.controls.distance.value,
      this.editVRLogForm.controls.location.value,
      this.hourValue,
      this.minuteValue,
      this.secondValue)
      .subscribe(data => {
        // this.getOrderByOrderNumber();
        this.getVRLog()
      });
  }

  buildAddVRLogForm() {
    this.addVRLogForm = this.formBuilder.group(
      {
        file: new FormControl('', Validators.required),
        vrId: new FormControl('', Validators.required),
        userId: new FormControl('', Validators.required),
        ticketId: new FormControl('', Validators.required),
        distance: new FormControl('', Validators.required),
        location: new FormControl('', Validators.required),
        hour: new FormControl('0', Validators.required),
        minute: new FormControl('0', Validators.required),
        second: new FormControl('0', Validators.required),
        ticketName: new FormControl('', Validators.required)
      }
    );
  }
  get addVRLogForms() { return this.addVRLogForm.controls; }

  getListVRByUser(userId) {
    this.clientservice.getListVRByUser(userId).subscribe(data => {
      // this.getOrderByOrderNumber();
      this.mapListVRByUser(data)
    });
  }
  mapListVRByUser(data: any) {
    //  console.log("listVR",data);
    this.listVR = data;

  }
  addVRLogModal() {
    this.errorMsgNull = '';
    this.classFileName = 'form-control';
    this.errorMsgTime = '';
    this.submittedAddVRLog = false;
    this.hourValue = undefined;
    this.minuteValue = undefined;
    this.secondValue = undefined;
    this.errorFile = false;
    this.modalService.open(this.addVRLog, { backdrop: 'static', windowClass: 'vrLogAddModalClass' });
  }
  mapAddVRLog(data: any) {
    // editVrLogSubmitted 
    var s = data.target.value.split(".", 2);
    this.addVRLogForm.controls.userId.setValue(this.userId);
    this.addVRLogForm.controls.ticketId.setValue(s[0]);
    // this.addVRLogForm.controls.eventId.setValue(data.eventId);
    this.addVRLogForm.controls.vrId.setValue(s[1]);
  }
  inputFile() {
    document.getElementById('but').click();
  }
  handleFileInputComplete(files: any) {
    this.fileToUpload = files.target.files[0];
    this.vrFileName = this.fileToUpload.name;
    if (this.vrFileName.substring(this.vrFileName.lastIndexOf('.') + 1).toLowerCase() == 'jpeg'
      || this.vrFileName.substring(this.vrFileName.lastIndexOf('.') + 1).toLowerCase() == 'png'
      || this.vrFileName.substring(this.vrFileName.lastIndexOf('.') + 1).toLowerCase() == 'jpg') {

      this.errorMsg = '';
      this.filename = this.vrFileName;
    } else {
      this.errorFile = true;
      this.errorMsg = 'กรุณาอัพโหลดไฟล์ที่เป็น .jpeg .png .jpg เท่านั้น';
    }
  }

  addVRLogAdmin() {
    this.submittedAddVRLog = true;
    var isNull = 0;
    if (this.filename == null) {
      this.errorMsgNull = 'กรุณา อัพโหลดไฟล์';
      this.classFileName = 'border-err form-control';
      isNull = isNull + 1;
    } else {
      this.errorMsgNull = '';
      this.classFileName = 'form-control';
    }
    if (this.minuteValue == undefined && this.secondValue == undefined) {
      this.errorMsgTime = 'กรุณา เลือกนาทีและวินาทีเป็นอย่างน้อย';
      isNull = isNull + 1;
    } else {
      this.errorMsgTime = '';
    }
    if (isNull == 0) {
      if (this.hourValue == undefined) {
        this.hourValue = '00';
      }
      this.modalService.dismissAll();
      this.clientservice.addVRLogAdmin(
        this.fileToUpload,
        this.addVRLogForm.controls.vrId.value,
        this.addVRLogForm.controls.userId.value,
        this.addVRLogForm.controls.ticketId.value,
        this.addVRLogForm.controls.distance.value,
        this.addVRLogForm.controls.location.value,
        this.hourValue,
        this.minuteValue,
        this.secondValue).subscribe(data => {
          this.getVRLog();
          this.buildAddVRLogForm();
        });
    }
  }

  getEditTrans() {
    this.clientservice.getOrderEditTrans(this.orderId).subscribe(data => {
      // this.getOrderByOrderNumber();
      this.mapListOrderEditTrans(data)
    });
  }
  mapListOrderEditTrans(data: any) {
    // console.log("mapListOrderEditTrans", data);
    this.listEditTrans = data;

  }
}
// listTicketFieldObject
